import React from "react";
import { LinkProps } from "react-router-dom";
import { IoHome, IoPencil, IoTime } from "react-icons/io5";
import retry from "Utils/ImportRetry";
import JGIcon from "../Components/JGIcon";
import GraphicEqIcon from "../Components/Icons/GraphicEqIcon";

interface AccountRoute {
  label: string;
  icon: JSX.Element;
  /** Link to page, relative to parent route. */
  to: LinkProps["to"];
  component: React.ComponentType | any;
}

const ACCOUNT_ROUTES: ReadonlyArray<AccountRoute> = [
  {
    label: "Overview",
    icon: <JGIcon is={IoHome} />,
    to: "",
    component: React.lazy(() => retry(() => import("./Overview"))),
  },
  {
    label: "Donation History",
    icon: <JGIcon is={IoTime} />,
    to: "/history",
    component: React.lazy(() => retry(() => import("./DonationHistory"))),
  },
  {
    label: "Audio Settings",
    icon: <GraphicEqIcon />,
    to: "/audio",
    component: React.lazy(() => retry(() => import("./AudioSettings"))),
  },
  {
    label: "Change Password",
    icon: <JGIcon is={IoPencil} />,
    to: "/profile",
    component: React.lazy(() => retry(() => import("./ProfileSettings"))),
  },
];

export default ACCOUNT_ROUTES;
