import { USER_ENDPOINT } from "../Utils/Constants";
import http from "../Utils/Http";
import { isString } from "Utils/Inspect";
import {
  DBUser,
  DBUserDto,
  JGUserDto,
  UserPreferences,
} from "../../types/Auth";
import { getTokenSilently } from "../react-auth0-spa";

export interface GetDBUserOptions {
  /**
   * Whether to sync the user's subscription with the database before pulling.
   * This is useful because the user's subscription is the database may not always
   * match the subscription in Chargebee.
   *
   * You should still verify the subscription after this regardless, as it appears
   * that the logic in charge of this call does not clear the subscription data if
   * the user goes from subscription to no subscription?
   */
  sync?: boolean;
}

const UserApi = {
  /**
   * Get the user data stored in the JG database.
   */
  async getDBUser(email: string, options?: GetDBUserOptions): Promise<DBUser> {
    const dto = options?.sync
      ? await http.post<DBUserDto>(USER_ENDPOINT, { email })
      : await http.get<DBUserDto>(USER_ENDPOINT, { params: { email } });
    // console.log("🚀 ~ getDBUser ~ dto:", dto);
    return {
      subscriptionId: dto.id,
      subscriptionCurrentTermEnd:
        (isString(dto.ttl) ? parseInt(dto.ttl) : dto.ttl) || undefined,
      planId: dto.plan,
      free_trial: dto.free_trial,
      subscription_status: dto?.subscription_status,
    };
  },

  async updatePreferences(
    preferences: Partial<UserPreferences>
  ): Promise<JGUserDto> {
    return http.put<JGUserDto>(`${USER_ENDPOINT}/preferences`, {
      accessToken: await getTokenSilently(),
      userPreferences: preferences,
    });
  },
};

export default UserApi;
