import React from "react";
import { createSvgIcon } from "@mui/material";

const GraphicEqIcon = createSvgIcon(
  <path
    d="M8 18c.55 0 1-.45 1-1V7c0-.55-.45-1-1-1s-1 .45-1 1v10c0 .55.45 1 1 1zm4 4c.55
       0 1-.45 1-1V3c0-.55-.45-1-1-1s-1 .45-1 1v18c0 .55.45 1 1 1zm-8-8c.55 0 1-.45
       1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1v2c0 .55.45 1 1 1zm12 4c.55 0 1-.45
       1-1V7c0-.55-.45-1-1-1s-1 .45-1 1v10c0 .55.45 1 1 1zm3-7v2c0 .55.45 1 1 1s1-.45
       1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1z"
  />,
  "GraphicEq",
);

export default GraphicEqIcon;
