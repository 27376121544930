import { Components, Theme } from "@mui/material/styles";

const Card: Components<Theme> = {
  MuiCard: {
    defaultProps: {
      elevation: 0,
    },
  },
};

export default Card;
