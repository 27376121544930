import { css, GlobalStyles, GlobalStylesProps, Theme } from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createMixins";

/**
 * Convert the height from {@link Theme.mixins.toolbar} to CSS variable,
 * and update it for each media query in the mixin.
 */
function createToolbarCSSVariableStyles(theme: Theme): GlobalStylesProps["styles"] {
  const toolbar = theme.mixins.toolbar;

  return Object.entries(toolbar).map<GlobalStylesProps["styles"]>(([key, props]) => {
    const isMediaQuery = key.startsWith("@media");
    let height: CSSProperties["height"];

    if (isMediaQuery) height = (props as CSSProperties).height;
    else if (key === "height") height = (props as any);

    if (!height) return {};

    const toolbarCSSVar = { "--jg-toolbar-height": `${height}px` };
    return {
      ":root": {
        // Wrap the CSS var in the media query, if applicable.
        ...(isMediaQuery ? { [key]: toolbarCSSVar } : toolbarCSSVar),
      },
    };
  });
}

const CSSVariablesStyles = <GlobalStyles styles={theme => {
  return [
    css`
      :root {
        // These will be set by their components when appropriate.
        --jg-player-height: 0px;
        --jg-toolbar-height: 0px;
        --jg-announcement-bar-height: 0px;
        --jg-highlighted-footer-height: ${theme.jgComponents.HighlightedFooter.height}px;

        --jg-header-height: calc(var(--jg-toolbar-height) + var(--jg-announcement-bar-height));
        --jg-content-min-height: calc(100vh - var(--jg-player-height) - var(--jg-header-height));

        // Hoist up chatlio widget if bottom footer or the player is visible
        --jg-chatlio-bottom: calc(var(--jg-player-height, 0) + var(--jg-visible-footer-height, 0));
      }
    `,
    createToolbarCSSVariableStyles,
  ];
}} />;

export default CSSVariablesStyles;
