import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Container, SwipeableDrawer, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { useWindowSize } from "react-use";
import { PlayerProps, PlayerStateProps } from "../types";
import SelectStation from "../SelectStation";
import Song from "../Song";
import { calcAlbumArtSize } from "../AlbumArt";
import * as S from "./PlayerDrawer.styles";
import PlayerSettingsButton from "../PlayerSettingsButton";
import DonateButton from "../../DonateButton";
import DrawerToggleButton from "./DrawerToggleButton";

type PlayerDrawerProps = PlayerStateProps & PlayerProps & {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const PlayerDrawer = (props: PlayerDrawerProps) => {
  const {
    open,
    setOpen,
    forceMobileView,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { width, height } = useWindowSize();
  const [albumArtSize, setAlbumArtSize] = useState<number>(calcAlbumArtSize(width, height, 16));

  useEffect(() => {
    setAlbumArtSize(calcAlbumArtSize(width, height, 16));
  }, [width, height]);

  useEffect(() => {
    if (!forceMobileView && !isMobile && open) {
      setOpen(false);
    }
  }, [forceMobileView, isMobile, open, setOpen]);

  return (
    <SwipeableDrawer
      className={S.classes.root}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      anchor="bottom"
      PaperProps={{
        sx: {
          height: "100%",
          backgroundColor: "background.default",
        }
      }}
      sx={theme => ({
        zIndex: theme.zIndex.player - 1,
      })}
    >
      <Toolbar sx={{ backgroundColor: "background.paper" }}>
        <Container
          disableGutters
          maxWidth="sm"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: !forceMobileView ? "space-between" : "flex-end",
          }}
        >
          {!forceMobileView && <DrawerToggleButton open={open} setOpen={setOpen} />}
          <DonateButton size={isMobile ? "small" : "medium"} />
        </Container>
      </Toolbar>

      <S.ContentContainer maxWidth="sm">
        <S.ContentStack spacing={4}>
          <SelectStation fullWidth />

          <Song
            disableTextTruncation
            AlbumArtProps={{
              size: albumArtSize,
              sx: { mb: 4 },
            }}
            actionButton={<PlayerSettingsButton {...props} />}
          />
        </S.ContentStack>
      </S.ContentContainer>
    </SwipeableDrawer>
  );
};

export default PlayerDrawer;
