import {
  Container,
  ContainerProps,
  css,
  Stack,
  StackProps,
  styled,
} from "@mui/material";

const PREFIX = "jg-player-drawer";
export const classes = {
  root: PREFIX,
};

export const ToolbarContent = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContentContainer = styled(Container)<ContainerProps>(({ theme }) => css`
  margin-bottom: ${theme.jgComponents.Player.height}px;

  ${theme.breakpoints.up("sm")} {
    padding: ${theme.spacing(0, 4)};
  }
`);

export const ContentStack = styled(Stack)<StackProps>(({ theme }) => css`
  margin: ${theme.spacing(2, 0)};

  ${theme.breakpoints.up("sm")} {
    margin: ${theme.spacing(4, 0)};
  }
`);
