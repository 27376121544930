import { alpha, Box, Button, Card, Stack, Typography } from "@mui/material";
import { MouseEventHandler } from "react";
import { IoMdCheckmark } from "react-icons/io";
import JGIcon from "Pages/Components/JGIcon";

const PREFIX = "jg-gift-option";
const classes = {
  root: PREFIX,
  img: `${PREFIX}__img`,
  text: `${PREFIX}__text`,
  actions: `${PREFIX}__actions`,
};

export default function GiftSelect(props: {
  selected: boolean,
  onView?: MouseEventHandler<HTMLButtonElement>,
  onSelect: MouseEventHandler<HTMLButtonElement>,
  title?: string | JSX.Element,
  description: string | JSX.Element,
  imgSrc?: string,
  available?: boolean
}) {
  const {
    selected,
    title,
    description,
    imgSrc,
    onView,
    onSelect,
    available = true
  } = props;

  return (
    <Card
      className={classes.root}
      elevation={0}
      component={Stack}
      direction={{ xs: "column", sm: "row" }}
      spacing={1.5}
      sx={theme => {
        const borderColor = selected ? theme.palette.primary.main : theme.palette.divider;
        return {
          alignItems: "stretch",
          justifyItems: "stretch",
          px: 2,
          py: 1.5,
          border: `1px solid ${borderColor}`,
          borderRadius: `${theme.shape.borderRadii[4]}px`,
          boxShadow: selected ? `0 0 0 1px ${borderColor}` : undefined,
          backgroundColor: selected ? alpha(theme.palette.primary.main, 0.1) : "inherit",
          transition: theme.transitions.create(["background-color", "border-color", "box-shadow"]),
        };
      }}
    >
      <Stack direction="row" spacing={1.5} sx={{ flex: "auto", alignItems: "center" }}>
        {imgSrc && (
          <Box
            className={classes.img}
            sx={theme => ({
              flex: "none",
              width: 70,
              height: 70,
              borderRadius: 1,
              background: `${theme.palette.common.white} url("${imgSrc}") center/cover no-repeat`,
            })}
          />
        )}

        <Box
          className={classes.text}
          sx={{
            flex: "auto",
            ml: title ? undefined : 1,
          }}
        >
          {title && (
            <Typography variant={"body1"} fontWeight="bold" sx={{ lineHeight: 1.1 }}>
              {title}
            </Typography>
          )}

          <Typography variant={"body2"} sx={{ color: title ? "text.secondary" : undefined }}>
            {description}
          </Typography>
        </Box>
      </Stack>

      <Stack
        className={classes.actions}
        spacing={0.5}
        direction={{ xs: "row", sm: "column" }}
        sx={{
          alignItems: "stretch",
          justifyContent: "center",
          flexShrink: 0,
          width: { xs: "auto", sm: 80 },
        }}
      >
        <Button
          size="small"
          color={selected ? "primary" : (available ? "neutral" : "warning")}
          onClick={onSelect}
          sx={{
            flex: { xs: 1, md: "none" },
            height: 36,
          }}
        >
          {selected
            ? <JGIcon is={IoMdCheckmark} />
                      : (available ? "Select" : "Upgrade")
          }
        </Button>

        {onView && (
          <Button
            size="small"
            color="neutral"
            variant="outlined"
            onClick={onView}
            sx={{
              flex: { xs: 1, md: "none" },
              py: "6px",
            }}
          >
            View
          </Button>
        )}
      </Stack>
    </Card>
  )
}
