import { styled, Typography } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

export const truncateMixin: SystemStyleObject = Object.freeze({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

export const TruncatedTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
` as typeof Typography;
