import React  from "react";
import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@mui/material";

export type ConfirmationDialogProps = Omit<DialogProps, "onClose" | "title"> & {
  title?: string | JSX.Element;
  cancelText?: string;
  CancelButtonProps?: ButtonProps;
  confirmText?: string;
  ConfirmButtonProps?: ButtonProps;
  open: boolean;
  onClose: (confirm: boolean) => void;
};

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    title,
    cancelText = "Cancel",
    CancelButtonProps,
    confirmText = "Confirm",
    ConfirmButtonProps,
    open,
    onClose,
    children,
    ...rest
  } = props;

  const handleCancel = () => onClose(false);
  const handleConfirm = () => onClose(true);

  return (
    <Dialog
      maxWidth="xs"
      {...rest}
      open={open}
      PaperProps={{
        sx: { width: "100%" },
      }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}

      <DialogContent>
        {children}
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          variant="outlined"
          color="neutral"
          {...CancelButtonProps}
          onClick={handleCancel}
        >
          {cancelText}
        </Button>

        <Button
          color="primary"
          {...ConfirmButtonProps}
          onClick={handleConfirm}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
