import { css, GlobalStyles } from "@mui/material";

const ChatlioStyles = <GlobalStyles styles={theme => {
  const chatlio = theme.jgComponents.Chatlio;

  return css`
    // We use the id selector to get precedence over the "!important" flag Chatlio itself has on this property
    #chatlio-widget.chatlio-closed .chatlio-title-bar.chatlio-title-bar-chip .chatlio-title-text a {
      line-height: ${chatlio.chip.height}px !important;
    }

    .chatlio-widget {
      bottom: var(--jg-chatlio-bottom, 0) !important;
      z-index: ${theme.zIndex.player - 1} !important;
      font-size: ${theme.typography.body2.fontSize};

      ${theme.breakpoints.down("md")} {
        display: none;
      }

      &, button, input, textarea {
        font-family: ${theme.typography.fontFamily};
      }

      input,
      textarea {
        // To make the text fields easier to see.
        border: 1px solid #cbcbd2;
        background-color: #f1f1f6;
      }

      .chatlio-title-bar-button,
      .chatlio-title-bar,
      .chatlio-title-bar-chip {
        width: ${chatlio.chip.height}px;
        margin: ${chatlio.margin}px;
      }

      &.chatlio-closed .chatlio-title-bar.chatlio-title-bar-chip,
      .chatlio-title-bar-button,
      .chatlio-title-bar,
      .chatlio-title-bar-chip {
        height: ${chatlio.chip.height}px;
        line-height: ${chatlio.chip.height}px;
      }

      &.chatlio-closed .chatlio-title-bar.chatlio-title-bar-chip {
        .chatlio-title-text a {
          display: flex !important;
          flex-direction: row-reverse;
          flex-shrink: 0;
          align-items: center;
          height: 100%;
          padding: ${theme.spacing(0, 2)} !important;
          line-height: 1 !important;
          text-decoration: none;
        }
      }

      .chatlio-title-text {
        height: 100%;
        white-space: nowrap;
      }

      .chatlio-title-bar.chatlio-title-bar-chip {
        color: ${theme.palette.text.primary};
        border: ${chatlio.chip.borderWidth}px solid ${theme.palette.border.elevation};
        background-color: ${theme.palette.background.paper} !important;
      }

      .chatlio-widget-body {
        bottom: ${chatlio.chip.height + 8}px;
        margin: ${chatlio.margin}px;
        height: 500px;
        max-height: calc(100vh - var(--jg-player-height) - ${chatlio.chip.height}px - ${chatlio.margin}px);
      }

      .chatlio-controls-close {
        height: 100%;
      }

      .chatlio-online-dot {
        position: relative;
        margin: ${theme.spacing(0, 1, 0, 0)};
        border: 0;
        background-color: ${theme.palette.success.main};
      }
    }
  `;
}} />;

export default ChatlioStyles;
