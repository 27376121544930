import { Theme } from "@mui/material/styles";
import { ControlsSize } from "./types";

export default function calcControlsWidth(
  theme: Theme,
  numberOfButtons: 0 | 1 | 2,
  withBorder: boolean,
  size: ControlsSize = "lg",
): number {
  const controls = theme.jgComponents.Player.controls;
  const {
    playButtonSize,
    sideButtonSize,
    spacing,
    borderWidth,
  } = controls[size];

  // The controls have a double border around them, thus the double multiplier.
  const totalBorderWidth = (borderWidth * 2) * (withBorder ? 2 : 0);
  const sideButtonsWidth = (sideButtonSize + spacing) * numberOfButtons;
  return playButtonSize + sideButtonsWidth + totalBorderWidth;
}
