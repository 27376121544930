import React from "react";
import { ButtonProps, Link as MuiLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import * as S from "./AnnouncementBar.styles";

type AnnouncementButtonProps = ButtonProps & {
  announcement: ContentfulEntry.Announcement,
};

const AnnouncementButton = ({ announcement, ...rest }: AnnouncementButtonProps) => {
  const { buttonEnabled, buttonUrl, buttonText } = announcement;
  if (!buttonEnabled || !buttonUrl) return null;

  if (buttonUrl.includes("https://jazzgroove.org")) {
    return (
      <S.SButton
        size="small"
        component={RouterLink}
        to={buttonUrl.replace("https://jazzgroove.org", "")}
        {...rest as any}
      >
        {buttonText}
      </S.SButton>
    );
  } else {
    return (
      <S.SButton size="small" component={MuiLink} href={buttonUrl} {...rest as any}>
        {buttonText}
      </S.SButton>
    );
  }
}

export default AnnouncementButton;
