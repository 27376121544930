import React, { forwardRef } from "react";
import { Box, BoxProps, Stack, StackProps, Tooltip, TooltipProps, Typography } from "@mui/material";
import clsx from "clsx";
import { normalizeSx } from "Utils/Components/normalizeSx";
import AlbumArt, { AlbumArtProps } from "./AlbumArt";
import { useAppContext } from "Context";
import { truncateMixin } from "../Styles";
import { ElementOverflow, useElementOverflow } from "Hooks";
import { ControlsSize } from "./PlayerControls/types";
import * as S from "./Song.styles";

type SongProps = BoxProps & {
  size?: ControlsSize;
  disableTextTruncation?: boolean;
  direction?: "vertical" | "horizontal";
  actionButton?: React.ReactNode;
  AlbumArtProps?: Partial<AlbumArtProps>;
  ContentProps?: StackProps;
}

function getOverflowTooltipProps(overflow: ElementOverflow, disableTextTruncation: boolean): Partial<TooltipProps> {
  const disabled = disableTextTruncation || !overflow.x;

  return {
    disableHoverListener: disabled,
    disableFocusListener: disabled,
    disableTouchListener: disabled,
  };
}

export const Song = forwardRef<typeof Box, SongProps>((props, ref) => {
  const {
    size = "lg",
    direction = "vertical",
    disableTextTruncation = false,
    actionButton,
    AlbumArtProps,
    ContentProps,
    className,
    ...rest
  } = props;

  const { songData } = useAppContext();
  const titleOverflow = useElementOverflow();
  const artistOverflow = useElementOverflow();
  const { artist, title, src } = songData;

  return (
    <S.Container
      ref={ref}
      className={clsx(S.classes.root, `${S.classes.root}--direction-${direction}`, className)}
      {...rest}
    >
      <AlbumArt
        src={src}
        {...AlbumArtProps}
        sx={[
          { [direction === "vertical" ? "mb" : "mr"]: 2 },
          ...normalizeSx(AlbumArtProps?.sx),
        ]}
      />

      <S.Content direction="row" spacing={1} {...ContentProps}>
        <Stack direction="column" sx={{ minWidth: 0 }}>
          <Typography variant="overline" lineHeight={1} color="text.secondary" sx={{ mb: "0.25rem" }}>
            Now Playing
          </Typography>

          <Tooltip title={title} {...getOverflowTooltipProps(titleOverflow, disableTextTruncation)}>
            <Typography
              ref={titleOverflow.ref}
              variant={size === "lg" ? "h6" : "body1"}
              component="p"
              sx={{
                ...(disableTextTruncation ? null : truncateMixin),
                fontWeight: "bold",
                lineHeight: 1.2,
              }}
            >
              {title || "The Jazz Groove"}
            </Typography>
          </Tooltip>

          <Tooltip title={artist} {...getOverflowTooltipProps(artistOverflow, disableTextTruncation)}>
            <Typography
              ref={artistOverflow.ref}
              variant={size === "lg" ? "body2" : "caption"}
              sx={{
                ...(disableTextTruncation ? null : truncateMixin),
              }}
            >
              {artist}
            </Typography>
          </Tooltip>
        </Stack>

        {actionButton}
      </S.Content>
    </S.Container>
  );
});

export default Song;
