import React from "react";
import { IconType } from "react-icons/lib";
import {
  IoAlertCircleOutline,
  IoInformationCircleOutline,
  IoWarningOutline
} from "react-icons/io5";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IconProps } from "react-toastify/dist/components/Icons";
import { TypeOptions } from "react-toastify/dist/types";
import JGIcon from "Pages/Components/JGIcon";

const icons: Record<Exclude<TypeOptions, "default">, IconType> = {
  info: IoInformationCircleOutline,
  success: IoMdCheckmarkCircleOutline,
  warning: IoWarningOutline,
  error: IoAlertCircleOutline,
};

const ToastIcon = ({ type }: IconProps) => {
  if (type === "default") return null;

  return (
    <JGIcon is={icons[type]} fontSize="inherit" />
  );
};

export default ToastIcon;
