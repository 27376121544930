import { Chip, ChipProps, css, Paper, PaperProps, styled } from "@mui/material";

const PREFIX = "jg-card";
export const classes = {
  root: PREFIX,
  borderless: `${PREFIX}--borderless`,
  highlight: `${PREFIX}--highlight`,
  transparent: `${PREFIX}--transparent`
};

export const Container = styled(Paper)<PaperProps>(({ theme }) => {
  const borderWidthElevation = theme.shape.borderWidthElevation;
  const borderWidthAccent = theme.shape.borderWidthAccent;
  const borderRadii = theme.shape.borderRadii;

  return css`
    --jg-card-border-width-elevation: ${borderWidthElevation}px;
    --jg-card-border-width-accent: 0px;
    --jg-card-border-radius: ${borderRadii[4]}px;
    --jg-card-highlight-color: ${theme.palette.primary.main};

    position: relative;
    overflow: visible;
    border: var(--jg-card-border-width-elevation) solid ${theme.palette.border.elevation};
    border-radius: var(--jg-card-border-radius);
    box-shadow: none;

    &.${classes.highlight} {
      --jg-card-border-width-accent: ${borderWidthAccent}px;
    }

    &.${classes.borderless} {
      --jg-card-border-width-elevation: 0px;
      --jg-card-border-radius: ${borderRadii[3]}px;
    }

    &.${classes.transparent} {
      background: transparent;
    }
  `;
});

export const Content = styled(Paper)<PaperProps>(({ theme }) => css`
  padding: ${theme.spacing(2)};
  border-radius: calc(
    var(--jg-card-border-radius) - var(--jg-card-border-width-elevation) - var(--jg-card-border-width-accent)
  );
  background: inherit;
  box-shadow: inset 0 0 0 var(--jg-card-border-width-accent) var(--jg-card-highlight-color);
  &.${classes.transparent} {
    background: transparent;
  }
`);

export const CardChip = styled(Chip)<ChipProps>(({ theme }) => css`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: ${theme.spacing(0, 1)};
`);
