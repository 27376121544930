import ErrorCode from "./Constants/ErrorCode"
import { Link } from "@mui/material"
import {useAuth0} from "../react-auth0-spa"

export default function useErrorMessages(): {[key in ErrorCode]: string | JSX.Element} {
    const authState = useAuth0()
    return {
        [ErrorCode.UserExists]: <>User already exists, please <Link onClick={() => authState.loginAndReturn()}>sign in</Link> to continue.</>
    }
}
