import {
  css,
  OutlinedInput, outlinedInputClasses, OutlinedInputProps,
  Select, SelectProps, selectClasses,
  styled, MenuItem, Stack, StackProps
} from "@mui/material";
import { TruncatedTypography } from "../../Styles";
import { ReactComponent as RadioTowerIcon } from "Assets/icons/radio-tower.svg";

const PREFIX = "jg-select-station";
export const classes = {
  root: PREFIX,
  inputStationName: `${PREFIX}__input__station-name`,
  option: `${PREFIX}__option`,

  widthFull: `${PREFIX}--width-full`,
};

export const StyledSelect = styled(Select as (props: SelectProps<any>) => JSX.Element)(({ theme }) => css`
  & .${selectClasses.icon} {
    font-size: 16px;
    right: ${theme.spacing(2)};
  }
`);

export const Input = styled(OutlinedInput)<OutlinedInputProps>(({ theme }) => {
  const player = theme.jgComponents.Player;
  const borderWidthElevation = theme.shape.borderWidthElevation;
  const borderWidthAccent = theme.shape.borderWidthAccent;
  const width = 240 + borderWidthElevation * 2;

  return css`
    height: ${player.height - (player.paddingVertical * 2)}px;
    width: ${width}px;
    border-radius: 60px;
    border: ${borderWidthElevation}px solid ${theme.palette.border.elevation};
    background-color: ${theme.palette.background.paper};
    overflow: hidden;

    &.${classes.widthFull} {
      width: 100%;
    }

    ${theme.breakpoints.between("md", "lg")} {
      &:not(.${classes.widthFull}) {
        width: ${width - 16}px;
      }
    }

    & .${outlinedInputClasses.notchedOutline},
    &:hover .${outlinedInputClasses.notchedOutline} {
      border: ${borderWidthAccent}px solid ${theme.palette.primary.main};
      border-radius: inherit;
    }

    & .${outlinedInputClasses.input} {
      padding-left: ${24 + borderWidthAccent}px;
      padding-right: ${24 + borderWidthAccent}px;

      ${theme.breakpoints.between("md", "lg")} {
        padding-left: ${16 + borderWidthAccent}px;
        padding-right: ${16 + borderWidthAccent}px;
      }
    }
  `;
});

export const InputStationName = styled(TruncatedTypography)(({ theme }) => css`
  width: 100%;
  font-weight: bold;
  text-align: left;
  color: ${theme.palette.primary.main};
`);

export const Background = styled(RadioTowerIcon)(({ theme }) => css`
  position: absolute;
  right: -24px;
  top: -44px;
  width: 148px;
  height: 148px;
  color: ${theme.palette.text.primary};
  opacity: 0.05;
`);

export const Option = styled(MenuItem)(({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${theme.spacing(1)};
  padding: ${theme.spacing(1, 2, 1, 1.5)};
  background-color: ${theme.palette.background.default};
  border-radius: 12px;
`);

export const StationContainer = styled(Stack)<StackProps>`
  min-width: 0;
  overflow: hidden;
  align-items: center;
`;

export const StationDescription = styled(TruncatedTypography)(({ theme }) => css`
  min-width: 0;
  max-width: 100%;
  color: ${theme.palette.text.disabled};
`);
