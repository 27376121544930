import queryString, { ParsedQuery, ParseOptions } from "query-string";

type ParsedValue<T = string> = ParsedQuery<T>[keyof ParsedQuery<T>];

function getKey<T = string>(parsedQuery: ParsedQuery<T>, key: keyof ParsedQuery<T>): ParsedValue<T> {
  return parsedQuery[key];
}

export function parseQSValue(key: string, qs?: string, options?: ParseOptions): ParsedValue;
export function parseQSValue(key: string, qs?: string, options?: {parseBooleans: true, parseNumbers: true} & ParseOptions): ParsedValue<string | boolean | number>;
export function parseQSValue(key: string, qs?: string, options?: {parseBooleans: true} & ParseOptions): ParsedValue<string | boolean>;
export function parseQSValue(key: string, qs?: string, options?: {parseNumbers: true} & ParseOptions): ParsedValue<string | number>;
export function parseQSValue(key: string, qs = window.location.search, options?: ParseOptions): ParsedValue {
  return getKey(queryString.parse(qs, options), key);
}
