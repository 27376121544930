import React, { useEffect, useMemo, useState } from "react";
import {
  AlertProps, Box,
  css, GlobalStyles, IconButton,
  Stack,
  useMediaQuery, useTheme,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import { formatCurrency } from "Utils/Format";
import { normalize } from "Utils/Components/Progress";
import AnnouncementButton from "./AnnouncementButton";
import JGIcon from "Pages/Components/JGIcon";
import DonateButton from "Pages/Components/DonateButton";
import * as S from "./AnnouncementBar.styles";
import clsx from "clsx";
import ContentfulClient from "Utils/ContentfulClient";
import { CONTENTFUL_ANNOUNCEMENT_ENTRY_ID } from "Utils/Constants";
import { isRedirectedFromMobileApp } from "Utils/RedirectedFromMobileApp";

const AnnouncementBar = (props: AlertProps) => {
  const [announcementVisible, setAnnouncementVisible] = useState(false);
  const [announcement, setAnnouncement] = useState<ContentfulEntry.Announcement | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const hide = () => {
    setAnnouncementVisible(false);
  };

  useEffect(() => {
    (async () => {
      const entry = await ContentfulClient.getEntry<ContentfulEntry.Announcement>(CONTENTFUL_ANNOUNCEMENT_ENTRY_ID);
      if (entry.fields.announcementEnabled && !isRedirectedFromMobileApp()) {
        setAnnouncement({ ...entry.fields });
        setAnnouncementVisible(entry.fields.announcementEnabled);
      }
    })();
  }, []);

  const globalStyles = useMemo(() => {
    const { height, heightFundDrive } = theme.jgComponents.Announcement;
    let announcementBarHeight = 0;
    if (announcementVisible && announcement?.announcementEnabled) {
      announcementBarHeight = (announcement.fundDriveEnabled && !isMobile) ? heightFundDrive : height;
    }

    return (
      <GlobalStyles styles={css`
        :root {
          --jg-announcement-bar-height: ${announcementBarHeight}px;
        }
      `} />
    );
  }, [announcement, announcementVisible, theme, isMobile]);

  if (!announcementVisible || !announcement || !announcement.announcementEnabled) return null;
  return (
    <>
      {globalStyles}
      <S.Container
        className={clsx(
          S.classes.root,
          announcement.fundDriveEnabled ? S.classes.variantFundDrive : S.classes.variantStandard,
        )}
        icon={false}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={hide}
          >
            <JGIcon is={IoClose} fontSize="inherit" />
          </IconButton>
        }
        {...props}
      >
        <S.ContentContainer>
          <S.Content className={S.classes.content}>
            {announcement.fundDriveEnabled && !isMobile ? (
              <Stack sx={{ alignItems: "center" }}>
                {announcement.content}

                <S.FundDriveProgress
                  variant="determinate"
                  value={normalize(announcement.fundDriveRaised || 0, 0, announcement.fundDriveGoal || 0)}
                />

                <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                  <Box>
                    {formatCurrency(announcement.fundDriveRaised || 0, 0, 0)} Raised
                    <Box component="span" sx={{ color: "text.disabled" }}> | </Box>
                    Goal: {formatCurrency(announcement.fundDriveGoal || 0, 0, 0)}
                  </Box>

                  <DonateButton size="small" onClick={hide} sx={{ px: 2 }} />
                </Stack>

              </Stack>
            ) : (
              <>
                {announcement.content}
                <AnnouncementButton announcement={announcement} sx={{ ml: 1 }} />
              </>
            )}
          </S.Content>
        </S.ContentContainer>
      </S.Container>
    </>
  );
}

export default AnnouncementBar;
