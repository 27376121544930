import { useMemo, useState } from "react";
import { PaletteMode, Theme } from "@mui/material";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import { DEFAULT_THEME_ID } from "Utils/Constants";
import { StationThemeContext } from "./Context";
import getThemeOptions from "Utils/Themes/getThemeOptions";
import { responsiveFontVariants } from "Utils/Themes/Typography";

function createStationTheme(themeId: StationId, mode: PaletteMode): Theme {
  return responsiveFontSizes(
    createTheme(getThemeOptions(themeId, mode)),
    { variants: responsiveFontVariants as any },
  );
}

/**
 * Use to change the theme to match the given station, as well as to switch between light and dark modes.
 * Used alongside MUI's {@link ThemeProvider}.
 */
export const StationThemeProvider = ({ children }) => {
  const [themeId, setThemeId] = useState<StationId>(DEFAULT_THEME_ID);

  const lightTheme = useMemo(() => {
    return createStationTheme(themeId, "light");
  }, [themeId]);

  const darkTheme = useMemo(() => {
    return createStationTheme(themeId, "dark");
  }, [themeId]);

  return (
    <StationThemeContext.Provider value={{ themeId, switchTheme: setThemeId, lightTheme, darkTheme }}>
      <ThemeProvider theme={darkTheme}>
        {children}
      </ThemeProvider>
    </StationThemeContext.Provider>
  );
}
