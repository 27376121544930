import moment from "moment";
import http from "../Utils/Http";
import { getTokenSilently, getUser } from "../react-auth0-spa";
import UserApi from "./UserApi";
import {
  createDisplayDateData,
  DisplayRelativeDateData,
} from "Utils/Account/Subscription";
import {
  DONATION_HISTORY_ENDPOINT,
  NOTIFY_PLAN_EXPIRATION_DAYS_THRESHOLD,
  RENEW_PLAN_EXPIRATION_DAYS_THRESHOLD,
} from "Utils/Constants";
import { Subscription } from "../../types/Subscription";
import { PlanCode, PlanFrequency } from "../../types/Plan";

export interface SubscriptionHistory {
  message?: string;
  list: { subscription: Subscription }[];
}

export interface ExpiringSubscription {
  id?: string;
  planCode: PlanCode;
  originalPlanCode: PlanCode;
  termEnd: DisplayRelativeDateData;
  legacyUpgradeOffer?: LegacyUpgradeOffer;
  donationType: PlanFrequency;
  amount: number;
  autoRenew?: boolean;
  renewButtonOnly?: boolean;
}

export interface LegacyUpgradeOffer {
  coupon: string;
  name: string;
  donationType: PlanFrequency;
}

export interface DonationHistory {
  amount: number;
  donationType: PlanFrequency;
  planId: PlanCode;
  planName: "Premium Plus" | "Unlimited Listening" | "Standard";
  originalPlanId: PlanCode;
  originalPlanName?: string;
  donationDate: number | string;
  nextBillDate?: number | string;
  expirationDate: number | string;
  metadata?: Metadata;
  subscriptionId: string;
  legacyUpgradeOffer?: LegacyUpgradeOffer;
  currentPlan: boolean;
}

export interface BankedDonation {
  amount: number;
  donationDate: Date;
  customerId: string;
}

export interface Metadata {
  free_trial: boolean;
}

export interface UserHistory {
  customerId: string;
  activeDonations: DonationHistory[];
  pastDonations: DonationHistory[];
  bankedDonations: BankedDonation[];
}

interface UpdateSubscriptionDto {
  subscription: Pick<
    Subscription,
    "id" | "plan_id" | "plan_unit_price" | "billing_period_unit"
  >;
  /**
   * The price will be in cents. For example, $60 will be 6000.
   */
  newAmount: Subscription["plan_amount"];
  coupon?: string;
}

async function getAuth0AccessToken(): Promise<string> {
  return getTokenSilently();
}

const SubscriptionApi = {
  async get(id: string): Promise<Subscription> {
    const accessToken = await getAuth0AccessToken();
    const response = await http.post<{ subscription: Subscription }>(
      DONATION_HISTORY_ENDPOINT,
      { accessToken, subscriptionId: id }
    );

    return response.subscription;
  },

  async update(dto: UpdateSubscriptionDto): Promise<void> {
    return http.post("/donate/modify", dto);
  },

  async cancel(id: string): Promise<void> {
    return http.post("/donate/cancel", { subscriptionId: id });
  },

  async reactivate(id: string, billingCycles?: number): Promise<void> {
    return http.post("/donate/reactivate", { id, billingCycles });
  },

  async reload(email: string): Promise<Subscription | null> {
    if (!email) return null;
    const dbUser = await UserApi.getDBUser(email, { sync: true });
    const { subscriptionId } = dbUser;

    if (!subscriptionId || subscriptionId === "promo") return null;

    // Verify that the subscription ID in the database is also present in Chargebee.
    return (await this.get(subscriptionId)) || null;
  },

  async getUserHistory(): Promise<UserHistory> {
    const accessToken = await getAuth0AccessToken();
    const URI =
      "/user/history" +
      (process.env.REACT_APP_NODE_ENV === "dev" ? "_dev" : "");
    return http.get<UserHistory>(URI, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getDonationExpirationAsMoment(donation: DonationHistory): moment.Moment {
    return moment(donation.expirationDate);
  },

  /**
   * Get the expiring subscription.
   */
  async getExpiring(): Promise<ExpiringSubscription | null> {
    let expiringSub: ExpiringSubscription | null = null;

    const userHistory = await this.getUserHistory();
    let warningThreshold = 0;
    let renewThreshold = 0;
    const now = moment().unix();
    const isDev = process.env.REACT_APP_NODE_ENV === "dev";
    if (userHistory.activeDonations.length) {
      for (const donation of userHistory.activeDonations) {
        const expireMoment = this.getDonationExpirationAsMoment(donation);

        warningThreshold = expireMoment
          .subtract(NOTIFY_PLAN_EXPIRATION_DAYS_THRESHOLD, "days")
          .unix();
        renewThreshold = expireMoment
          .subtract(RENEW_PLAN_EXPIRATION_DAYS_THRESHOLD, "days")
          .unix();
        if (
          (donation.currentPlan &&
            donation.expirationDate !== undefined &&
            !donation.metadata?.free_trial &&
            now >= renewThreshold &&
            donation?.originalPlanId !== "premium-plus-lite") ||
          (isDev &&
            donation.expirationDate !== undefined &&
            !donation.metadata?.free_trial &&
            now >= renewThreshold &&
            donation?.originalPlanId !== "premium-plus-lite")
        ) {
          expiringSub = {
            id: donation.subscriptionId,
            amount: donation.amount,
            donationType: donation.donationType,
            legacyUpgradeOffer: donation.legacyUpgradeOffer,
            planCode: donation.planId as PlanCode,
            originalPlanCode: donation.originalPlanId as PlanCode,
            termEnd: createDisplayDateData(donation.expirationDate, true),
            renewButtonOnly:
              now >= renewThreshold && !(now >= warningThreshold),
          };
          break;
        }
      }
    } else {
      const user = await getUser();
      if (user.plan && !user.subscriptionId && user.ttl) {
        warningThreshold = moment(user.ttl)
          .subtract(NOTIFY_PLAN_EXPIRATION_DAYS_THRESHOLD, "days")
          .unix();
        if (now >= warningThreshold) {
          expiringSub = {
            id: user.subscriptionId,
            planCode: user.plan,
            originalPlanCode: user.plan,
            termEnd: createDisplayDateData(moment.unix(user.ttl), true),
            amount: 0,
            donationType: "monthly",
          };
        }
      }
    }

    return expiringSub;
  },
};

export default SubscriptionApi;
