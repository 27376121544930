import React, { SVGProps } from "react";
import { useTheme } from "@mui/material";

const PremiumSkipIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  const primary = theme.palette.primary;
  const white = theme.palette.common.white;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 165 165"
      fill="none"
      width="100%"
      height="100%"
      {...props}
    >
      <path
        d="M82.5 165c45.563 0 82.5-36.937 82.5-82.5S128.063 0 82.5 0 0 36.937 0 82.5 36.937 165 82.5 165Z"
        fill={primary.light}
      />
      <path
        d="M82.189 134.46c28.789 0 52.128-23.338 52.128-52.128s-23.339-52.127-52.128-52.127c-28.79 0-52.128 23.338-52.128 52.127 0 28.79 23.338 52.128 52.128 52.128Z"
        fill={primary.dark}
      />
      <path
        d="M106.386 53.516c-.766 0-1.364.622-1.364 1.364v22.474a8.064 8.064 0 0 0-2.37-2.13L68.834 55.622c-3.95-2.273-8.975-.933-11.25 3.016a8.285 8.285 0 0 0-1.1 4.117v39.131a8.233 8.233 0 0 0 12.35 7.133l33.938-19.554a8.385 8.385 0 0 0 2.369-2.13v22.45a1.365 1.365 0 0 0 2.729 0v-54.88c0-.767-.598-1.365-1.364-1.389h-.12Z"
        fill={white}
      />
    </svg>
  );
};

export default PremiumSkipIcon;
