import { forwardRef } from "react";
import NumberFormat, { InputAttributes, NumberFormatProps } from "react-number-format";

export type NumberFormatInputChangeEvent = { target: { name: string; value: string } };
export type NumberFormatInputProps = NumberFormatProps & {
  name: string;
  onChange: (event: NumberFormatInputChangeEvent) => void;
};

const NumberFormatInput = forwardRef<
  NumberFormat<InputAttributes>,
  NumberFormatInputProps
>((props, ref) => {
  const { onChange, ...rest } = props;
  return (
    <NumberFormat
      {...rest}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
});

export default NumberFormatInput;
