import React from "react";
import { SvgIconProps } from "@mui/material";
import { IoCloseCircleOutline, IoStar, IoStarOutline } from "react-icons/io5";
import { PlanFeature, PlanTierKey } from "Utils/Constants";
import { isIncludedInPlan } from "Utils/Components/Plan";
import JGIcon from "../../JGIcon";
import CheckCircleIcon from "../../Icons/CheckCircleIcon";

type PlanFeatureIconProps = SvgIconProps & {
  /** Whether to use star icons instead of the default icons. */
  star: boolean;
  feature: PlanFeature;
  tier: PlanTierKey;
  giftEnabled: boolean
}

const PlanFeatureIcon = ({ star, feature, tier, giftEnabled, ...rest }: PlanFeatureIconProps) => {
  const iconProps: SvgIconProps = {
    fontSize: "small",
    ...rest,
  };

  if (star) {
    return isIncludedInPlan(feature, tier, giftEnabled)
      ? <JGIcon is={IoStar} color="warning" {...iconProps} />
      : <JGIcon is={IoStarOutline} color="disabled" {...iconProps} />;
  }

  return isIncludedInPlan(feature, tier, giftEnabled)
    ? <CheckCircleIcon color="success" {...iconProps} />
    : <JGIcon is={IoCloseCircleOutline} color="disabled" {...iconProps} />;
};

export default PlanFeatureIcon;
