import { Box, css, styled } from "@mui/material";

const PREFIX = "jg-page";
export const classes = {
  root: PREFIX,
  body: `${PREFIX}__body`,
  content: `${PREFIX}__content`,
};

export const Container = styled(Box)(({ theme }) => {
  return css`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
  `;
});

export const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: auto;
  position: relative;
`;

export const Content = styled("main")`
  min-height: var(--jg-content-min-height);
`;
