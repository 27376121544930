import { useTheme } from "@mui/material";
import calcControlsWidth from "./calcControlsWidth";
import { ControlsSize, PlayerBarProps } from "./types";
import playerClasses from "../classes";

interface SvgInfo {
  viewBox: string;
  pathD: string;
}

interface SvgInfoRecord {
  circular: SvgInfo
  sideButtons: SvgInfo;
}

const svgInfoSizeMap: Record<ControlsSize, SvgInfoRecord> = {
  sm: {
    circular: {
      viewBox: "0 0 100 100",
      pathD: "M94.6613 50C94.6613 74.6658 74.6657 94.6613 50 94.6613C25.3342 94.6613" +
        "5.33868 74.6658 5.33868 50C5.33868 25.3343 25.3342 5.33875 50 5.33875C74.6657 " +
        "5.33875 94.6613 25.3343 94.6613 50Z",
    },
    sideButtons: {
      viewBox: "0 0 144 100",
      pathD: "M88.8758 72C81.2018 85.5314 66.6671 94.6613 50 94.6613C25.3342 94.6613 5.33868 " +
        "74.6658 5.33868 50C5.33868 25.3343 25.3342 5.33875 50 5.33875C67.0744 5.33875 81.911 " +
        "14.9203 89.4261 29H113.5C125.374 29 135 38.6259 135 50.5C135 62.3741 125.374 72 113.5 72H88.8758Z",
    },
  },
  lg: {
    circular: {
      viewBox: "0 0 156 156",
      pathD: "M148 78c0 38.66-31.34 70-70 70S8 116.66 8 78 39.34 8 78 8s70 31.34 70 70Z",
    },
    sideButtons: {
      viewBox: "0 0 214 156",
      pathD: "M145.089 105C134.714 131.351 109.035 150 79 150C39.7878 150 8 118.212 8 79C8 " +
        "39.7878 39.7878 8 79 8C107.491 8 132.063 24.7819 143.369 49H176C191.464 49 204 61.536 " +
        "204 77C204 92.464 191.464 105 176 105H145.089Z",
    },
  },
}

const PlayerControlsBackground = (props: PlayerBarProps) => {
  const { sideButtonsHidden, size } = props;

  const theme = useTheme();
  const { borderWidth } = theme.jgComponents.Player.controls[size];
  /**
   * Unlike {@link PlayerControls}'s width, this does not account for the placeholder space on the left-hand
   * side of the play button. This is the width of background, which only encompasses the play and skip buttons.
   */
  const width = calcControlsWidth(theme, sideButtonsHidden ? 0 : 1, true, size);

  // Height will always be the same as the width without any side buttons.
  const height = calcControlsWidth(theme, 0, true, size);

  const svgInfo = svgInfoSizeMap[size][sideButtonsHidden ? "circular" : "sideButtons"];
  const backgroundPathId = `${playerClasses.controls}__bg`;

  return (
    <div style={{
      position: "absolute",
      right: -borderWidth * 2,
    }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={svgInfo.viewBox}
        width={width}
        height={height}
        fill="none"
      >
        <defs>
          <path
            id={backgroundPathId}
            fill={theme.palette.background.paper}
            d={svgInfo.pathD}
          />
        </defs>

        <use
          href={"#" + backgroundPathId}
          stroke={theme.palette.border.elevation}
          strokeWidth={borderWidth * 2}
        />
        <use href={"#" + backgroundPathId} />
      </svg>
    </div>
  );
};

export default PlayerControlsBackground;
