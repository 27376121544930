import { css, IconButton, IconButtonProps, styled } from "@mui/material";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import calcControlsWidth from "./calcControlsWidth";
import { OwnerStateProps } from "./types";
import playerClasses from "../classes";

const PREFIX = playerClasses.controls;
export const classes = {
  root: `${PREFIX}`,
  playButton: `${PREFIX}__play-button`,
  skipButton: `${PREFIX}__skip-button`,
  leftButton: `${PREFIX}__left-button`,
  bar: `${PREFIX}__bar`,
};

export const Container = styled("div")<OwnerStateProps>(({ theme, ownerState }) => {
  const size = ownerState.controlsSize;
  const borderWidth = theme.jgComponents.Player.controls[size].borderWidth;
  const width = calcControlsWidth(theme, 0, true, size);

  return css`
    position: relative;
    top: 0;
    flex: 0;
    width: ${width}px;
    margin: 0 ${borderWidth * 2}px;
    padding: 0 ${borderWidth * 2}px;
    text-align: center;
    transform: scale(1);
    transition: ${theme.transitions.create(["flex", "margin", "top", "width", "transform"])};
    pointer-events: none;

    .${playerClasses.drawerOpen} & {
      flex: 1;
      top: -${borderWidth * 2}px;
      transform: scale(1.1);
    }

    ${theme.breakpoints.down("md")} {
      .${playerClasses.drawerClosed} & {
        margin-left: -${theme.spacing(2)};
      }
    }

    ${theme.breakpoints.between("md", "lg")} {
      transform: scale(0.8);
    }
  `;
});

export const PlayButton = styled(LoadingButton)<
  LoadingButtonProps & OwnerStateProps
>(({ theme, ownerState }) => {
  const {
    controlsSize,
    paused
  } = ownerState;

  const player = theme.jgComponents.Player;
  const buttonSize = player.controls[controlsSize].playButtonSize;

  return css`
    // Pixels from the top to move the button to vertically center it in the player.
    top: calc((var(--jg-player-height) - ${buttonSize}px) / 2);
    width: ${buttonSize}px;
    height: ${buttonSize}px;
    padding: 0;
    font-size: ${buttonSize / 2.3}px;
    border-radius: 50%;
    transition: ${theme.transitions.create(["top", "width"])};
    pointer-events: auto;

    & .${classes.playButton}__icon path {
      // The play button in Ionicons isn't visually centered, so we balance it ourselves.
      transform: ${paused ? "translate(0.5em)" : "translate(0)"};
    }
   `;
});

export const Bar = styled("div")<OwnerStateProps>(({ theme, ownerState }) => {
  const {
    sideButtonsHidden,
    controlsSize,
  } = ownerState;

  const width = calcControlsWidth(theme, sideButtonsHidden ? 0 : 2, false, controlsSize);

  return css`
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: ${width}px;
    margin: 0;
    transform: translate(-50%, -50%);

    .${classes.root}--side-buttons-two & {
      justify-content: space-between;
    }
  `;
});

export const LeftButton = styled("div")<OwnerStateProps>(({ theme, ownerState }) => css`
  position: relative;
  left: -${theme.jgComponents.Player.controls[ownerState.controlsSize].borderWidth * 2}px;
  pointer-events: auto;
`);

export const SkipButton = styled(IconButton)<IconButtonProps & OwnerStateProps>(({ theme, ownerState }) => {
  const sideButtonSize = theme.jgComponents.Player.controls[ownerState.controlsSize].sideButtonSize;

  return css`
    width: ${sideButtonSize}px;
    height: ${sideButtonSize}px;
    pointer-events: auto;
  `;
});
