import { css, styled } from "@mui/material";
import JGIcon from "../../JGIcon";
import { classes as playerDrawerClasses } from "./PlayerDrawer.styles";

const PREFIX = `${playerDrawerClasses.root}-toggle`;
export const classes = {
  root: PREFIX,
  open: `${PREFIX}--open`,
  closed: `${PREFIX}--closed`,
};

export const DrawerToggleIcon = styled(JGIcon)(({ theme }) => css`
  opacity: 1;
  transition: ${theme.transitions.create(["opacity"])};
`);
