import { Components, Theme } from "@mui/material/styles";
import { dialogTitleClasses } from "@mui/material";

const padding = 32;

const Dialog: Components<Theme> = {
  MuiDialog: {
    styleOverrides: {
      paperWidthXs: {
        maxWidth: 424,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: `${padding}px ${padding}px ${padding/2}px`,
        textAlign: "center",
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding,
        textAlign: "center",

        [`.${dialogTitleClasses.root} + &`]: {
          paddingTop: 0,
        },
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      spacing: {
        justifyContent: "center",
        padding: `0 ${padding}px ${padding}px`,
        "& > .MuiButton-root": {
          flex: 1,
        },
      },
    },
  },
};

export default Dialog;
