import { Components, Theme } from "@mui/material/styles";
import { alpha, inputBaseClasses, outlinedInputClasses } from "@mui/material";

const inputPaddingY = 12.5;
const inputPaddingYSmall = 8.5;

const getDisabledBorderColor = (theme: Theme) => theme.palette.action.focus;

const Form: Components<Theme> = {
  MuiInputBase: {
    styleOverrides: {
      root: {
        textAlign: "left",
      },
    },
  },

  MuiInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.shape.borderRadii[0],
      }),
    },
  },

  MuiOutlinedInput: {
    defaultProps: {
      notched: false,
    },
    styleOverrides: {
      root: ({ theme }) => {
        const classes = outlinedInputClasses;

        const bgColor = theme.palette.action.active;
        const disabledBgColor = alpha(bgColor, 0.03);

        return {
          borderRadius: theme.shape.borderRadii[0],
          background: theme.palette.action.hover,

          "label + &": {
            marginTop: theme.spacing(2.5),
          },

          [`&.${classes.disabled}`]: {
            color: theme.palette.action.disabled,
            WebkitTextFillColor: "inherit",
            background: disabledBgColor,
          },

          [`& .${classes.input}[readonly]`]: {
            color: theme.palette.text.secondary,
            WebkitTextFillColor: "inherit",
            cursor: "default",
          },

          [`&.${classes.disabled} .${classes.notchedOutline}`]: {
            borderColor: getDisabledBorderColor(theme),
          },
        };
      },

      input: ({ ownerState, theme }) => ({
        [`&:not(.${inputBaseClasses.inputMultiline})`]: {
          paddingTop: inputPaddingY,
          paddingBottom: inputPaddingY,
        },

        [`&.${inputBaseClasses.inputSizeSmall}`]: {
          paddingTop: inputPaddingYSmall,
          paddingBottom: inputPaddingYSmall,
        },

        ...(ownerState?.readOnly && {
          [`& + .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: getDisabledBorderColor(theme),
          },
        }),
      }),

      multiline: {
        paddingTop: inputPaddingY,
        paddingBottom: inputPaddingY,

        [`&.${inputBaseClasses.inputSizeSmall}`]: {
          paddingTop: inputPaddingYSmall,
          paddingBottom: inputPaddingYSmall,
        },
      },
    },
  },

  MuiInputLabel: {
    defaultProps: {
      shrink: true,
    },
    styleOverrides: {
      outlined: ({ theme }) => ({
        color: theme.palette.text.primary,
        fontSize: theme.typography.caption.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        transform: `translate(0, -1.5px)`,
      }),
    },
  },

  MuiFormHelperText: {
    styleOverrides: {
      root: {
        margin: "4px 0 0 0",
      },
    },
  },
};

export default Form;
