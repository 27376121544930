import moment from "moment";
import { PlanFrequency, PlanTier } from "../../../types/Plan";
import { Subscription } from "../../../types/Subscription";
import { getPlanTierByCode } from "../Components/Plan";
import { daysUntil, formatRelativeDate, isWithinTimeOf } from "../Date";
import { DEFAULT_DATE_FORMAT, NOTIFY_PLAN_EXPIRATION_DAYS_THRESHOLD } from "../Constants";
import { isNil } from "../Inspect";

interface DisplayDateData {
  date: Date;
  formattedDate: string;
}

export type DisplayRelativeDateData = DisplayDateData & {
  /**
   * Uses {@link formatRelativeDate}.
   *
   * @example
   * "today", "tomorrow", "in 1 day", "in 3 days"
   */
  relativeFormattedDate: string;

  /**
   * Do NOT use for expiration. Use {@link expired}.
   */
  daysUntil: number;

  /**
   * We use this for expiration instead of {@link daysUntil} because it takes account of time of day.
   */
  expired: boolean;
}

export interface SubscriptionDisplayData {
  name: string;
  tier: PlanTier;
  amount: number;
  frequency: PlanFrequency;

  /**
   * Whether the plan associated with the subscription is expiring soon.
   * Determined by status, {@link termEnd}, and {@link NOTIFY_PLAN_EXPIRATION_DAYS_THRESHOLD}.
   */
  planExpiring?: boolean;

  started: DisplayDateData;
  termEnd: DisplayRelativeDateData;
  cancelled?: DisplayRelativeDateData;
}

/**
 * Get, parse, and calculate data to display the subscription to the user.
 * Create an object containing information about the {@link PlanTier} associated with the given subscription.
 */
export function getSubscriptionDisplayData(subscription: Subscription): SubscriptionDisplayData {
  const {
    status,
    plan_id,
    started_at,
    cancelled_at,
    setup_fee,
    plan_amount = 0,
  } = subscription;
  const tier = getPlanTierByCode(plan_id);
  const cancelledAt = (!isNil(cancelled_at) && moment.unix(cancelled_at)) || null;
  const frequency = getPlanFrequency(subscription);
  const amount = setup_fee || plan_amount;
  const termEnd = createDisplayDateData(moment(getTermEndDate(subscription)), true);
  const planExpiring = termEnd && !termEnd.expired && termEnd.daysUntil <= NOTIFY_PLAN_EXPIRATION_DAYS_THRESHOLD;

  return {
    name: tier.name,
    tier,
    amount,
    frequency,
    planExpiring: !["active", "cancelled"].includes(status) && planExpiring,
    started: createDisplayDateData(moment.unix(started_at), false),
    termEnd,
    cancelled: (cancelledAt && createDisplayDateData(cancelledAt, true)) || undefined,
  };
}

export function createDisplayDateData(value: moment.MomentInput, withRelative: false): DisplayDateData;
export function createDisplayDateData(value: moment.MomentInput, withRelative: true): DisplayRelativeDateData;
export function createDisplayDateData(
  value: moment.MomentInput,
  withRelative: boolean,
): DisplayDateData | DisplayRelativeDateData {
  const date = moment(value);

  let displayData: DisplayDateData = {
    date: date.toDate(),
    formattedDate: date.format(DEFAULT_DATE_FORMAT),
  };
  if (!withRelative) return displayData;

  (displayData as DisplayRelativeDateData) = {
    ...displayData,
    relativeFormattedDate: formatRelativeDate(date, "day"),
    daysUntil: daysUntil(date),
    expired: date.isBefore(moment()),
  };

  return displayData;
}

export function isActive(subscription: Subscription): boolean {
  const { status, plan_id } = subscription;
  return (
    (status === "active" || status === "in_trial")
    && plan_id !== "unlimited-listening"
  );
}

/**
 * Get the date the subscription ends or the date the user will be billed next.
 */
export function getTermEndDate(subscription: Subscription): Date {
  const {
    billing_period_unit,
    current_term_end,
    remaining_billing_cycles = 0,
  } = subscription;

  return moment.unix(current_term_end)
    .add(remaining_billing_cycles, billing_period_unit)
    .toDate();
}

function getPlanFrequency(subscription: Subscription): PlanFrequency {
  const {
    status,
    billing_period_unit,
    current_term_end,
    remaining_billing_cycles = 0,
    cancel_schedule_created_at,
    setup_fee,
    started_at,
  } = subscription;

  let isOneTime = false;
  if (status !== "active") {
    isOneTime = setup_fee != null;

    if (!isOneTime && status === "non_renewing") {
      /**
       * One-time and cancelled reoccurring donations will both have this status. To differentiate between them
       * when they've been cancelled in less than one term, we check the cancelled date. One-time donations are
       * cancelled almost immediately after creation.
       */
      isOneTime = !!(cancel_schedule_created_at
        && isWithinTimeOf(moment.unix(cancel_schedule_created_at), moment.unix(started_at), 1, "s"));
    }

    if (!isOneTime) {
      // We add 1 to the billing cycles to account for the current term.
      const previousTermStart = moment.unix(current_term_end).subtract(remaining_billing_cycles + 1, billing_period_unit);
      isOneTime = isWithinTimeOf(previousTermStart, moment.unix(started_at), 1, "s");
    }
  }

  return isOneTime
    ? "one-time"
    : billing_period_unit === "month"
      ? "monthly"
      : "annually";
}
