export const defaultGift = {
    id: "",
    name: "No gift for me",
    first: true,
    caption:
      "I'd like 100% of my donation to support JazzGroove.org and be tax deductible.",
    is_shippable: false,
    meta_data: {},
    description:
      "I'd like 100% of my donation to support JazzGroove.org and be tax deductible.",
};