import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router";

type MatchPathParams = Parameters<typeof matchPath>;

/**
 * Check if the current path matches a given path.
 */
const useMatchPath = (
  props: MatchPathParams[1],
  parent?: MatchPathParams[2],
): ReturnType<typeof matchPath> => {
  const location = useLocation();
  const [match, setMatch] = useState<ReturnType<typeof matchPath>>(matchPath(location.pathname, props, parent));

  useEffect(() => {
    setMatch(matchPath(location.pathname, props, parent));
  }, [location, parent, props, setMatch]);

  return match;
};

export default useMatchPath;
