import {
  Color,
  darken,
  PaletteColorOptions,
  PaletteMode,
  ThemeOptions,
} from "@mui/material";
import { stationGreyMap, stationPaletteMap } from "./Colors";
import { DEFAULT_THEME_ID } from "Utils/Constants";

/**
 * Create a station's theme options based on the current mode. Most of each theme
 * will be based around the {@link stationGreyMap} for the specific station.
 */
export default function createStationThemeOptions(
  stationId: StationId,
  mode: PaletteMode
): ThemeOptions {
  const primaryPalette =
    stationPaletteMap[stationId] || stationPaletteMap[DEFAULT_THEME_ID];
  const grey =
    stationGreyMap[stationId] || (stationGreyMap[DEFAULT_THEME_ID] as Color);

  const neutralPalette: PaletteColorOptions = {
    light: grey["500"],
    main: grey["600"] as string,
    dark: grey["700"],
    darker: grey["800"],
  };

  return {
    palette: {
      stationId,
      primary: primaryPalette,
      primaryContrast: {
        main: primaryPalette.contrastText,
        dark: darken(primaryPalette.contrastText, 0.1),
        contrastText: primaryPalette.dark,
      },
      secondary: neutralPalette,
      neutral: neutralPalette,
      grey,
      darkPurple: { main: "#444EB1" },
      darkest: { main: "#121212" },
      background: {
        paper: grey["900"],
        default: grey["800"],
        light: grey["700"],
        darkest: grey["A100"],
      },
      border: {
        elevation: grey["700"] as string,
      },
    },
  };
}
