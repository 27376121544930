import { css, Stack, styled } from "@mui/material";
import playerClasses from "../classes";

const PREFIX = playerClasses.actions;
export const classes = {
  root: PREFIX,
  drawerToggleIcon: `${PREFIX}__drawer-toggle__icon`
};

export const Content = styled(Stack)(({ theme }) => css`
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-left: 16px;
  padding-right: ${theme.spacing(2)};

  ${theme.breakpoints.up("lg")} {
    padding-left: ${theme.jgComponents.Player.controls["lg"].sideButtonSize}px;
  }
`);
