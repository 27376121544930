import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

type CheckCircleIconProps = SvgIconProps & {
  checkmarkColor?: string;
};

/**
 * Wrapper for `IoCheckmarkCircle` with the ability to change the checkmark's color.
 */
const CheckCircleIcon = ({ checkmarkColor = "#ffffff", ...rest }: CheckCircleIconProps) => {
  return (
    <SvgIcon viewBox="0 0 512 512" height="1em" width="1em" {...rest}>
      <path
        fill={checkmarkColor}
        d="M256,66A190,190,0,1,0,446,256,189.83,189.83,0,0,0,256,66Z"
      />
      <path
        fill="currentColor"
        d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29l-134.4 160a16 16 0 01-12 5.71h-.27a16 16 0 01-11.89-5.3l-57.6-64a16 16 0 1123.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0124.5 20.58z"
      />
    </SvgIcon>
  );
};
(CheckCircleIcon as any).muiName = SvgIcon.muiName;

export default CheckCircleIcon;
