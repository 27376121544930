import { Components } from "@mui/material/styles";
import Accordion from "./Accordion";
import Alert from "./Alert";
import Button from "./Button";
import Card from "./Card";
import Chip from "./Chip";
import Dialog from "./Dialog";
import Divider from "./Divider";
import Form from "./Form";
import Paper from "./Paper";
import Snackbar from "./Snackbar";

const MuiComponents = Object.assign(
  {},
  Accordion,
  Alert,
  Button,
  Card,
  Chip,
  Dialog,
  Divider,
  Form,
  Paper,
  Snackbar,
) as Components;

export default MuiComponents;
