import { Components, Theme } from "@mui/material/styles";

const Chip: Components<Theme> = {
  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontWeight: theme.typography.fontWeightMedium,
      }),
    },
  },
};

export default Chip;
