import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { AppWrapper as App } from "./App";
// import reportWebVitals from "./reportWebVitals";
import "regenerator-runtime/runtime";
import { Auth0Provider } from "./react-auth0-spa";
import * as Sentry from "@sentry/react";
import History from "Utils/Routing/History";
import { checkBlock } from "AudioPlayer";

import { StationProvider } from "StationsContext/Context";
require("./Utils/Init");

const onRedirectCallback = (appState?: any) => {
  History.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
  try {
    checkBlock();
  } catch (e) {}
};

function FallbackComponent() {
  return <div>An error has occurred.</div>;
}

let ErrorBoundary;
if (process.env.REACT_APP_NODE_ENV !== "dev") {
  ErrorBoundary = Sentry.ErrorBoundary;
} else {
  ErrorBoundary = (props) => props.children;
}

/**
 * Important: Do not modify the auth0 cacheLocation to memory unless you
 * modify the rest of the integration to be asynchronous.
 * We have changed it to localstorage to fix a critical bug with the integration
 */
ReactDOM.render(
  <ErrorBoundary fallback={FallbackComponent} showDialog>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <StationProvider>
        <App />
      </StationProvider>
    </Auth0Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
