import { PLAYER_DEFAULT_STATION_ID } from "./Player";
import { PaletteMode } from "@mui/material";
import { CamelCase } from "type-fest";
import { PlanFrequency } from "../../../types/Plan";
import { Auth0NamespacePrefix } from "../../../types/Auth";

export * from "./Contentful";
export * from "./Donate";
export * from "./Player";
export * from "./Station";
export * from "./PlanFeature";
export * from "./PlanTier";

export const PLAN_FREQUENCIES: ReadonlyArray<CamelCase<PlanFrequency>> =
  Object.freeze(["monthly", "annually", "oneTime"]);

export const PLAN_FREQUENCY_FORMATTED_MAP: Record<PlanFrequency, string> = {
  monthly: "/month",
  annually: "/year",
  "one-time": "One-time",
} as const;

export const DEFAULT_THEME_ID = PLAYER_DEFAULT_STATION_ID;

// TODO: Once we switch themes to dark mode, set this to "dark"
export const DEFAULT_HEADER_MODE: PaletteMode = "light";

export const UNLIMITED_LISTENING_PLAN = "unlimited-listening";
export const PREMIUM_PLAN = "premium";
export const PREMIUM_PLUS_PLAN = "premium-plus";

export const AUTH0_USER_METADATA_KEY: `${Auth0NamespacePrefix}/user_metadata` =
  "https://jazzgroove.org/user_metadata";

export const LOWEST_QUALITY: Bitrate = "audio-mp4-aac-128k";
export const LOW_QUALITY: Bitrate = "audio-mp4-aac-128k";
export const MID_QUALITY: Bitrate = "audio-mp4-aac-256k";
export const HIGH_QUALITY: Bitrate = "audio-mp4-flac";

export const USER_ENDPOINT = "/user";
export const DONATION_HISTORY_ENDPOINT = "/donate/history";
export const CHECKOUT_ENDPOINT = "/checkout";
export const GENERATE_TOKEN = "/stream/generate-token";

/** @see https://momentjs.com/docs/#/displaying/format/ */
export const DEFAULT_DATE_FORMAT = "MMM D, YYYY";

// Minimum number of days before a user's plan expires before we begin reminding them.
export const NOTIFY_PLAN_EXPIRATION_DAYS_THRESHOLD = 14;
export const NOTIFY_PLAN_EXPIRATION_DAYS_CRITICAL_THRESHOLD = 3;
export const RENEW_PLAN_EXPIRATION_DAYS_THRESHOLD = 30;
