import { darken, alertClasses } from "@mui/material";
import { Components, Theme } from "@mui/material/styles";
import { ButtonClassKey } from "@mui/material/Button";
import { mix } from "Utils/Color";

function getLinearGradient(color: string): string {
  return `linear-gradient(to bottom, ${darken(color, 0.18)}, ${color})`;
}

type ButtonStyleOverrides = NonNullable<Components<Theme>[ButtonClassKey]["styleOverrides"]>;
const commonButtonStartEndIconStyles: ButtonStyleOverrides["root"] = ({ ownerState }) => ({
  ...(ownerState.size === "small" && {
    "& > *:nth-of-type(1)": {
      fontSize: 16,
    },
  }),
});

const Button: Components<Theme> = {
  MuiLoadingButton: {
    defaultProps: {
      variant: "contained",
      disableElevation: true,
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        return {
          ...(ownerState.color === "neutral" && {
            color: ownerState.disabled
              ? theme.palette.text.disabled
              : theme.palette.text.secondary,
          }),
        };
      },
    }
  },
  MuiButton: {
    defaultProps: {
      variant: "contained",
      disableElevation: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        position: "relative",
        zIndex: 1,
        borderRadius: 60,
        fontWeight: theme.typography.fontWeightMedium,
        borderWidth: theme.shape.borderWidth,
      }),
      startIcon: commonButtonStartEndIconStyles,
      endIcon: commonButtonStartEndIconStyles,
      sizeSmall: ({ ownerState, theme }) => ({
        ...theme.typography.buttonSmall,

        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),

        ...(ownerState.variant !== "text" && {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),

          [`.${alertClasses.action} &`]: {
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
          },
        }),
      }),
      sizeMedium: ({ ownerState, theme }) => ({
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),

        ...(ownerState.variant !== "text" && {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        }),
      }),
      sizeLarge: ({ ownerState, theme }) => ({
        ...theme.typography.buttonLarge,

        paddingTop: theme.spacing(1.75),
        paddingBottom: theme.spacing(1.75),

        ...(ownerState.variant !== "text" && {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        }),
      }),
      contained: ({ ownerState, theme }) => {
        const primary = theme.palette.primary;
        const mixinColor = theme.palette.neutral.light;
        const mixWeight = 0.2;

        return {
          ...(ownerState.color === "primary" && {
            textShadow: theme.textShadows[1],
            backgroundImage: getLinearGradient(primary.main),

            // Gradients can't transition natively, so we use this workaround to simulate it.
            "&::before": {
              content: `""`,
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              opacity: 0,
              zIndex: -1,
              borderRadius: "inherit",
              backgroundImage: getLinearGradient(primary.dark),
              transition: theme.transitions.create("opacity"),
            },

            "&:hover::before": {
              opacity: 1,
            },

            "& svg": {
              filter: `drop-shadow(${theme.textShadows[1]})`,
            },

            ...(ownerState.disabled && {
              backgroundImage: getLinearGradient(mix(primary.main, mixinColor, mixWeight)),

              "&:hover": {
                backgroundImage: getLinearGradient(mix(primary.dark, mixinColor, mixWeight)),
              },
            }),
          }),
        };
      },
      outlined: ({ ownerState, theme }) => {
        const darkMode = theme.palette.mode === "dark";
        const disabled = ownerState.disabled;

        return {
          "&:hover": {
            boxShadow: `0 0 0 ${theme.shape.borderWidth}px ${theme.palette.primary.main}`,
          },

          ...(ownerState.color === "neutral" && {
            color: disabled
              ? theme.palette.text.disabled
              : darkMode
                ? theme.palette.text.primary
                : theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            "&:hover": {
              backgroundColor: theme.palette.action.hover,
              borderColor: theme.palette.text.disabled,
            },
          }),
        };
      },
      text: ({ ownerState, theme }) => {
        return {
          ...(ownerState.color === "neutral" && {
            color: ownerState.disabled
              ? theme.palette.text.disabled
              : theme.palette.text.secondary,
          }),
        };
      },
    },
  },
};

export default Button;
