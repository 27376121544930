import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useAuth0 } from "react-auth0-spa";
import { IoClose } from "react-icons/io5";
import History from "./Routing/History";

export default function PremiumAlert({popup, open, onClose}) {
  const { isAuthenticated: loggedIn, loginWithRedirect } = useAuth0();

  function getPremium() {
    if (popup) {
      try {
        window.opener.parent.location.href = "/premium";
      } catch (e) {
        window.open("/premium");
      }
    } else {
      History.push("/premium");
    }
    onClose(true)
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="get-premium-title"
      open={open}
    >
      <DialogTitle id="get-premium-title">
        Get Premium Plus to enjoy:
        <IconButton
          aria-label="close"
          color="default"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8
          }}
        >
          <IoClose />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography component="div" textAlign="left">
          <ul>
            <li>
              No Interruptions from Fund Drives or Promotional Announcements
            </li>
            <li>Song Skips</li>
            <li>Audiophile Fidelity</li>
            <li>Unlimited Access</li>
          </ul>
        </Typography>
      </DialogContent>
      <DialogActions sx={{p: 2}}>
        {!loggedIn && <Button color="secondary" onClick={() => loginWithRedirect()}>
          Login
        </Button>}
        <Button autoFocus onClick={getPremium}>
          Get Premium Now
        </Button>
      </DialogActions>
    </Dialog>
  )
}
