import React, { Suspense, useCallback, useEffect } from "react";
import { useLocation } from "react-router";
import { usePrevious } from "react-use";
import ReactGA from "react-ga";
import Player from "Pages/Components/Player/Player";
import Spinner from "Pages/Components/Spinner";
import Checkout from "Pages/Checkout";
import FreeTrial from "Pages/FreeTrial";
import PayPalCheckout from "Pages/PayPalCheckout";
import OneTimeCheckout from "Pages/OneTimeCheckout";
import ClaimGift from "Pages/ClaimGift";
import ThankYou from "Pages/ThankYou";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "Utils/Routing/PrivateRoute";
import LoginRedirect from "Utils/Routing/LoginRedirect";
import SonosLander from "../../Pages/Sonos/SignUp";
import SonosCheckout from "../../Pages/Sonos/Checkout";
import SonosComplete from "../../Pages/Sonos/Complete";
import { useAuth0 } from "react-auth0-spa";
import History from "Utils/Routing/History";
import retry from "Utils/ImportRetry";
import DefaultLayout from "../../Containers/Layout/DefaultLayout";
import { STATION_QUERY_CODES } from "../Constants";
import ThankYouOneTime from "Pages/ThankYouOneTime";

const Home = React.lazy(() => retry(() => import("Pages/Home")));
const Blog = React.lazy(() => retry(() => import("Pages/Blog")));
const About = React.lazy(() => retry(() => import("Pages/About")));
const Contact = React.lazy(() => retry(() => import("Pages/Contact")));
const Premium = React.lazy(() => retry(() => import("Pages/GetPremium")));
const JazzTonight = React.lazy(() => retry(() => import("Pages/JazzTonight")));
const JazzTonightGallery = React.lazy(() =>
  retry(() => import("Pages/JazzTonightGallery"))
);
const Account = React.lazy(() => retry(() => import("Pages/Account")));
const LoginSuccessful = React.lazy(() =>
  retry(() => import("Pages/LoginSuccessful"))
);
const Error404 = React.lazy(() => retry(() => import("Pages/Error")));
const PrivacyPolicy = React.lazy(() =>
  retry(() => import("Pages/PrivacyPolicy"))
);
const Post = React.lazy(() => retry(() => import("Pages/Post")));
const Surround = React.lazy(() => retry(() => import("Pages/Surround")));
const Upgrade = React.lazy(() => retry(() => import("Pages/Upgrade")));
const HelpCenter = React.lazy(() =>
  retry(() => import("Pages/HelpCenter/HelpCenter"))
);
const DevicesAndApps = React.lazy(() =>
  retry(() => import("Pages/HelpCenter/DevicesAndApps"))
);

const SignUpRedirect = () => {
  const { loading, loginWithRedirect, isAuthenticated } = useAuth0();
  useEffect(() => {
    if (isAuthenticated) History.push("/");
    else if (!loading) loginWithRedirect({ screen_hint: "signup" });
  }, [loading, loginWithRedirect, isAuthenticated]);
  return null;
};

const Routes = () => {
  const location = useLocation();
  const prevLocation = usePrevious(location);

  const onLocationChange = useCallback((location) => {
    if (location.pathname !== prevLocation?.pathname) {
      if (!location.hash) {
        window.scrollTo(0, 0);
      }
      ReactGA.pageview(location.pathname + location.search);
    }
    /** {@link prevLocation} is from a ref and can be ignored as a dependency. */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onLocationChange(location);
  }, [location, onLocationChange]);

  return (
    <Switch>
      <Route exact path={"/stream"} render={() => <Player forceMobileView />} />
      <Route
        exact
        path="/donate"
        component={({ location }) => (
          <Redirect
            to={{
              ...location,
              pathname: location.pathname.replace(/donate/, "checkout"),
            }}
          />
        )}
      />
      <Route exact path="/checkout" component={Checkout} />
      <Route exact path="/checkout/paypal" component={PayPalCheckout} />
      <Route exact path="/checkout/one-time" component={OneTimeCheckout} />
      <Route exact path={"/link/sonos"} component={SonosLander} />
      <Route exact path={"/link/sonos/complete"} component={SonosComplete} />
      <Route exact path={"/link/sonos/checkout"} component={SonosCheckout} />
      <Route exact path="/freetrial" component={FreeTrial} />
      <Route exact path="/free" component={FreeTrial} />
      <DefaultLayout>
        <Pages />
      </DefaultLayout>
    </Switch>
  );
};

const Pages = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {process.env.REACT_APP_NODE_ENV === "dev" && (
          <Route
            path={"/dev/theme/cheatsheet"}
            component={React.lazy(() =>
              retry(() => import("Pages/ThemeCheatsheet"))
            )}
          />
        )}

        <Route exact path={"/(devices|device)"} component={DevicesAndApps} />
        <Route exact path={"/help"} component={HelpCenter} />
        <Route exact path={"/(faq|faqs|facts|support)"}>
          <Redirect to="/help" />
        </Route>
        <Route
          exact
          path={"/cap"}
          render={() => {
            return (
              <iframe
                className="faqs-doc-embed"
                title="Cap"
                src="https://docs.google.com/document/d/e/2PACX-1vQv1AQV1E76LdwlLK1dVpQaELuwImjJDpkYS-fJQ2KTwhnpytWb8diSoTPzi7OfZg7BM63saBgFu-b-/pub?embedded=true"
              ></iframe>
            );
          }}
        />
        <Route
          exact
          path={"/mcafee"}
          render={() => {
            return (
              <iframe
                className="faqs-doc-embed"
                title="McAfee"
                src="https://docs.google.com/document/d/e/2PACX-1vT3bbjTVWa6mpabFQMrMOdW03bO-GkEnoFC2M6v67wsmzocSmusCvUbuxMh1xK2sGCeWAIuPL2oAMG7/pub?embedded=true"
              ></iframe>
            );
          }}
        />
        <Route
          exact
          path={
            "/(echo|alexa|bluesound|cambridge|iphone|marantz|squeezebox|roon|rune|sonos|roku)"
          }
        >
          <Redirect to={"/devices"} />
        </Route>
        <Route exact path={"/"} component={Home} />
        <Route
          exact
          path={`/:c(${STATION_QUERY_CODES.join("|")})`}
          component={Home}
        />
        <Route exact path={"/about"} component={About} />
        <Route exact path={"/login"} component={LoginRedirect} />
        <Route exact path={"/(signup|sign-up)"} component={SignUpRedirect} />
        <Route exact path={"/contact"} component={Contact} />
        <Route exact path={"/blog/:id"} component={Post} />
        <Route exact path={"/blog"} component={Blog} />
        <Route exact path={"/listen"}>
          <Redirect to={"/devices"} />
        </Route>
        <Route exact path={"/jazz-tonight"} component={JazzTonight} />
        <Route
          exact
          path={"/jazz-tonight/gallery"}
          component={JazzTonightGallery}
        />
        <Route exact path={"/premium"} component={Premium} />
        <PrivateRoute path={"/account"} component={Account} />
        <Route exact path={"/privacy-policy"} component={PrivacyPolicy} />
        <Route exact path="/surround" component={Surround} />

        <Route exact path={"/success"} component={LoginSuccessful} />
        <Route exact path={"/thank-you"} component={ThankYou} />
        <Route exact path={"/thank-you-one-time"} component={ThankYouOneTime} />
        <Route exact path={"/(car|cars)"} component={Home} />
        <Route exact path={"/gift/claim/:id"} component={ClaimGift} />
        <Route exact path={"/upgrade/:id"} component={Upgrade} />
        <Redirect from="/tips" to="/blog/new-website-tips" />
        <Redirect from="/silence" to="/devices" />
        <Redirect
          from="/checkout/payment"
          to={`/checkout${window.location.search}`}
        />
        <Redirect
          from={"/upgrade"}
          to={"/checkout?a=1000&m=1&s=1&c=plus-spring20"}
        />
        <Route exact path={"/404"} component={Error404} />
        <Route component={Error404} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
