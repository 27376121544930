import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 120 * 1000, // 2 minutes
  headers: {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY as string,
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "comma", skipNulls: true });
  },
});

const http = {
  async request<T, TBody = any>(config: AxiosRequestConfig<TBody>): Promise<T> {
    const axiosResponse = await client.request<T>(config);
    return axiosResponse.data;
  },

  async get<T, TBody = any>(url: string, config?: AxiosRequestConfig<TBody>): Promise<T> {
    return this.request<T, TBody>({ ...config, method: "GET", url });
  },

  async delete<T, TBody = any>(url: string, config?: AxiosRequestConfig<TBody>): Promise<T> {
    return this.request<T, TBody>({ ...config, method: "DELETE", url });
  },

  async head<T, TBody = any>(url: string, config?: AxiosRequestConfig<TBody>): Promise<T> {
    return this.request<T, TBody>({ ...config, method: "HEAD", url });
  },

  async options<T, TBody = any>(url: string, config?: AxiosRequestConfig<TBody>): Promise<T> {
    return this.request<T, TBody>({ ...config, method: "OPTIONS", url });
  },

  async post<T, TBody = any>(url: string, body?: TBody, config?: AxiosRequestConfig<TBody>): Promise<T> {
    return this.request<T, TBody>({
      ...config,
      method: "POST",
      data: body,
      url,
    });
  },

  async put<T, TBody = any>(url: string, body?: TBody, config?: AxiosRequestConfig<TBody>): Promise<T> {
    return this.request<T, TBody>({
      ...config,
      method: "PUT",
      data: body,
      url,
    });
  },

  async patch<T, TBody = any>(url: string, body?: TBody, config?: AxiosRequestConfig<TBody>): Promise<T> {
    return this.request<T, TBody>({
      ...config,
      method: "PATCH",
      data: body,
      url,
    });
  },
};

export default http;
