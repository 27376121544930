import { matchPath } from "react-router";
import { STATION_QUERY_CODES } from "./Constants";

export function isHome(path: string): boolean {
  const homeMatch = matchPath(path, { path: "/", exact: true });
  const homeWithChannelMatch = matchPath(path, {
    path: `/:channel(${[...STATION_QUERY_CODES, "sign-up"].join("|")})`,
    exact: true,
  });

  return homeMatch != null || homeWithChannelMatch != null;
}
