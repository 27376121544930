import FootnoteButton from "Pages/Components/FootnoteButton";
import { footnoteNumberOf } from "Utils/Components/Plan";
import { PlanFeature } from "Utils/Constants";


export default function PlanFeatureFootnote(props: {feature: PlanFeature}) {
    const { feature } = props

    const footnoteNumber = footnoteNumberOf(feature);
    
    return (
        <>
            {feature.footnote && (
                <FootnoteButton number={footnoteNumber!}>{feature.footnote}</FootnoteButton>
            )}
        </>
    )
}