/**
 * Get the album art size based on the width and height of its container.
 * @param width
 * @param height
 * @param padding - Padding to account for on the container, like {@link Container} gutters.
 */
export default function calcAlbumArtSize(width: number, height: number, padding: number = 0): number {
  const dimensionRatio = width / height;
  const size = (dimensionRatio > 0.5) ? (height * 0.4) : width;
  return size - (padding * 2);
}
