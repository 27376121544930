export const STATIONS: ReadonlyArray<Station> = Object.freeze([
  {
    id: "mix1",
    channelId: "jazzgroove-mix1",
    queryCode: "mix1",
    name: "The Jazz Groove Mix #1",
    description: "Laid-Back Jazz",
    playlistId: "bab24f05-73f8-4088-9373-bf118f07bdec",
    defaultAlbumArtUrl: "https://assets.jazzgroove.org/sonos/Mix1.jpg",
  },
  {
    id: "mix2",
    channelId: "jazzgroove-mix2",
    queryCode: "mix2",
    name: "The Jazz Groove Mix #2",
    description: "Laid-Back Jazz",
    playlistId: "bf099ca1-68e5-4611-aed2-f3914eed074c",
    defaultAlbumArtUrl: "https://assets.jazzgroove.org/sonos/Mix2.jpg",
  },
  {
    id: "dreams",
    channelId: "jazzgroove-dreams",
    queryCode: "dreams",
    name: "Dreams",
    description: "The Softest Laid-Back Jazz for Sleep",
    playlistId: "7db4781b-63eb-479c-b018-a7498423c680",
    defaultAlbumArtUrl: "https://assets.jazzgroove.org/sonos/Dreams.jpg",
  },
  // Uncomment after christmas
  {
    id: "gems",
    channelId: "jazzgroove-gems",
    queryCode: "gems",
    name: "Gems",
    description: "Vocal Standards",
    playlistId: "5b6248df-d419-4de2-b32a-efd3e1d3bfb3",
    defaultAlbumArtUrl: "https://assets.jazzgroove.org/sonos/Gems.jpg",
  },
  // Christmas version of gems
  // {
  //   id: "gems",
  //   channelId: "jazzgroove-gems",
  //   queryCode: "gems",
  //   name: "Christmas Jazz",
  //   description: "The Jazz Groove Christmas Station",
  //   playlistId: "5b6248df-d419-4de2-b32a-efd3e1d3bfb3",
  //   defaultAlbumArtUrl: "https://assets.jazzgroove.org/sonos/GoldenTree.jpg"
  // },
  {
    id: "smooth",
    channelId: "jazzgroove-smooth",
    queryCode: "smoothjazz.blue",
    name: "Smooth Jazz",
    description: "The Smoothest Place on Earth",
    playlistId: "eb33de06-5c1d-4b60-b2d3-a6f7b472f2cd",
    defaultAlbumArtUrl: "https://assets.jazzgroove.org/sonos/Smooth.jpg",
  },
  // Christmas copy.  swap out with Gems during holidays.
  // {
  //   id: "gems",
  //   channelId: "jazzgroove-gems",
  //   queryCode: "gems",
  //   name: "Christmas Jazz",
  //   description: "The Jazz Groove Christmas Station",
  //   playlistId: "5b6248df-d419-4de2-b32a-efd3e1d3bfb3",
  //   defaultAlbumArtUrl: "https://assets.jazzgroove.org/sonos/Christmas.jpg"
  // }
]);

export const STATION_IDS: ReadonlyArray<StationId> = STATIONS.map(station => station.id);

export const STATION_OPTIONS: ReadonlyArray<JGOption<StationId>> =
  STATIONS.map(station => ({ label: station.name, value: station.id }));

export const STATION_QUERY_CODES: ReadonlyArray<string> =
  // I'm not sure if "christmas" is used anymore, but I'll keep it to be safe.
  STATIONS.map(station => station.queryCode).concat(["christmas"]);
