import { Button, Dialog, DialogActions, DialogTitle, IconButton } from "@mui/material";
import { IoClose } from "react-icons/io5";

export default function SkipAlert({open, onClose}) {

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="skip-limit-title"
      open={open}
    >
      <DialogTitle id="skip-limit-title">
        Skip up to six tracks an hour, on each channel, as per music licensing guidelines.
        <IconButton
          aria-label="close"
          color="default"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8
          }}
        >
          <IoClose />
        </IconButton>
      </DialogTitle>
      <DialogActions sx={{p: 2}}>
        <Button color="primary" sx={{maxWidth: "fit-content"}} onClick={() => onClose()}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
