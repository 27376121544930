export const PREFIX = "Toastify";
export const CONTAINER_PREFIX = `${PREFIX}__toast-container`;
export const TOAST_PREFIX = `${PREFIX}__toast`;
export const TOAST_VARIANT_PREFIX = `${TOAST_PREFIX}__toast`
export const THEME_PREFIX = `${PREFIX}__toast-theme`;

const classes = {
  root: PREFIX,

  container: CONTAINER_PREFIX,
  containerTopLeft: `${CONTAINER_PREFIX}--top-left`,
  containerTopCenter: `${CONTAINER_PREFIX}--top-center`,
  containerTopRight: `${CONTAINER_PREFIX}--top-right`,
  containerBottomLeft: `${CONTAINER_PREFIX}--bottom-left`,
  containerBottomCenter: `${CONTAINER_PREFIX}--bottom-center`,
  containerBottomRight: `${CONTAINER_PREFIX}--bottom-right`,

  toast: TOAST_PREFIX,
  toastVariantDefault: `${TOAST_VARIANT_PREFIX}--default`,
  toastVariantInfo: `${TOAST_VARIANT_PREFIX}--info`,
  toastVariantSuccess: `${TOAST_VARIANT_PREFIX}--success`,
  toastVariantWarning: `${TOAST_VARIANT_PREFIX}--warning`,
  toastVariantError: `${TOAST_VARIANT_PREFIX}--error`,

  toastThemeLight: `${THEME_PREFIX}--light`,
  toastThemeDark: `${THEME_PREFIX}--dark`,
  toastThemeColored: `${THEME_PREFIX}--colored`,

  toastBody: `${PREFIX}__toast-body`,
  toastIcon: `${PREFIX}__toast-icon`,
  toastCloseButton: `${PREFIX}__toast-close-button`,

  progressBar: `${PREFIX}__progress-bar`,
  spinner: `${PREFIX}__spinner`,
};

export default classes;
