
import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { useAuth0 } from "../../react-auth0-spa";

const Loading = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "inherit",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: {targetUrl: window.location.pathname}
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = props =>
    isAuthenticated === true ? <Component {...props} /> : <Loading />;

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
