import React, { useEffect, useState } from "react";
import {
  ButtonProps,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IoChevronDown, IoPerson } from "react-icons/io5";
import clsx from "clsx";
import ReactGA from "react-ga";
import { getTokenSilently, useAuth0 } from "react-auth0-spa";
import JGIcon from "Pages/Components/JGIcon";
import * as S from "./AuthActions.styles";
import ACCOUNT_ROUTES from "Pages/Account/AccountRoutes";
import HeaderNavMenuItem from "Pages/Components/Header/HeaderNavMenuItem";
import SignOutDialog from "Pages/Components/Account/SignOutDialog";
import { User } from "../../../../types/Auth";
import { PlanExpirationDialogProps } from "Pages/Components/PlanExpirationDialog";
import SignInDialog from "../Account/SignInDialog";

type AuthActionsProps = ButtonProps & {
  expirationDialog?: PlanExpirationDialogProps;
};

function getInitials(user: User): string | null {
  if (!user || !user.name) return null;
  const firstInitial = user.given_name?.[0]?.toUpperCase() || "";
  const lastInitial = user.family_name?.[0]?.toUpperCase() || "";
  return firstInitial + lastInitial || null;
}

const AuthActions = (props: AuthActionsProps) => {
  const { className, expirationDialog, ...rest } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { isAuthenticated, loading, loginAndReturn, user } = useAuth0();
  const getIfUserNeedLogin = async () => {
    try {
      await getTokenSilently();
    } catch (error) {
      if (localStorage.getItem("hasAuthenticated") === "true") {
        setNeedLogin(true);
      }
    }
  };

  useEffect(() => {
    getIfUserNeedLogin();
  }, [isAuthenticated, loading, user]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [signOutDialogOpen, setSignOutDialogOpen] = useState(false);
  const [signInDialogOpen, setSignInDialogOpen] = useState(true);
  const [needLogin, setNeedLogin] = useState(false);

  const closeMenu = () => setAnchorEl(null);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const openExpirationDialog = () => {
    if (!expirationDialog) return;

    ReactGA.event({
      category: "button",
      action: "expiring",
      days: expirationDialog.subscription.termEnd.daysUntil,
    } as any);

    expirationDialog.setOpen(true);
  };

  const isMenuOpen = Boolean(anchorEl);

  if (needLogin && signInDialogOpen) {
    return (
      <>
        <SignInDialog
          open={signInDialogOpen}
          onClose={() => setSignInDialogOpen(false)}
          className={clsx(S.classes.root, className)}
          loading={loading}
          onClick={loginAndReturn}
        />
        {(!isAuthenticated || loading) && (
          <S.SignInButton
            variant="outlined"
            className={clsx(S.classes.root, className)}
            loading={loading}
            onClick={loginAndReturn}
            {...rest}
          >
            Sign In
          </S.SignInButton>
        )}
      </>
    );
  }

  if (!isAuthenticated || loading) {
    return (
      <S.SignInButton
        variant="outlined"
        className={clsx(S.classes.root, className)}
        loading={loading}
        onClick={loginAndReturn}
        {...rest}
      >
        Sign In
      </S.SignInButton>
    );
  }

  const initials = getInitials(user);
  return (
    <>
      <S.AccountButton
        color="neutral"
        className={clsx(S.classes.root, className)}
        onClick={openMenu}
        {...rest}
      >
        {!isMobile && <S.StartIcon is={IoChevronDown} />}

        <S.AvatarBadge
          badgeContent={
            expirationDialog?.subscription &&
            !expirationDialog.subscription.renewButtonOnly
              ? 1
              : undefined
          }
          color={"error"}
        >
          <S.UserAvatar>
            {initials ? (
              <Typography sx={{ fontWeight: "medium" }}>{initials}</Typography>
            ) : (
              <JGIcon is={IoPerson} />
            )}
          </S.UserAvatar>
        </S.AvatarBadge>
      </S.AccountButton>

      <Menu
        anchorEl={anchorEl}
        disableScrollLock
        open={isMenuOpen}
        onClose={closeMenu}
        onClick={closeMenu}
      >
        {expirationDialog?.subscription &&
          !expirationDialog.subscription.renewButtonOnly && (
            <S.PlanExpirationMenuItem onClick={openExpirationDialog} dense>
              Plan expiring&nbsp;
              <strong>
                {expirationDialog.subscription.termEnd.relativeFormattedDate}
              </strong>
            </S.PlanExpirationMenuItem>
          )}
        {ACCOUNT_ROUTES.map((route, index) => (
          <HeaderNavMenuItem
            key={route.label}
            link={{ to: `/account${route.to}`, exact: index === 0 }}
            label={index === 0 ? "Account" : route.label}
          />
        ))}

        <Divider />

        <MenuItem onClick={() => setSignOutDialogOpen(true)}>
          <ListItemText>Sign Out</ListItemText>
        </MenuItem>
      </Menu>

      <SignOutDialog
        open={signOutDialogOpen}
        onClose={() => setSignOutDialogOpen(false)}
      />
    </>
  );
};

export default AuthActions;
