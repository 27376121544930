import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { IoClose } from "react-icons/io5";

export default function AlbumModal({open, onClose, src, alt}) {

  return (
    <Dialog
      onClose={onClose}
      open={open}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          color="default"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8
          }}
        >
          <IoClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
          <img src={src} alt={alt} />
      </DialogContent>
    </Dialog>
  )
}
