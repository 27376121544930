import { useTheme } from "@mui/material";
import React from "react";

const Spinner = ({style, primary, small}: {style?: any, primary?: boolean, small?: boolean}) => {
  const theme = useTheme()
  const styles = style || {}
  if (primary) {
    styles.backgroundColor = theme.palette.primary.main
  }
  if (small) {
    styles.width = "9px"
    styles.height = "9px"
  }
  return (
    <div className="spinner">
      <div className="bounce1" style={{...styles}}></div>
      <div className="bounce2" style={{...styles}}></div>
      <div className="bounce3" style={{...styles}}></div>
    </div>
  );
};

export default Spinner;
