import { useEffect, useState } from "react";
import contentfulClient from "../Utils/ContentfulClient";

const useGetStationApi = () => {
  const [stations, setStation] = useState<any>();
  const isDev = process.env.REACT_APP_NODE_ENV === "dev";

  const parseStations = (stations: any) => {
    // TODO: REMOVE MAPPING ID AND CHANNELID BEFORE DEPLOYMENT
    let parsedStations = stations?.items.map((item: any) => {
      return {
        ...item.fields,
        id: item.fields.channelId,
        channelId: item.fields.id,
      };
    });

    if (isDev) {
      const filteredArray = parsedStations.filter(
        (el: any) => el.id !== "dev1" && el.id !== "dev2"
      );
      parsedStations = filteredArray;
    }

    parsedStations.sort((a: any, b: any) => a.order - b.order);

    return parsedStations;
  };

  const getStations = async () => {
    try {
      const entries = await contentfulClient.getEntries({
        content_type: "channelBackgroundList",
        include: 2,
      });

      setStation(parseStations(entries));
    } catch (err) {
      console.error("Unable to refresh image gallery from network", err);
    }
  };

  useEffect(() => {
    getStations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { stations };
};

export default useGetStationApi;
