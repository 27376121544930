import { alpha, css, GlobalStyles } from "@mui/material";
import CSSVariablesStyles from "./CSSVariablesStyles";
import ChatlioStyles from "./ChatlioStyles";
import ToastifyStyles from "./ToastifyStyles";

const JGGlobalStyles = <>
  {CSSVariablesStyles}
  {ChatlioStyles}
  {ToastifyStyles}

  <GlobalStyles styles={theme => css`
    a {
      color: ${theme.palette.primary.main};
      text-decoration: underline;
      // Color the same as the MUI Link component's underline color.
      text-decoration-color: ${alpha(theme.palette.primary.main, 0.4)};

      &:hover {
        text-decoration-color: inherit;
      }
    }

    p {
      margin-top: 0;
    }

    img {
      max-width: 100%;
    }

    svg {
      overflow: hidden;
      vertical-align: middle;
    }

    .swal2-container {
      z-index: ${theme.zIndex.modal};
    }

    body.swal2-shown .mui-fixed {
      padding-right: 15px;
    }

    @keyframes ticker {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  `} />
</>;

export default JGGlobalStyles;
