import { Components, Theme } from "@mui/material/styles";
import { accordionClasses } from "@mui/material";

const Accordion: Components<Theme> = {
  MuiAccordion: {
    styleOverrides: {
      root: (({ theme }) => ({
        borderRadius: theme.shape.borderRadius,

        [`&.${accordionClasses.root} + .${accordionClasses.root}`]: {
          marginTop: theme.spacing(2),
        },

        "&:before": {
          display: "none",
        },
      })) as any,
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(1, 4, 4),
      }),
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(1, 4),
      }),
    },
  },
};

export default Accordion;
