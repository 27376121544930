import React, { useState } from "react";
import { MenuItem, TextField, Typography } from "@mui/material";
import ConfirmationDialog, { ConfirmationDialogProps } from "Pages/Components/ConfirmationDialog";
import { DonationGift } from "../../../../../types/Donate";

type GiftVariantsDialogProps = Omit<ConfirmationDialogProps, "onClose"> & {
  gift: DonationGift;
  onClose: (variantIndex: number | null) => void;
};

const GiftVariantsDialog = (props: GiftVariantsDialogProps) => {
  const { gift, open, onClose } = props;
  const [variantIndex, setVariantIndex] = useState<number>(0);

  const handleClose = (confirm: boolean) => {
    onClose(confirm ? variantIndex : null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVariantIndex(Number(event.target.value));
  };

  return (
    <ConfirmationDialog
      open={open}
      onClose={handleClose}
      title={<Typography variant="h4" component="span">{gift.name}</Typography>}
      cancelText="Cancel"
      confirmText="Select"
      ConfirmButtonProps={{ color: "primary" }}
    >
      <TextField
        select
        label="Select your size"
        value={variantIndex}
        onChange={handleChange}
        sx={{
          minWidth: "60%",
          maxWidth: 300,
          my: 2,
        }}
      >
        {gift.meta_data?.variants?.map((variant, index) => (
          <MenuItem key={index} value={index}>{variant.name}</MenuItem>
        ))}
      </TextField>
    </ConfirmationDialog>
  );
};

export default GiftVariantsDialog;
