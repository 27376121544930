import React from "react";
import { ThemeOptions, TypographyStyle } from "@mui/material/styles";
import muiDefaultTheme from "Utils/Themes/MuiDefaultTheme";

export const fontFamily = `"URW DIN", Helvetica, sans-serif`;
export const fontFamilySecondary = `futura-pt-bold, "Trebuchet MS", sans-serif`;

const { fontWeightRegular, fontWeightMedium, fontWeightBold, pxToRem } =
  muiDefaultTheme.typography;

function createVariant(
  fontSize: number,
  lineHeight: React.CSSProperties["lineHeight"],
  additionalProps?: React.CSSProperties
): TypographyStyle & React.CSSProperties {
  return {
    fontSize: pxToRem(fontSize),
    lineHeight,
    ...{
      fontWeight: fontWeightRegular,
      letterSpacing: 0,
      ...additionalProps,
    },
  };
}

function createHeadingVariant(
  ...args: Parameters<typeof createVariant>
): ReturnType<typeof createVariant> {
  args[2] = {
    fontFamily: fontFamilySecondary,
    fontWeight: fontWeightBold,
    ...args[2],
  };

  return createVariant(...args);
}

export const responsiveFontVariants = [
  // Default
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "subtitle1",
  "subtitle2",
  "body1",
  "body2",
  "caption",
  "button",
  "overline",

  // Custom
  "display1",
  "display2",
];

const additionalButtonProps: React.CSSProperties = {
  fontWeight: fontWeightMedium,
  textTransform: "none",
};

export const typography: ThemeOptions["typography"] = {
  fontFamily,
  display1: createHeadingVariant(68, 0.882),
  display2: createHeadingVariant(58, 0.897),
  h1: createHeadingVariant(48, 1.167),
  h2: createHeadingVariant(40, 1.1),
  h3: createHeadingVariant(32, 1.125),
  h4: createHeadingVariant(28, 1.143),
  h5: createHeadingVariant(24, 1.167),
  h6: createHeadingVariant(20, 1.2),
  subtitle1: createVariant(20, 1.4),
  subtitle2: createVariant(18, 1.337),
  caption: createVariant(13, 1.231),
  button: createVariant(16, 1.25, additionalButtonProps),
  buttonSmall: createVariant(14, 1.143, additionalButtonProps),
  buttonLarge: createVariant(18, 1.111, additionalButtonProps),
};
