import { IconButton, Tooltip, useMediaQuery, useTheme, Dialog, DialogTitle } from "@mui/material";
import { IoEllipsisHorizontalOutline } from "react-icons/io5";
import { setMaxQuality } from "AudioPlayer";
import { useAppContext } from "Context";
import GearMenu from "./GearMenu";
import JGIcon from "../JGIcon";
import { useState } from "react";

interface PlayerSettingsButtonProps {
  qualityPreference: AudioQuality;
  setQualityPreference: (qualityPreference: AudioQuality) => void;
  pause: () => void;
}

const PlayerSettingsButton = (props: PlayerSettingsButtonProps) => {
  const {
    qualityPreference,
    setQualityPreference,
    pause,
  } = props;

  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up("lg"));

  const { external, setExternal, popup } = useAppContext();
  const [open, setOpen] = useState(false)

  const openSettings = async () => {
    setOpen(true)
  };

  const onClose = (returnValue) => {
    setOpen(false)
    if (
      returnValue.hasOwnProperty("external") &&
      returnValue.external !== external
    ) {
      setExternal(returnValue.external);
      pause();
    }
    if (returnValue.quality && returnValue.quality !== qualityPreference) {
      setQualityPreference(returnValue.quality);
      setMaxQuality(returnValue.quality);
    }
  }

  return (
    <>
      <Tooltip title="Fidelity settings">
        <IconButton
          aria-label="Fidelity Settings"
          onClick={openSettings}
        >
          <JGIcon is={IoEllipsisHorizontalOutline} fontSize={isDesktop ? "medium" : "small"} />
        </IconButton>
      </Tooltip>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="fidelity-settings-title"
        open={open}
        maxWidth="xs"
      >
        <DialogTitle id="fidelity-settings-title">
          Fidelity Settings
        </DialogTitle>
        <GearMenu
          popup={popup}
          external={external}
          onClose={onClose}
          qualityPreference={qualityPreference}
        />
      </Dialog>
    </>
  )
};

export default PlayerSettingsButton;
