import { PlanTier } from "../../../types/Plan";

// Helper function which infers keys and restricts values to PlanTier.
function asPlanTiers<T extends PlanTier>(planTiers: readonly T[]) {
  return Object.freeze(planTiers);
}

/**
 * Plan tiers, from lowest to highest, so order matters.
 * The higher the rank, the higher the tier. Generally means more features and higher minimum donation amounts.
 */
export const PLAN_TIERS = asPlanTiers([
  {
    key: "free",
    name: "Free",
    comparable: true,
    monthly: { minimumAmount: 0, code: null },
    annually: { minimumAmount: 0, code: null },
    oneTime: { minimumAmount: 0, code: null },
    popular: false,
    active: true,
  },
  {
    key: "unlimited-listening",
    name: "Unlimited",
    comparable: false,
    monthly: {
      minimumAmount: 0.01,
      code: "unlimited-listening",
    },
    annually: {
      minimumAmount: 0.01,
      code: "unlimited-listening",
    },
    oneTime: {
      minimumAmount: 0.01,
      code: "unlimited-listening",
    },
    legacyCodes: {
      "jazzgroove-donation-only": "Donation Only",
      "jazzgroove-donation-only-monthly": "Donation Only",
    },
    popular: false,
    active: false,
  },
  {
    key: "standard",
    name: "Standard",
    comparable: false,
    monthly: {
      minimumAmount: 0.01,
      code: "standard",
    },
    annually: {
      minimumAmount: 0.01,
      code: "standard",
    },
    oneTime: {
      minimumAmount: 0.01,
      code: "standard",
    },
    legacyCodes: {},
    popular: false,
    active: false,
  },
  {
    key: "premium",
    name: "Premium",
    comparable: false,
    monthly: {
      minimumAmount: 5,
      code: "jazzgroove-premium-monthly",
    },
    annually: {
      minimumAmount: 60,
      code: "jazzgroove-premium",
    },
    oneTime: {
      minimumAmount: 60,
      code: "jazzgroove-premium",
    },
    legacyCodes: {
      "jazzgroove-premium-annual": "Premium",
    },
    popular: false,
    active: false,
  },
  {
    key: "premium-plus-lite",
    name: "Premium Plus",
    comparable: false,
    monthly: {
      minimumAmount: 99999999.99,
      code: "premium-plus-lite",
    },
    annually: {
      minimumAmount: 99999999.99,
      code: "premium-plus-lite",
    },
    oneTime: {
      minimumAmount: 0.01,
      code: "premium-plus-lite",
    },
    legacyCodes: {},
    popular: false,
    active: true,
  },
  {
    key: "premium-plus",
    name: "Premium Plus",
    comparable: true,
    monthly: {
      minimumAmount: 10,
      code: "premium-plus-monthly",
    },
    annually: {
      minimumAmount: 100,
      code: "premium-plus",
    },
    oneTime: {
      minimumAmount: 100,
      code: "premium-plus",
    },
    legacyCodes: {
      "premium-plus-gift": "Premium Plus",
      "jazzgroove-premium-plus-2019-summer": "Premium Plus",
      "jazzgroove-premium-plus-2019-fall": "Premium Plus",
    },
    popular: true,
    active: true,
  },
] as const);

export const PLAN_TIER_KEYS = Object.freeze(PLAN_TIERS.map(tier => tier.key));

export type PlanTierKey = typeof PLAN_TIER_KEYS[number];

/**
 * Index of a plan tier within {@link PLAN_TIERS}.
 * Used to easily find how one tier relates to another.
 */
export const PlanTierRank = Object.freeze(PLAN_TIER_KEYS.reduce((accum, tierKey, index) => {
  accum[tierKey] = index;
  return accum;
}, {} as Record<PlanTierKey, number>));
