import { css, linkClasses, styled, Typography } from "@mui/material";
import clsx from "clsx";

const PREFIX = "jg-footer";
export const classes = {
  root: PREFIX,
  highlightedFooter: `${PREFIX}__highlighted-footer`,
  highlightedFooterItem: `${PREFIX}__highlighted-footer__item`,
};

export const HighlightedFooterItem = styled((props: any) => (
  <Typography variant="body2" {...props} className={clsx(props.className, classes.highlightedFooterItem)} />
))(({ theme }) => {
  return css`
    display: inline-block;
    color: ${theme.palette.primary.contrastText};

    &.${linkClasses.root} {
      text-decoration: none;
      font-weight: ${theme.typography.fontWeightMedium};

      &:hover {
        color: ${theme.palette.text.secondary};
      }
    }

    & + .${classes.highlightedFooterItem}::before {
      content: '|';
      display: inline-block;
      margin: ${theme.spacing(0, 1)};
      color: ${theme.palette.text.disabled};
    }
  `;
}) as any;
