import React, { useCallback, useEffect, useState } from "react";
import {
  alpha,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAsync } from "react-use";
import { IoMenu } from "react-icons/io5";
import clsx from "clsx";
import { useAuth0 } from "react-auth0-spa";
import { useHeader } from "Providers/Header";
import { isRedirectedFromMobileApp } from "Utils/RedirectedFromMobileApp";
import ContentfulClient from "Utils/ContentfulClient";
import { parseQSValue } from "Utils/Routing/QueryString";
import { CONTENTFUL_HOW_TO_LISTEN_DISPLAY_NAME_ENTRY_ID } from "Utils/Constants";
import { useHome } from "Hooks";
import SubscriptionApi, { ExpiringSubscription } from "Api/SubscriptionApi";
import Logo from "Pages/Components/Logo";
import DonateButton from "Pages/Components/DonateButton";
import JGIcon from "Pages/Components/JGIcon";
import AnnouncementBar from "Pages/Components/AnnouncementBar";
import PlanExpirationDialog, {
  PlanExpirationDialogProps,
} from "Pages/Components/PlanExpirationDialog";
import HEADER_NAV_ITEMS from "./HeaderNavItems";
import HeaderNavItem from "./HeaderNavItem";
import HeaderDrawer from "./HeaderDrawer";
import AuthActions from "./AuthActions";
import * as S from "./Header.styles";

let reloadInterval;

export interface HeaderProps {
  disableBanner?: boolean;
  disableNavItems?: boolean;
  disableDonateButton?: boolean;
}

type HeaderContentProps = HeaderProps & {
  expirationDialog?: PlanExpirationDialogProps;
  isFreeTrial?: boolean;
};

const HeaderContainer = (props: HeaderProps) => {
  const [expiringDialogOpen, setExpiringDialogOpen] = useState(false);
  const [isFreeTrial, setIsFreetrial] = useState(false);
  const [expiringSub, setExpiringSub] = useState<ExpiringSubscription | null>(
    null
  );
  const { isAuthenticated, user } = useAuth0();

  const handleExpiringDialogClose = useCallback(
    (renewed: boolean) => {
      if (renewed) {
        setExpiringSub(null);
      }

      setExpiringDialogOpen(false);
    },
    [setExpiringSub]
  );

  useEffect(() => {
    if (!isAuthenticated || !user) return;

    // This is for Chatlio - Maxime Laberge 2024
    window.identify(
      user.email,
      { sub: user.sub, plan: user.plan },
      Upscope("updateConnection", {
        // Set the user ID below. If you don't have one, set to undefined.
        uniqueId: user.plan,

        // Set the user's name or email below (e.g. ["John Smith", "john.smith@acme.com"]).
        identities: [user.family_name, user.given_name, user.email],
      })
    );

    // console.log("User plan: ", user);
    const reloadExpiring = () => {
      if (isAuthenticated && user && !user?.free_trial) {
        SubscriptionApi.getExpiring()
          .then((sub) => {
            setExpiringSub(sub);
            if (parseQSValue("action") === "renew") {
              setExpiringDialogOpen(true);
            }
          })
          .catch(() => setExpiringDialogOpen(false));
      }
    };

    reloadExpiring();
    clearInterval(reloadInterval);
    reloadInterval = setInterval(() => reloadExpiring(), 3600000);

    if (user?.free_trial) setIsFreetrial(true);

    return () => clearInterval(reloadInterval);
  }, [isAuthenticated, user]);

  useAsync(async () => {
    const helpCenterKey = CONTENTFUL_HOW_TO_LISTEN_DISPLAY_NAME_ENTRY_ID;
    const entry = await ContentfulClient.getEntry<{ buttonText: string }>(
      helpCenterKey
    );

    const helpCenterName = entry?.fields?.buttonText;
    const helpCenterNavItem = HEADER_NAV_ITEMS.find(
      (item) => item.key === helpCenterKey
    );
    if (helpCenterName && helpCenterNavItem) {
      helpCenterNavItem.label = helpCenterName;
    }
  });

  return (
    <Header
      expirationDialog={
        expiringSub
          ? {
              open: expiringDialogOpen,
              setOpen: setExpiringDialogOpen,
              subscription: expiringSub,
              onClose: handleExpiringDialogClose,
            }
          : undefined
      }
      isFreeTrial={isFreeTrial}
      {...props}
    />
  );
};

const Header = (props: HeaderContentProps) => {
  const {
    disableBanner,
    disableNavItems,
    disableDonateButton,
    expirationDialog,
    isFreeTrial,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const header = useHeader();
  const home = useHome();

  const [drawerOpen, setDrawerOpen] = useState(false);

  if (isRedirectedFromMobileApp()) return null;

  const isOpaque = !(home && header.bgOpacity < 1);
  return (
    <>
      <S.SAppBar
        className={clsx(
          S.classes.root,
          header.mode === "dark" || isOpaque
            ? S.classes.modeDark
            : S.classes.modeLight,
          home && "mui-fixed"
        )}
        position={home ? "fixed" : "sticky"}
        sx={{ boxShadow: !isOpaque ? "none" : undefined }}
      >
        {!disableBanner && <AnnouncementBar />}

        <S.SToolbar
          sx={(theme) => {
            const bgColor = theme.palette.background.paper;
            return {
              "--jg-header-item-shadow": isOpaque
                ? undefined
                : `0 0 2px 1px ${alpha(bgColor, 0.5)}`,
              "--jg-header-text-shadow": `0 0 1rem ${alpha(
                bgColor,
                1
              )}, 0 0 0.5rem ${alpha(bgColor, 0.8)}`,
              "--jg-header-logo-shadow": `0 0 1rem ${alpha(bgColor, 0.4)}`,
              background: alpha(bgColor, home ? header.bgOpacity : 1),
            };
          }}
        >
          <S.Nav direction="row" spacing={3} component="nav">
            <S.LogoContainer to="/">
              <Logo />
            </S.LogoContainer>

            {!isMobile && !disableNavItems && (
              <S.NavMenu className={S.classes.nav}>
                {HEADER_NAV_ITEMS.map((item) => (
                  <HeaderNavItem {...item} />
                ))}
              </S.NavMenu>
            )}
          </S.Nav>

          <Stack direction="row" sx={{ alignItems: "center" }}>
            {!disableDonateButton && (
              <DonateButton
                expirationDialog={expirationDialog}
                isFreeTrial={isFreeTrial}
                className={S.classes.button}
                size={isSmall ? "small" : "medium"}
              />
            )}

            <AuthActions
              className={S.classes.button}
              expirationDialog={expirationDialog}
              size={isSmall ? "small" : "medium"}
              sx={{
                ml: isMobile ? 1 : 2,
              }}
            />

            {isMobile && !disableNavItems && (
              <IconButton
                size="large"
                onClick={() => setDrawerOpen((isDrawerOpen) => !isDrawerOpen)}
              >
                <JGIcon is={IoMenu} />
              </IconButton>
            )}
          </Stack>
        </S.SToolbar>
      </S.SAppBar>

      {isMobile && (
        <HeaderDrawer open={drawerOpen} setOpen={setDrawerOpen} {...header} />
      )}

      {expirationDialog && <PlanExpirationDialog {...expirationDialog} />}
    </>
  );
};

export default HeaderContainer;
