import { css, Paper, styled } from "@mui/material";
import classes from "./classes";

// Exported here to keep with the pattern.
export { default as classes } from "./classes";

export const Container = styled(Paper)(
  ({ theme }) => css`
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: ${theme.zIndex.player};
    display: flex;
    flex-shrink: 0;
    width: 100%;
    height: var(--jg-player-height);
    transition: ${theme.transitions.create("height")};

    &.${classes.drawerOpen} {
      --jg-player-height: ${theme.mixins.toolbar.height}px;
    }

    ${theme.breakpoints.down("md")} {
      & .${classes.song} {
        order: 2;
      }

      & .${classes.controls} {
        order: 1;
      }

      & .${classes.actions} {
        order: 3;
      }
    }
  `
) as typeof Paper;

export const Content = styled("div")`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const DevPlayer = styled("div")`
  display: grid;
  position: absolute;
  right: 0;
  top: 120px;
`;
