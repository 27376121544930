import { Typography } from "@mui/material";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router";
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from "Pages/Components/ConfirmationDialog";

const SignInDialog = ({
  open,
  onClose,
  onClick,
}: ConfirmationDialogProps | any) => {
  const [cookies, setCookie] = useCookies(["closedSignInPopup"]);
  const location = useLocation();

  const closedSignInPopup = cookies.closedSignInPopup;

  const closeDialog = (confirm: boolean | null) => {
    if (confirm) {
      onClick();
    }
    if (onClose) {
      // Expires after 24 hours
      setCookie("closedSignInPopup", "true", { maxAge: 60 * 60 * 24 });
      onClose(false);
    }
  };

  if (
    closedSignInPopup ||
    location.pathname === "/checkout" ||
    location.pathname === "/checkout/" ||
    location.pathname === "/freetrial/" ||
    location.pathname === "/freetrial"
  ) {
    return <></>;
  }

  return (
    <>
      <ConfirmationDialog
        open={open}
        onClose={closeDialog}
        title="You are no longer signed in"
        confirmText="Sign In"
        ConfirmButtonProps={{ color: "primary" }}
      >
        <Typography variant="body2">
          You will not have access to your plan perks until you sign in again.
        </Typography>
      </ConfirmationDialog>
    </>
  );
};

export default SignInDialog;
