import React, { useState, useEffect, useContext, useCallback } from "react";
import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  RedirectLoginOptions,
} from "@auth0/auth0-spa-js";
import UserApi, { GetDBUserOptions } from "Api/UserApi";
import { User } from "../types/Auth";
import { PlanData } from "../types/Plan";

type RefreshUserFunction = (options?: GetDBUserOptions) => Promise<void>;

export type LoginWithRedirectOptions<TAppState = any> =
  RedirectLoginOptions<TAppState> & {
    /**
     * Route to direct to after login. Path must be whitelisted.
     * If provided, overwrites {@link RedirectLoginOptions.redirect_uri}.
     */
    redirect_to?: string;
  };

type LoginWithRedirect<TAppState = any> = (
  options?: LoginWithRedirectOptions<TAppState>
) => Promise<void>;

type AuthState = Pick<
  Auth0Client,
  "getIdTokenClaims" | "getTokenWithPopup" | "loginWithPopup" | "logout"
> & {
  isAuthenticated: boolean;
  user: User;
  loading: boolean;
  popupOpen: boolean;
  refreshUser: RefreshUserFunction;
  refreshUserSilently: RefreshUserFunction;
  handleRedirectCallback: () => Promise<void>;
  loginAndReturn: LoginWithRedirect;
  loginWithRedirect: LoginWithRedirect;
};

export const Auth0Context = React.createContext<AuthState>({} as AuthState);
export const useAuth0 = () => useContext(Auth0Context);
export let lastUser: any = undefined;
export let auth0Initialized = false;

let _initOptions: Auth0ClientOptions;

const getAuth0Client = (_?: Auth0ClientOptions): Promise<Auth0Client> => {
  return new Promise(async (resolve, reject) => {
    let client;
    if (!client) {
      try {
        client = await createAuth0Client(_initOptions);
        resolve(client);
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        reject(new Error("getAuth0Client Error", e));
      }
    }
  });
};

export const getTokenSilently = async (
  ...p: Parameters<Auth0Client["getTokenSilently"]>
): ReturnType<Auth0Client["getTokenSilently"]> => {
  const client = await getAuth0Client();
  return await client.getTokenSilently(...p);
};

export const getUser = async (
  ...p: Parameters<Auth0Client["getUser"]>
): Promise<User> => {
  const client = await getAuth0Client();
  const user = await client.getUser(...p);
  const planData = await getPlan(user?.email as string, { sync: false });
  const jgUser = { ...user, ...planData };
  lastUser = jgUser;
  return jgUser;
};

async function getPlan(
  email: string,
  options?: GetDBUserOptions
): Promise<PlanData> {
  try {
    const response = await UserApi.getDBUser(email, options);
    return {
      plan: response.planId,
      subscriptionId: response.subscriptionId,
      ttl: response.subscriptionCurrentTermEnd,
      free_trial: response.free_trial,
      subscriptionStatus: response.subscription_status,
    };
  } catch (e) {
    return {
      plan: "",
      subscriptionId: "",
    };
  }
}

export const Auth0Provider = ({
  children,
  onRedirectCallback,
  ...initOptions
}: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User>({} as User);
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      _initOptions = initOptions;
      const client = await getAuth0Client(initOptions);
      setAuth0(client);
      if (window.location.search.includes("code=")) {
        const { appState } = await client.handleRedirectCallback();
        onRedirectCallback(appState);
      }
      const isAuthenticated = await client.isAuthenticated();

      try {
        if (isAuthenticated) {
          const user = await client.getUser();
          const planData = await getPlan(user?.email as string);
          lastUser = { ...user, ...planData };
          setUser({ ...user, ...planData });
          localStorage.setItem("hasAuthenticated", "true");
        }
      } catch (err) {
        console.error(err);
      }

      auth0Initialized = true;

      /**
       * Don't set the user's authentication status before getting their user profile.
       * This prevents unnecessary and premature re-renders
       */
      setIsAuthenticated(isAuthenticated);

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  /**
   * Refresh the user. Note that unlike {@link UserApi.getDBUser}, this defaults
   * to {@link GetDBUserOptions.sync} being true to force the plan to refresh, too.
   */
  const refreshUser: RefreshUserFunction = useCallback(
    async (options: GetDBUserOptions = { sync: true }) => {
      await auth0Client?.getTokenSilently({ ignoreCache: true });
      const user = await auth0Client?.getUser();
      const planData = await getPlan(user?.email as string, options);
      setUser({ ...user, ...planData });
    },
    [auth0Client]
  );

  /**
   * Refresh the user while suppressing any errors. Note that unlike {@link UserApi.getDBUser}, this defaults
   * to {@link GetDBUserOptions.sync} being true to force the plan to refresh, too.
   */
  const refreshUserSilently: RefreshUserFunction = useCallback(
    async (options: GetDBUserOptions = { sync: true }) => {
      try {
        await refreshUser(options);
      } catch (_) {}
    },
    [refreshUser]
  );

  const getUserAndSetAuth = async () => {
    await refreshUser({ sync: true });
    setIsAuthenticated(true);
  };

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client?.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }

    await getUserAndSetAuth();
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client?.handleRedirectCallback();
    await getUserAndSetAuth();
    setLoading(false);
  };

  const loginWithRedirect: LoginWithRedirect = async <TAppState extends any>(
    options?: LoginWithRedirectOptions<TAppState>
  ): Promise<void> => {
    if (options) {
      const { redirect_to, ...redirectOptions } = options;

      if (redirect_to?.startsWith("/")) {
        redirectOptions.redirect_uri = `${process.env.REACT_APP_DOMAIN}${redirect_to}`;
      }

      options = redirectOptions;
    }

    return auth0Client?.loginWithRedirect(options);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        refreshUser,
        refreshUserSilently,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p: any[]) =>
          auth0Client?.getIdTokenClaims(...p) as ReturnType<
            Auth0Client["getIdTokenClaims"]
          >,
        loginWithRedirect,
        getTokenWithPopup: (...p: any[]) =>
          auth0Client?.getTokenWithPopup(...p) as ReturnType<
            Auth0Client["getTokenWithPopup"]
          >,
        logout: (...p: any[]) => auth0Client?.logout(...p),
        loginAndReturn: (opts) => {
          opts = opts || {};
          opts.appState = opts.appState || {};
          opts.appState.targetUrl = window.location.pathname;
          return loginWithRedirect(opts);
        },
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
