const PREFIX = "jg-header";
const classes = {
  root: PREFIX,
  logo: `${PREFIX}__logo`,
  nav: `${PREFIX}__nav`,
  navItem: `${PREFIX}__nav-item`,
  button: `${PREFIX}__button`,
  authActions: `${PREFIX}__auth-actions`,
  drawer: `${PREFIX}__drawer`,

  sizeMedium: `${PREFIX}--size-medium`,
  sizeLarge: `${PREFIX}--size-large`,
  modeDark: `${PREFIX}__mode-dark`,
  modeLight: `${PREFIX}__mode-light`,
};

export default classes;
