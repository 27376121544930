import React, { Dispatch, forwardRef, SetStateAction } from "react";
import { useLocation } from "react-router";
import { Box, IconButton, Stack, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import ReactGA from "react-ga";
import { IoOpenOutline } from "react-icons/io5";
import JGIcon from "../../JGIcon";
import SelectStation from "../SelectStation";
import PlayerVolume  from "./PlayerVolume";
import { DrawerToggleButton } from "../PlayerDrawer";
import { PlayerVolumeProps } from "../types";
import * as S from "./PlayerActions.styles";

type PlayerActionsProps = PlayerVolumeProps & {
  pause: () => void;
  drawerOpen: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

const PlayerActionsMobile = ({ drawerOpen, setDrawerOpen }: PlayerActionsProps) => {
  return (
    <DrawerToggleButton
      open={drawerOpen}
      setOpen={setDrawerOpen}
      sx={{ opacity: drawerOpen ? 0 : undefined }}
    />
  );
};

const PlayerActions = ({ pause, ...rest }: PlayerActionsProps) => {
  const location = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      <Stack spacing={isDesktop ? 1 : 0} direction="row" sx={{ pr: isDesktop ? 1 : 0 }}>
        <PlayerVolume {...rest} />

        <Tooltip title="Open in pop-up">
          <IconButton
            aria-label="Open player in pop-up"
            onClick={(e) => {
              e.preventDefault();
              pause();
              ReactGA.event({
                category: "player",
                action: "popup",
              });
              window.open(
                "/stream" + location.search,
                "stream",
                "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=700,left=100,top=100"
              );
            }}
          >
            <JGIcon is={IoOpenOutline} fontSize={isDesktop ? "medium" : "small"} />
          </IconButton>
        </Tooltip>
      </Stack>

      <SelectStation
        MenuProps={{
          anchorOrigin: { vertical: "top", horizontal: "center" },
          transformOrigin: { vertical: "bottom", horizontal: "center" },
        }}
      />
    </>
  );
};

const PlayerActionsContainer = forwardRef<typeof Box, PlayerActionsProps>((props, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      ref={ref}
      className={S.classes.root}
      sx={{
        flex: {
          xs: 0,
          md: 1,
        },
        alignItems: "center",
        height: "100%",
      }}
    >
      <S.Content spacing={1} direction="row">
        { isMobile
          ? <PlayerActionsMobile {...props} />
          : <PlayerActions {...props} />
        }
      </S.Content>
    </Box>
  );
});

export default PlayerActionsContainer;
