import { useCallback, useEffect, useState } from "react";
import { PaletteMode } from "@mui/material";
import { HeaderContext } from "./Context";
import { DEFAULT_HEADER_MODE } from "Utils/Constants";
import useHome from "Hooks/useHome";

export const HeaderProvider = ({ children }) => {
  const [mode, _setMode] = useState<PaletteMode>(DEFAULT_HEADER_MODE);
  const home = useHome();
  const [bgOpacity, setBgOpacity] = useState(home ? 0 : 1);

  const handleScroll = useCallback(() => {
    /**
     * Normalizes the scroll position to a value between 0 and 1, which will then
     * be used to set the opacity of the background on the header on the home page.
     */
    setBgOpacity(Math.min((window.scrollY / window.innerHeight) * 6, 1));
  }, [setBgOpacity]);

  /** Wrapper used in case {@link SliderImage} JSON does not have mode set correctly. */
  const setMode = (mode: PaletteMode) => {
    _setMode((mode && ["light", "dark"].includes(mode)) ? mode : DEFAULT_HEADER_MODE);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <HeaderContext.Provider value={{ mode, setMode, bgOpacity }}>
      {children}
    </HeaderContext.Provider>
  );
}
