import React, { useCallback, useEffect, useState } from "react";
import CheckoutStepper from "./Components/Checkout/CheckoutStepper";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  alpha,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Link,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Entry } from "contentful";
import { BLOCKS, Document, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useAsync } from "react-use";
import { IoInformationCircleOutline } from "react-icons/io5";
import { CONTENTFUL_DONATE_DOC_ENTRY_ID } from "Utils/Constants";
import { createRendererOptions } from "Utils/ContentfulRenderer";
import History from "Utils/Routing/History";
import BannerBg from "Assets/checkout-banner.jpg";
import MobileBg from "Assets/checkout-mobile-background.jpg";
import ContentfulClient from "Utils/ContentfulClient";
import DefaultLayout from "Containers/Layout/DefaultLayout";
import Title from "Pages/Components/Title";
import JGIcon from "Pages/Components/JGIcon";
import { useAuth0 } from "react-auth0-spa";

interface ContentfulDonateDocDto {
  /** Text equivalent of {@link title} */
  topText: string;
  // Subtitle of page.
  subtitle: Document;
  content: Document;
  oneTimeCheckoutActive: boolean;
}

interface ContentfulDonateDoc {
  subtitle: React.ReactNode;
  content: React.ReactNode;
  oneTimeCheckoutActive: boolean;
}

const stripe = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string);

const PREFIX = "jg-checkout";
const classes = {
  root: PREFIX,
  subtitle: `${PREFIX}__subtitle`,
  subtitleStrongText: `${PREFIX}__subtitle__strong-text`,
};

const donateDocToReactComponents = (
  entry: Entry<ContentfulDonateDocDto>
): ContentfulDonateDoc => ({
  subtitle: documentToReactComponents(
    entry.fields.subtitle,
    createRendererOptions({
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => children,
      },
      renderMark: {
        [MARKS.BOLD]: (text) => <SubtitleStrongText>{text}</SubtitleStrongText>,
      },
    })
  ),

  content: documentToReactComponents(
    entry.fields.content,
    createRendererOptions({
      renderNode: {
        [BLOCKS.HEADING_2]: (node, children) => (
          <Typography variant="h4">{children}</Typography>
        ),
      },
    })
  ),
  oneTimeCheckoutActive: entry.fields.oneTimeCheckoutActive,
});

const SubtitleStrongText = (props: TypographyProps) => (
  <Typography
    className={classes.subtitleStrongText}
    variant="inherit"
    component="span"
    fontWeight="bold"
    {...props}
  />
);

const Checkout = (props) => {
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showLanding, setShowLanding] = useState(true);
  const [learnMoreDialogOpen, setLearnMoreDialogOpen] = useState(false);
  const { isAuthenticated, user, refreshUserSilently } = useAuth0();

  const { value: contentfulDoc } = useAsync(async () => {
    const entry = await ContentfulClient.getEntry<ContentfulDonateDocDto>(
      CONTENTFUL_DONATE_DOC_ENTRY_ID
    );
    return donateDocToReactComponents(entry);
  }, []);

  const { value: userRefreshed } = useAsync(async () => {
    if (isAuthenticated) {
      await refreshUserSilently();

      return user;
    }

    return null;
  }, [isAuthenticated]);

  useEffect(() => {
    console.log("User plan from Checkout: ", userRefreshed);
    console.log("Contentul entity: ", contentfulDoc);
    if (
      contentfulDoc?.oneTimeCheckoutActive &&
      userRefreshed &&
      userRefreshed?.plan &&
      userRefreshed?.ttl &&
      !userRefreshed?.free_trial
    ) {
      const threshold = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30);
      const ttl = new Date(userRefreshed.ttl * 1000);
      // console.log("Threshold: ", threshold.toISOString());
      // console.log("TTL: ", ttl.toISOString());
      // console.log("Threshold: ", threshold.valueOf());
      // console.log("TTL: ", ttl.valueOf());
      // console.log("User Subscription: ", userRefreshed?.subscriptionId);
      if (
        threshold.valueOf() <= ttl.valueOf() ||
        (userRefreshed?.subscriptionId &&
          userRefreshed?.plan !== "premium-plus-lite" &&
          userRefreshed?.subscriptionStatus !== "non_renewing")
      ) {
        // console.log("greater than 30 days or has active subscription");
        History.push("/checkout/one-time");
      }

      if (
        ttl.valueOf() > Date.now() &&
        threshold.valueOf() > ttl.valueOf() &&
        userRefreshed?.subscriptionId &&
        userRefreshed?.plan !== "premium-plus-lite" &&
        userRefreshed?.subscriptionStatus === "non_renewing"
      ) {
        // console.log("less than 30 days and doesn't have active renewing subscription");
        History.push("/account");
      }
    }
  }, [userRefreshed, contentfulDoc]);

  const Subtitle = useCallback(
    (props: { colored?: boolean }) => {
      const { colored = false } = props;

      return (
        <Box
          className={classes.subtitle}
          component="span"
          sx={{
            [`& .${classes.subtitleStrongText}`]: colored
              ? { color: "primary.main" }
              : { whiteSpace: "nowrap" },
          }}
        >
          {contentfulDoc?.subtitle || (
            <>
              Everything you hear on The Jazz Groove is{" "}
              <SubtitleStrongText>funded by listeners</SubtitleStrongText> like
              you.
            </>
          )}
        </Box>
      );
    },
    [contentfulDoc?.subtitle]
  );

  const handleLearnMoreDialogOpen = () => setLearnMoreDialogOpen(true);
  const handleLearnMoreDialogClose = () => setLearnMoreDialogOpen(false);

  return (
    <>
      <Title value="Donate" />

      <DefaultLayout
        disablePlayer
        disableFooter
        disableBanner
        disableNavItems
        disableDonateButton
        disableHeader={isMobile && !showLanding}
      >
        <Elements stripe={stripe}>
          {!isMobile && (
            <Box
              sx={{
                height: "200px",
                background: `linear-gradient(114.79deg, ${alpha(
                  theme.palette.primary.main,
                  0.72
                )} -2.66%, ${alpha(
                  theme.palette.primary.dark,
                  0.56
                )} 100%), url('${BannerBg}')`,
                backgroundPosition: "bottom",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Container
                sx={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  margin: "0 auto",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="display1"
                    sx={(theme) => ({
                      textTransform: "uppercase",
                      textShadow: theme.textShadows[1],
                    })}
                  >
                    Donate
                  </Typography>

                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ pl: 2, borderColor: "text.disabled" }}
                  />

                  <Box sx={{ pl: 2, maxWidth: "50ch" }}>
                    <Typography
                      variant="h5"
                      sx={(theme) => ({
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: "regular",
                        lineHeight: 1.2,
                        textShadow: theme.textShadows[1],
                      })}
                    >
                      <Subtitle />
                    </Typography>
                  </Box>

                  <Button
                    size="small"
                    variant="outlined"
                    color="primaryContrast"
                    onClick={handleLearnMoreDialogOpen}
                    sx={{ ml: 3 }}
                  >
                    Learn More
                  </Button>
                </Box>
              </Container>
            </Box>
          )}

          {isMobile && showLanding && (
            <Box
              sx={{
                height: "100vh",
                background: `url('${MobileBg}')`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <Box sx={{ pt: 8, pr: 4, pl: 4 }}>
                <Typography variant="h2">
                  <Subtitle colored />

                  <IconButton
                    color="primaryContrast"
                    onClick={handleLearnMoreDialogOpen}
                  >
                    <JGIcon is={IoInformationCircleOutline} />
                  </IconButton>
                </Typography>

                <Typography variant="body1" sx={{ mt: 4 }}>
                  Join our family of Jazz Groove listeners who’ve become
                  contributors. Your donation is tax deductible (EIN
                  #81-1511864).
                </Typography>
                <Button
                  fullWidth
                  size="large"
                  sx={{ mt: 4 }}
                  onClick={() => setShowLanding(false)}
                >
                  Donate
                </Button>
                <Typography variant="body1" sx={{ textAlign: "center", mt: 1 }}>
                  Or{" "}
                  <Link
                    href="https://careasy.org/nonprofit/foundationtoadvancejazzjazzgroove"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    donate a car
                  </Link>
                  .
                </Typography>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              pt: isMobile ? 0 : 4,
              pb: 8,
              margin: "0 auto",
              maxWidth: "lg",
              display: !isMobile || !showLanding ? "block" : "none",
            }}
          >
            <CheckoutStepper
              isMobile={isMobile}
              setShowLanding={(val) => {
                if (isMobile) setShowLanding(val);
              }}
            ></CheckoutStepper>
          </Box>
        </Elements>
      </DefaultLayout>

      <Dialog
        fullScreen={isXSmall}
        open={learnMoreDialogOpen}
        onClose={handleLearnMoreDialogClose}
      >
        <DialogContent>
          <Typography variant={isMobile ? "h3" : "h5"} component="p">
            <Subtitle colored />
          </Typography>

          {contentfulDoc?.content}
        </DialogContent>

        <DialogActions>
          <Button color="neutral" onClick={handleLearnMoreDialogClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Checkout;
