import React from "react";
import { Button, ButtonBase, ButtonProps, ButtonTypeMap } from "@mui/material";
import { LoginQueryParams } from "Utils/Routing/LoginRedirect";
import clsx from "clsx";
import { useAuth0 } from "react-auth0-spa";
import { useMatchPath } from "Hooks";

type SignInButtonProps<T = {}> =
  ButtonProps<ButtonTypeMap["defaultComponent"], T>
  & {
  redirectTo?: LoginQueryParams["redirect_to"],
  ButtonComponent?: typeof ButtonBase
};

const SignInButton = <T extends {}>(props: SignInButtonProps<T>) => {
  const {
    redirectTo,
    ButtonComponent = Button,
    className,
    children = "Sign In",
    ...rest
  } = props;

  const { loginWithRedirect } = useAuth0();
  const isThankYouPage = useMatchPath("/thank-you");

  const redirectParam = redirectTo || (isThankYouPage ? "/" : null);
  const queryParams: LoginQueryParams | undefined = redirectParam
    ? { redirect_to: redirectParam }
    : undefined;

  return (
    <ButtonComponent
      className={clsx("jg-sign-in-button", className)}
      {...rest}
      onClick={() => loginWithRedirect(queryParams)}
    >
      {children}
    </ButtonComponent>
  );
};

export default SignInButton;
