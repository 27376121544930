import React, { Dispatch, forwardRef, SetStateAction } from "react";
import { IconButton, IconButtonProps, SvgIconProps } from "@mui/material";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import clsx from "clsx";
import { normalizeSx } from "Utils/Components/normalizeSx";
import * as S from "./DrawerToggleButton.styles";

type DrawerToggleButtonProps = IconButtonProps & {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  IconProps?: SvgIconProps;
}

const DrawerToggleButton = forwardRef<any, DrawerToggleButtonProps>((props, ref) => {
  const {
    open,
    setOpen,
    sx = [],
    IconProps,
    ...rest
  } = props;

  return (
    <IconButton
      className={clsx(S.classes.root, open ? S.classes.open : S.classes.closed)}
      ref={ref}
      {...rest}
      sx={[
        theme => ({
          opacity: 1,
          transition: theme.transitions.create(["opacity"]),
        }),
        ...normalizeSx(sx),
      ]}
      onClick={() => setOpen(prevDrawerOpen => !prevDrawerOpen)}
    >
      <S.DrawerToggleIcon is={open ? IoChevronDown : IoChevronUp} {...IconProps} />
    </IconButton>
  );
});

export default DrawerToggleButton;
