import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { BoxProps, ButtonProps, Menu } from "@mui/material";
import { IoChevronDown } from "react-icons/io5";
import clsx from "clsx";
import { HeaderNavItemProps } from "./HeaderNavItems";
import HeaderNavMenuItem from "./HeaderNavMenuItem";
import JGIcon from "Pages/Components/JGIcon";
import * as S from "./HeaderNavItem.styles";

const HeaderNavItem = (props: HeaderNavItemProps & BoxProps & { component?: any }) => {
  const {
    label,
    link,
    items,
    className,
    component,
    ...rest
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const closeMenu = () => setAnchorEl(null);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  if (!link && !items) return null;

  const isMenuOpen = Boolean(anchorEl);
  const buttonProps: ButtonProps & Partial<HeaderNavItemProps["link"]> =
    link
      ? { component: NavLink, ...link as any }
      : {
          onClick: openMenu,
          sx: { cursor: "pointer" },
          endIcon: <JGIcon is={IoChevronDown} fontSize="small" />,
        };

  return (
    <S.ListItem className={clsx(S.classes.root, className)} component={component ?? "li"} {...rest}>
      <S.ItemButton
        variant="text"
        className={clsx(S.classes.link, isMenuOpen && S.classes.linkMenuOpen)}
        {...buttonProps}
      >
        <S.ItemButtonText className={S.classes.text} variant="subtitle2" component="span">
          {label}
        </S.ItemButtonText>
      </S.ItemButton>

      {!link &&
        <Menu
          anchorEl={anchorEl}
          disableScrollLock
          open={isMenuOpen}
          onClose={closeMenu}
          onClick={closeMenu}
        >
          {items?.map(item => <HeaderNavMenuItem {...item} />)}
        </Menu>
      }
    </S.ListItem>
  );
};

export default HeaderNavItem;
