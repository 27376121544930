import { Components, Theme } from "@mui/material/styles";

const Divider: Components<Theme> = {
  MuiDivider: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderColor: theme.palette.action.selected,
      }),
      light: ({ theme }) => ({
        borderColor: theme.palette.divider,
      }),
    },
  },
};

export default Divider;
