import { Grid } from "@mui/material";
import { useEffect } from "react";
import { DonationType } from "Utils/Constants/Donate";
import SelectBubble from "../SelectBubble";

const DonationTypeStep = (props: {
  setDonationType: (donationType: DonationType) => void,
  donationType: DonationType
}) => {
  const {setDonationType, donationType} = props

  const { setDonationType: propsSetDonationType } = props
  useEffect(() => {
    propsSetDonationType(donationType)
  }, [donationType, propsSetDonationType])

  return (
    <>
      <Grid container spacing={1} sx={{ mb: 1 }}>
        <Grid item xs={6} md={4}>
          <SelectBubble showCheck selected={donationType === DonationType.monthly} label="Monthly" onClick={() => setDonationType(DonationType.monthly)}></SelectBubble>
        </Grid>
        <Grid item xs={6} md={4}>
          <SelectBubble showCheck selected={donationType === DonationType.oneTime} label="One-time" onClick={() => setDonationType(DonationType.oneTime)}></SelectBubble>
        </Grid>
      </Grid>
    </>
  );
};

export default DonationTypeStep;
