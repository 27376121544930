import React, { forwardRef, useMemo } from "react";
import { IconType } from "react-icons/lib";
import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

export type JGIconProps = SvgIconProps & {
  is: IconType;
  circleFilled?: boolean;
};


const CIRCLE_FILLED_VIEW_BOX_SIZE = 80;
const CIRCLE_FILLED_PADDING = 16;
const CIRCLE_FILLED_STROKE_WIDTH = 2;

const JGCircleFilledIcon = forwardRef<SVGSVGElement, JGIconProps & { viewBox: string }>((props, ref) => {
  const {
    is: IconComponent,
    viewBox,
    children,
    ...rest
  } = props;

  const theme = useTheme();
  const primary = theme.palette.primary;
  const white = theme.palette.common.white;

  const viewBoxParts = viewBox.split(" ");
  const iconSize = viewBoxParts.length ? Number(viewBoxParts[viewBoxParts.length - 1]) : 24;

  return (
    <SvgIcon ref={ref} viewBox={`0 0 ${CIRCLE_FILLED_VIEW_BOX_SIZE} ${CIRCLE_FILLED_VIEW_BOX_SIZE}`} {...rest}>
      <path d="M40 80c22.091 0 40-17.909 40-40S62.091 0 40 0 0 17.909 0 40s17.909 40 40 40Z"
            fill={primary.light}/>
      <IconComponent
        fill={white}
        stroke={primary.dark}
        strokeWidth={iconSize / CIRCLE_FILLED_VIEW_BOX_SIZE * CIRCLE_FILLED_STROKE_WIDTH * 2}
        x={CIRCLE_FILLED_PADDING}
        y={CIRCLE_FILLED_PADDING}
        size={CIRCLE_FILLED_VIEW_BOX_SIZE - (CIRCLE_FILLED_PADDING * 2)}
      />
    </SvgIcon>
  );
});

/**
 * Wrapper around SVG icons from `react-icons` to solve layout issues in MUI with
 * non-material icons, as recommended in MUI's `SVGIcon` [documentation](https://mui.com/components/icons/#font-awesome).
 *
 * If using ionicons, make sure to import from `react-icons/io5`. IDEs may try
 * to import from `react-icons/all`, which causes an error when compiling.
 *
 * @example
 * ```ts
 * import { IoHeart } from "react-icons/io5";
 *
 * const FooBar = () => <JGIcon is={IoHeart} />;
 * ```
 */
const JGIcon = forwardRef<SVGSVGElement, JGIconProps>((props, ref) => {
  const {
    is: IconComponent,
    circleFilled = false,
    ...rest
  } = props;

  // By caching the view box, we can use any icon component we want from `react-icons`.
  const viewBox = useMemo(() => IconComponent({}).props?.attr?.viewBox, [IconComponent]);

  return circleFilled
    ? <JGCircleFilledIcon ref={ref} is={IconComponent} viewBox={viewBox} {...rest} />
    : <SvgIcon ref={ref} component={IconComponent} viewBox={viewBox} {...rest} />;
});

/**
 * "To provide maximum flexibility and performance, MUI needs a way to know the nature of the child elements a component
 * receives. To solve this problem, we tag some of the components with a `muiName` static property when needed.
 *
 * You may, however, need to wrap a component in order to enhance it, which can conflict with the `muiName` solution.
 * If you wrap a component, verify if that component has this static property set."
 *
 * @see https://mui.com/guides/composition/#wrapping-components
 */
(JGIcon as any).muiName = SvgIcon.muiName;

export default JGIcon;
