import { useEffect } from "react";
import { RouteChildrenProps } from "react-router";
import queryString from "query-string";
import { LoginWithRedirectOptions, useAuth0 } from "react-auth0-spa";
import History from "Utils/Routing/History";

export type LoginQueryParams = Omit<LoginWithRedirectOptions, "appState">;

const LoginRedirect = ({ location }: RouteChildrenProps) => {
  const { loading, loginWithRedirect, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      History.push("/");
    } else if (!loading) {
      const options: LoginQueryParams = (location && queryString.parse(location.search)) || {};
      if (!options.redirectMethod) options.redirectMethod = "replace";
      loginWithRedirect(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, loginWithRedirect, isAuthenticated]);

  return null;
};

export default LoginRedirect;
