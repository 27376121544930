import { useCallback, useEffect, useRef, useState } from "react";
import { IoCard, IoLogoPaypal } from "react-icons/io5";
import { RiBankFill } from "react-icons/ri";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SelectBubble from "../SelectBubble";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import ReCAPTCHA from "react-google-recaptcha";
import Spinner from "Pages/Components/Spinner";
import ReactGA from "react-ga";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import Axios from "axios";
import { formatCurrency } from "Utils/Format";
import StripeTextField from "Pages/Components/Checkout/Stripe/StripeTextField";
import { DonationType } from "Utils/Constants";
import { getTokenSilently } from "react-auth0-spa";
import { DEFAULT_DONATION_AMOUNTS } from "Pages/Components/Checkout/Constants";

export enum PaymentMethod {
  card,
  payPal,
  ach,
  freeTrial,
}

function usePlaidLinkToken(onPlaidSuccess) {
  const [plaidLinkToken, setPlaidLinkToken] = useState("");
  const [plaidLinkConfig, setPlaidLinkConfig] = useState<PlaidLinkOptions>(
    {} as any
  );

  useEffect(() => {
    getTokenSilently()
      .catch((err) => {
        console.error(err);
        return "";
      })
      .then((accessToken) => {
        return Axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/checkout/plaid-link`,
          {
            accessToken: accessToken ?? undefined,
          },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY as string,
            },
          }
        ).then((plaidLinkResult) => {
          setPlaidLinkToken(plaidLinkResult.data.link_token);
        });
      });
  }, []);

  const result = usePlaidLink(plaidLinkConfig);

  useEffect(() => {
    if (plaidLinkToken)
      setPlaidLinkConfig({
        token: plaidLinkToken,
        publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY as string,
        clientName: "The Jazz Groove",
        product: ["auth"],
        env:
          process.env.REACT_APP_NODE_ENV === "dev" ? "sandbox" : "production",
        accountSubtypes: { depository: ["checking", "savings"] },
        onExit: (err, metadata) => {
          if (err) {
            ReactGA.event({
              category: "ach",
              action: "exitWithError",
            });
          } else {
            ReactGA.event({
              category: "ach",
              action: "exit",
            });
          }
        },
        onSuccess: onPlaidSuccess,
      });
    // eslint-disable-next-line
  }, [plaidLinkToken]);

  return result;
}

export default function PaymentStep(props: {
  stripe: any;
  elements: any;
  paymentMethod: PaymentMethod;
  donationAmount: number;
  donationAdditionalAmount: number;
  donationType: DonationType;
  isGift: boolean;
  gift: any;
  loading: boolean;
  setDonationAdditionalAmount: (additionalAmount: number) => void;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  setCaptcha: (captcha: any) => void;
  coupon: any;
  setCoupon: (coupon: any) => void;
  setIsValid: (valid: boolean) => void;
  plaidToken: any;
  setPlaidToken: (token: any) => void;
  donationPlan: string;
  setFallbackGift: (gift: any) => void;
  autoRenew: boolean;
  setAutoRenew: (autoRenew: boolean) => void;
  freeTrial?: boolean;
}) {
  const [paymentMethod, setPaymentMethod] = useState(props.paymentMethod);
  const [donationAdditionalAmount, setDonationAdditionalAmount] = useState(
    props.donationAdditionalAmount || 0
  );
  const [plaidToken] = useState(props.plaidToken);
  const [plaidAccounts, setPlaidAccounts] = useState<any[]>([]);

  const [cardError, setCardError] = useState("");
  const [cardComplete, setCardComplete] = useState(false);
  const [expError, setExpError] = useState("");
  const [expComplete, setExpComplete] = useState(false);
  const [cvcError, setCvcError] = useState("");
  const [cvcComplete, setCvcComplete] = useState(false);

  const {
    coupon,
    setCoupon,
    donationAmount,
    gift,
    isGift,
    donationPlan,
    setFallbackGift,
  } = props;

  const applyCouponCode = useCallback(async () => {
    try {
      const response = await Axios.post(
        process.env.REACT_APP_API_ENDPOINT + "/checkout/coupon",
        {
          coupon: coupon.value,
          plan: `${donationPlan}${isGift ? "-gift" : ""}`,
          freeTrial: !!props.freeTrial,
        }
      );
      let formattedCouponText =
        response.data.discountType === "fixed_amount"
          ? formatCurrency(
              Math.min(donationAmount, response.data.discountAmount) / 100
            )
          : response.data.discountAmount + "%";

      if (props.freeTrial) {
        formattedCouponText = "Free Trial";
      }
      setCoupon({
        ...coupon,
        formatted: formattedCouponText,
        error: false,
        applied: true,
        discountAmount: response.data.discountAmount,
        discountType: response.data.discountType,
        message:
          gift && gift.id !== ""
            ? "Gifts are not eligible to be used with a coupon. Your gift has been automatically removed. If you would like to add your gift back, please click 'REMOVE COUPON'."
            : "",
      });
      setFallbackGift(gift);
    } catch (e: any) {
      setCoupon({
        ...coupon,
        error: true,
        message:
          e?.response?.data?.error ?? e?.message ?? "An error has occurred.",
      });
    }
  }, [
    coupon,
    donationPlan,
    isGift,
    donationAmount,
    props.freeTrial,
    setCoupon,
    gift,
    setFallbackGift,
  ]);

  const initialCouponRef = useRef(false);
  useEffect(() => {
    if (
      !initialCouponRef.current &&
      coupon &&
      coupon.value &&
      !coupon.applied
    ) {
      applyCouponCode();
    }
    initialCouponRef.current = true;
  }, [coupon, applyCouponCode]);

  const { setDonationAdditionalAmount: propsSetDonationAdditionalAmount } =
    props;
  useEffect(() => {
    propsSetDonationAdditionalAmount(donationAdditionalAmount);
  }, [donationAdditionalAmount, propsSetDonationAdditionalAmount]);

  const { setIsValid: propsSetIsValid } = props;
  useEffect(() => {
    let valid = false;
    switch (paymentMethod) {
      case PaymentMethod.card:
        valid =
          cardComplete &&
          expComplete &&
          cvcComplete &&
          !cardError &&
          !expError &&
          !cvcError;
        break;
      case PaymentMethod.payPal:
        valid = true;
        break;
      case PaymentMethod.ach:
        valid =
          props.plaidToken !== null && props.plaidToken.accountId !== null;
        break;
      case PaymentMethod.freeTrial:
        valid = !!props.freeTrial && !!coupon && coupon.applied;
        break;
    }
    propsSetIsValid(valid);
  }, [
    paymentMethod,
    plaidToken,
    propsSetIsValid,
    cardComplete,
    expComplete,
    cvcComplete,
    cardError,
    expError,
    cvcError,
    props.plaidToken,
    props.freeTrial,
    coupon,
  ]);

  const { setPaymentMethod: propsSetPaymentMethod } = props;
  useEffect(() => {
    propsSetPaymentMethod(paymentMethod);
  }, [paymentMethod, propsSetPaymentMethod]);

  const onPlaidSuccess = (token, metadata) => {
    ReactGA.event({
      category: "ach",
      action: "success",
    });
    props.setPlaidToken({
      token: token,
      accountId: metadata.accounts.length ? metadata.accounts[0].id : null,
      mask: metadata.accounts.length ? metadata.accounts[0].mask : null,
    });
    setPlaidAccounts(metadata.accounts);
  };

  const plaidLink = usePlaidLinkToken(onPlaidSuccess);

  const isSelected = (pm: PaymentMethod) => {
    return paymentMethod === pm;
  };

  const renderCouponOnly = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ ml: 3 }}>
            {couponControl()}
          </Grid>
          <Grid item xs={12}>
            {props.setCaptcha && (
              <>
                <Divider sx={{ my: 0 }} />

                <Box
                  sx={{
                    mt: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Confirm you are not a robot by checking this box:
                  </Typography>

                  <ReCAPTCHA
                    onChange={props.setCaptcha}
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  />
                </Box>

                <Divider sx={{ mt: 1 }} />
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  const renderStripe = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <StripeTextField
              label={"Card number"}
              fullWidth
              required
              stripeElement={CardNumberElement}
              onChange={(e) => {
                setCardComplete(e.complete);
                e.error ? setCardError(e.error.message) : setCardError("");
              }}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <StripeTextField
              label={"Exp. Date"}
              fullWidth
              required
              stripeElement={CardExpiryElement}
              onChange={(e) => {
                setExpComplete(e.complete);
                e.error ? setExpError(e.error.message) : setExpError("");
              }}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <StripeTextField
              label={"CVC"}
              fullWidth
              required
              stripeElement={CardCvcElement}
              onChange={(e) => {
                setCvcComplete(e.complete);
                e.error ? setCvcError(e.error.message) : setCvcError("");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {additionalAmountControl()}
          </Grid>
          <Grid item xs={12}>
            {autoRenewControl()}
          </Grid>
          <Grid item xs={12}>
            {couponControl()}
          </Grid>
          <Grid item xs={12}>
            {props.setCaptcha && (
              <>
                <Divider sx={{ my: 3 }} />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Confirm you are not a robot by checking this box:
                  </Typography>

                  <ReCAPTCHA
                    onChange={props.setCaptcha}
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  />
                </Box>

                <Divider sx={{ mt: 3 }} />
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  const renderPayPal = () => {
    return (
      <>
        {additionalAmountControl()}
        {autoRenewControl()}
        <Grid item xs={12}>
          {couponControl()}
        </Grid>
        {props.loading ? (
          <div className="paypal-loading-message">
            <p>Redirecting you to PayPal...</p>
            <Spinner />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderPlaid = () => {
    if (!plaidLink.ready) {
      return (
        <Box>
          <CircularProgress />
        </Box>
      );
    }
    if (plaidLink.error) {
      return (
        <Box>
          <Typography variant="body1">Error connecting to Plaid.</Typography>
        </Box>
      );
    }
    return (
      <>
        {props.plaidToken ? (
          <>
            <Typography variant="h4" sx={{ mb: 1 }}>
              Choose an account to use:
            </Typography>
            {plaidAccounts.map((account) => (
              <Box sx={{ mb: 1 }} key={account.id}>
                <SelectBubble
                  showCheck
                  selected={props.plaidToken.accountId === account.id}
                  label={`${account.name} ****${account.mask}`}
                  onClick={() => {
                    props.setPlaidToken({
                      ...props.plaidToken,
                      accountId: account.id,
                      mask: account.mask,
                    });
                  }}
                ></SelectBubble>
              </Box>
            ))}
          </>
        ) : (
          <Box>
            <Button
              onClick={() => {
                ReactGA.event({
                  category: "ach",
                  action: "open",
                });
                plaidLink?.open();
              }}
            >
              Connect a bank account
            </Button>
          </Box>
        )}

        {autoRenewControl()}
        <Grid item xs={12}>
          {couponControl()}
        </Grid>
      </>
    );
  };

  const getFeeAmount = () => {
    return parseFloat((props.donationAmount * 0.025).toFixed(0));
  };

  const additionalAmountControl = () => {
    if (paymentMethod === PaymentMethod.ach) {
      return <></>;
    }
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={donationAdditionalAmount > 0}
            onChange={() =>
              donationAdditionalAmount === 0
                ? setDonationAdditionalAmount(getFeeAmount())
                : setDonationAdditionalAmount(0)
            }
          />
        }
        style={{ marginBottom: 0 }}
        label={
          <span style={{ textTransform: "initial" }}>
            I'd like to cover ${(getFeeAmount() / 100).toFixed(2)} in
            transaction fees so JazzGroove.org can keep 100% of my donation.
          </span>
        }
      />
    );
  };

  const autoRenewControl = () => {
    if (
      props.donationType === DonationType.oneTime &&
      props.donationAmount >= DEFAULT_DONATION_AMOUNTS[DonationType.oneTime]
    ) {
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={props.autoRenew}
              onChange={() => props.setAutoRenew(!props.autoRenew)}
            />
          }
          label={
            <span style={{ textTransform: "initial" }}>
              Yes, automatically renew my plan in one year
            </span>
          }
        />
      );
    }
    return <></>;
  };

  const couponControl = () => {
    return (
      <>
        <TextField
          label={
            <>
              {props.freeTrial &&
                !coupon.applied &&
                "Enter Free Trial Coupon Code *"}{" "}
              {props.freeTrial && coupon.applied && "Coupon Applied"}{" "}
              {!props.freeTrial && (
                <>
                  Have a coupon code?{" "}
                  <em style={{ fontWeight: "normal" }}>(optional)</em>
                </>
              )}
            </>
          }
          value={coupon.value}
          onChange={(e) => setCoupon({ value: e.target.value })}
          helperText={coupon.message ?? " "}
          error={coupon.error ?? false}
          disabled={props.freeTrial && coupon.applied}
          fullWidth
          sx={{ mt: 2 }}
          InputLabelProps={{
            color: props.freeTrial && coupon.applied ? "success" : "primary",
          }}
          InputProps={{
            sx: { pr: 1 },
            endAdornment: (
              <InputAdornment position="end">
                {coupon && coupon.applied && !props.freeTrial ? (
                  <Button
                    variant="text"
                    onClick={() => setCoupon({ value: coupon.value })}
                    sx={{ fontWeight: "bold", color: "text.error" }}
                  >
                    REMOVE COUPON
                  </Button>
                ) : (
                  !coupon.applied && (
                    <Button
                      variant="text"
                      onClick={applyCouponCode}
                      sx={{ fontWeight: "bold" }}
                    >
                      APPLY
                    </Button>
                  )
                )}
              </InputAdornment>
            ),
          }}
        />
      </>
    );
  };

  const renderPaymentMethod = () => {
    switch (paymentMethod) {
      case PaymentMethod.card:
        return renderStripe();
      case PaymentMethod.ach:
        return renderPlaid();
      case PaymentMethod.payPal:
        return renderPayPal();
      default:
        return renderStripe();
    }
  };

  const mdWidth = props.isGift ? 6 : 4;
  return (
    <>
      <Grid container spacing={2}>
        {props.freeTrial && renderCouponOnly()}
        {!props.freeTrial && (
          <>
            <Grid item xs={12} md={mdWidth}>
              <SelectBubble
                showCheck
                selected={isSelected(PaymentMethod.card)}
                label="Credit Card"
                icon={<IoCard />}
                onClick={() => setPaymentMethod(PaymentMethod.card)}
              ></SelectBubble>
            </Grid>
            <Grid item xs={12} md={mdWidth}>
              <SelectBubble
                showCheck
                selected={isSelected(PaymentMethod.payPal)}
                label="PayPal"
                icon={<IoLogoPaypal />}
                onClick={() => setPaymentMethod(PaymentMethod.payPal)}
              ></SelectBubble>
            </Grid>
            {!props.isGift ? (
              <Grid item xs={12} md={mdWidth}>
                <SelectBubble
                  showCheck
                  selected={isSelected(PaymentMethod.ach)}
                  label="Checking Account"
                  icon={<RiBankFill />}
                  onClick={() => {
                    setPaymentMethod(PaymentMethod.ach);
                    setDonationAdditionalAmount(0);
                  }}
                ></SelectBubble>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12}>
              {renderPaymentMethod()}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
