import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { PLAN_FREQUENCY_FORMATTED_MAP, PlanTierKey } from "Utils/Constants";
import { PlanFrequency } from "../../../../types/Plan";
import PlanFeaturesList from "./PlanFeaturesList";
import { getPlanTierByKey } from "Utils/Components/Plan";
import { toCamelCase } from "Utils/ChangeCase";
import { formatNumber } from "Utils/Format";
import { useAuth0 } from "react-auth0-spa";
import { PlanFeaturesListProps } from "./PlanFeaturesList";
import { Link as RouterLink } from "react-router-dom"

export interface PlanProps {
  tier: PlanTierKey;
  frequency: PlanFrequency;

  size?: PlanFeaturesListProps["size"];

  /**
   * Override the amount shown. Default is the minimum amount for the plan tier and frequency.
   */
  amount?: number;
  showButton?: boolean
  buttonProps?: any
  giftEnabled: boolean
}

const Plan = (props: PlanProps) => {
  const {
    tier,
    frequency,
    amount,
    size,
    giftEnabled,
    showButton = false,
    buttonProps = {}
  } = props;
  const { loginWithRedirect } = useAuth0()
  const plan = getPlanTierByKey(tier);
  const frequencyDonationInfo = plan[toCamelCase(frequency)];

  return (
    <Box>
      <Typography
        variant="h6"
        component="p"
        fontWeight="medium"
        sx={theme => ({
          fontFamily: theme.typography.fontFamily,
          mb: 1,
        })}
      >
        {plan.name}
      </Typography>

      <Box>
        <Typography variant="h2" component="span">
          <Box component="span" sx={{ fontWeight: "medium" }}>$</Box>
          {formatNumber(amount ?? frequencyDonationInfo.minimumAmount, 0, 2)}
        </Typography>
        <Typography component="span" sx={{ color: "text.secondary", ml: 0.5 }}>
          {PLAN_FREQUENCY_FORMATTED_MAP[frequency]}
        </Typography>
      </Box>

      <Divider sx={{ mt: 3, mb: 1 }} />

      <PlanFeaturesList tier={tier} giftEnabled={giftEnabled} size={size} />
      {showButton ? (
        <Box sx={{ textAlign: "center", mt: 1 }}>
          {
            frequencyDonationInfo.minimumAmount > 0
            ? <Button
                {...buttonProps}
                component={RouterLink}
                to={`/checkout?a=${frequencyDonationInfo.minimumAmount * 100}&f=${frequency === "annually" ? "a" : frequency === "monthly" ? "m" : "o"}`}
              >
                Get {plan.name}
              </Button>
              // What if auth already?
            : <Button
                {...buttonProps}
                onClick={() => loginWithRedirect()}
              >
                Sign Up
              </Button>
          }
        </Box>
      ) : (<></>)}
    </Box>
  );
};

export default Plan;
