import { Components, Theme } from "@mui/material/styles";
import { iconButtonClasses } from "@mui/material";

export const MUI_DEFAULT_ACTION_PADDING_TOP = 4;
export const ACTION_PADDING_TOP = 1;

const Alert: Components<Theme> = {
  MuiAlert: {
    defaultProps: {
      variant: "filled",
    },
    styleOverrides: {
      action: {
        /**
         * 1px to center buttons when the alert is only one line.
         * MUI's default is 4px because the MUI's default small button is shorter than our's.
         */
        paddingTop: ACTION_PADDING_TOP,

        [`.${iconButtonClasses.root}`]: {
          marginTop: MUI_DEFAULT_ACTION_PADDING_TOP - ACTION_PADDING_TOP,
        },
      },
    },
  },
};

export default Alert;
