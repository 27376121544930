import {
  PLAN_FEATURES_WITH_FOOTNOTES,
  PLAN_FREQUENCIES,
  PLAN_TIERS,
  PlanFeature,
  PlanTierKey,
  PlanTierRank,
  DonationType
} from "Utils/Constants";
import { PlanCode, PlanFrequencyDonationInfo, PlanTier } from "../../../types/Plan";
import { isNilOrEmpty } from "../Inspect";

export function isIncludedInPlan(feature: PlanFeature, tier: PlanTierKey, giftEnabled: boolean): boolean {
  if (feature.featureKey === "physical-gift" && !giftEnabled) return false
  return PlanTierRank[feature.lowestTier] <= PlanTierRank[tier];
}

export function footnoteNumberOf(feature: PlanFeature): number | undefined {
  const foundFeature = PLAN_FEATURES_WITH_FOOTNOTES.find(x => x === feature);
  return foundFeature ? foundFeature.number : undefined;
}

export function getPlanTierByKey(tierKey: PlanTierKey): PlanTier {
  return PLAN_TIERS[PlanTierRank[tierKey]];
}

export function getPlanTierByCode(code?: PlanCode | null): PlanTier {
  if (!code) return getPlanTierByKey("free");

  return PLAN_TIERS.find(tier => {
    return tier.legacyCodes?.[code]
      ? true
      : !!PLAN_FREQUENCIES.find(frequency => tier[frequency]?.code === code);
  }) as PlanTier;
}

export function getPlanName(code?: PlanCode | null | ""): string {
  if (code) {
    for (let i = 0; i < PLAN_TIERS.length; i++) {
      const tier = PLAN_TIERS[i];
      if (tier.key === "free") continue;

      for (let j = 0; j < PLAN_FREQUENCIES.length; j++) {
        const frequency = PLAN_FREQUENCIES[j];
        const info: PlanFrequencyDonationInfo = tier[frequency];
        if (info && info.code === code) return tier.name;
      }

      if (!isNilOrEmpty(tier.legacyCodes) && tier.legacyCodes[code]) {
        return tier.legacyCodes[code];
      }
    }
  }

  return getPlanTierByKey("free").name;
}

/**
 * Check if the given tier is the highest plan tier available.
 */
export function isHighestPlanTier(tierKey: PlanTierKey): boolean {
  return PlanTierRank[tierKey] === (PLAN_TIERS.length - 1);
}

export function getDonationPlanCode(donationAmount: number, donationType: DonationType) {
  let highestTier = { code: null as PlanCode | null, amount: 0}
  for (const tier of PLAN_TIERS.filter(x => x.active)) {
      const tierInfo =  donationType === DonationType.monthly ? tier.monthly : tier.oneTime
      const tierAmount = tierInfo.minimumAmount
      if (donationAmount / 100 >= tierAmount && tierAmount >= highestTier.amount) {
          highestTier = {
              code: tierInfo.code,
              amount: tierAmount
          }
      }
  }
  return highestTier.code
}
