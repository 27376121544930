import { DonationType } from "Utils/Constants";
import { getPlanTierByKey } from "Utils/Components/Plan";

const premiumPlusPlanTier = getPlanTierByKey("premium-plus");
export const DEFAULT_DONATION_AMOUNTS = {
  [DonationType.monthly]: premiumPlusPlanTier.monthly.minimumAmount * 100,
  [DonationType.annual]: premiumPlusPlanTier.annually.minimumAmount * 100,
  [DonationType.oneTime]: premiumPlusPlanTier.oneTime.minimumAmount * 100,
};

const annualCostOnMonthlyPlan = premiumPlusPlanTier.monthly.minimumAmount * 12;
const dollarSavings =
  annualCostOnMonthlyPlan - premiumPlusPlanTier.annually.minimumAmount;
export const ANNUAL_SAVINGS_PERCENT = Math.ceil(
  (dollarSavings / annualCostOnMonthlyPlan) * 100
);

export const DEV_STREAMS_URL = {
  mix1: "https://streams.radiomast.io/station/52bf5882-33f2-483a-8d3c-6d139898a537/player/widget?s=baadc988-4d0b-4ba6-98e6-7edf3566fbd6",
  mix2: "https://streams.radiomast.io/station/52bf5882-33f2-483a-8d3c-6d139898a537/player/widget?s=1ea90634-e56c-4218-b2b2-d9df3589a611",
};
