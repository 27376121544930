import React from "react";
import { Link } from "react-router-dom";
import { Alert, AlertProps, Button, alertClasses, Typography } from "@mui/material";
import { normalizeSx } from "Utils/Components/normalizeSx";

export const HelpfulTips = ({ sx, ...rest }: AlertProps) => {
  return (
    <Alert
      {...rest}
      severity="info"
      color="primary"
      sx={[
        {
          alignItems: "center",
          [`& .${alertClasses.message}`]: {
            textAlign: "left",
          },
        },
        ...normalizeSx(sx),
      ]}
      action={
        <Button
          component={Link}
          variant="outlined"
          color="inherit"
          size="small"
          to="/tips"
        >
          <Typography variant="caption" fontWeight="bold">
            Learn More
          </Typography>
        </Button>
      }
    >
      Not sure what to do now? We have helpful tips to get you started.
    </Alert>
  );
};
