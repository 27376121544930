import { css, alpha, styled, buttonClasses, Button, svgIconClasses, Box, Typography } from "@mui/material";
import headerClasses from "./classes";

const PREFIX = headerClasses.navItem;
export const classes = {
  root: PREFIX,
  link: `${PREFIX}__link`,
  text: `${PREFIX}__text`,
  menuItem: `${PREFIX}__menu-item`,

  linkMenuOpen: `${PREFIX}__link--menu-open`,
};

export const ListItem = styled(Box)(({ theme }) => css`
  display: flex;
  align-items: center;
  transition: ${theme.transitions.create("color", { duration: "0.1s" })};

  &.${classes.menuItem} {
    padding-top: ${theme.spacing(1)};
    padding-bottom: ${theme.spacing(1)};
  }

  &:not(.${classes.menuItem}) {
    margin: ${theme.spacing(0, 1)};
    color: ${theme.palette.text.disabled};

    .${headerClasses.modeLight} & {
      color: ${theme.palette.text.secondary};
      color: ${alpha(theme.palette.text.primary, 0.85)};
    }

    & .${classes.text} {
      text-shadow: var(--jg-header-text-shadow);
    }

    & .${classes.link} {
      color: inherit;
    }
  }

  & .${classes.link} {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 100%;

    text-decoration: none;
    transition: ${theme.transitions.create(["color"])};

    &:hover {
      color: ${alpha(theme.palette.text.primary, 0.6)};
    }

    &,
    &.${classes.linkMenuOpen},
    &.active {
      color: ${theme.palette.text.primary};
    }
  }
`) as any;

export const ItemButton = styled(Button)(({ theme }) => css`
  height: 100%;

  &:hover {
    background: none;
  }

  & > .${classes.text} {
    font-weight: ${theme.typography.fontWeightBold};
    text-transform: uppercase;
  }

  & .${buttonClasses.endIcon} {
    margin-left: ${theme.spacing(0.5)};

    & .${svgIconClasses.root} {
      font-size: 0.75rem;
    }
  }
`);

export const ItemButtonText = styled(Typography)(({ theme }) => css`
  position: relative;
  width: 100%;
  text-align: left;

  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    width: 12px;
    height: 2px;
    border-radius: 50px;
    background-color: ${theme.palette.primary.main};
    opacity: 0;
    transform: scale(0) translateX(-50%);
    transition: ${theme.transitions.create(["width", "opacity", "transform"])};

    .${classes.link}.active & {
      opacity: 1;
      width: 100%;
      transform: scale(1) translateX(-50%);
    }

    .${classes.root}:hover & {
      opacity: 1;
      transform: scale(1) translateX(-50%);
    }
  }
`) as typeof Typography;
