import { AppBar, css, Stack, styled, Toolbar } from "@mui/material";
import { Link } from "react-router-dom";
import classes from "./classes";

// Exported here to maintain the pattern.
export { default as classes } from "./classes";

export const SAppBar = styled(AppBar)`
  background: none;
  /**
   * This is Theme.shadows[3] with its y-offset multiplied by -1.
   */
  box-shadow: 0px -3px 3px -2px rgb(0 0 0 / 20%),
              0px -3px 4px 0px rgb(0 0 0 / 14%),
              0px -1px 8px 0px rgb(0 0 0 / 12%);
` as typeof AppBar;

export const SToolbar = styled(Toolbar)(({ theme }) => {
  return css`
    justify-content: space-between;
    transition: ${theme.transitions.create(["height"])};
    background: ${theme.palette.background.paper};

    & .${classes.button} {
      white-space: nowrap;
      box-shadow: var(--jg-header-item-shadow);

      &:hover,
      &:active {
        box-shadow: var(--jg-header-item-shadow);
      }
    }
  `;
}) as typeof Toolbar;

export const Nav = styled(Stack)`
  align-items: stretch;
  height: 100%;
` as typeof Stack;

export const LogoContainer = styled(Link)(({ theme }) => css`
  display: block;
  align-self: center;
  height: calc(100% - 28px);
  min-width: 60px;
  max-width: 128px;
  margin-right: ${theme.spacing(1)};

  & svg {
    filter: drop-shadow(var(--jg-header-logo-shadow));
  }
`) as typeof Link;

export const NavMenu = styled("ul")(({ theme }) => css`
  display: flex;
  align-items: stretch;
  margin: 0;
  padding: 0;
  list-style: none;

  .${classes.root} & {
    padding: ${theme.spacing(2.5, 0)};
  }
`);
