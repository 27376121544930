/**
 * Add the `mui-fixed` class to chatlio to account for fixed-positioned elements slightly shifting when
 * opening a non-scrollable backdrop (the default behavior for modals and menus). See the
 * [MUI documentation](https://mui.com/getting-started/faq/#why-do-the-fixed-positioned-elements-move-when-a-modal-is-opened)
 * for more information.
 */
function updateChatlioWidget() {
  const widgetContainer = document.getElementById("chatlio-widget-container");
  if (widgetContainer) {
    // We use `setTimeout` so that the class is added after the widget has time to update its own classes.
    window.setTimeout(() => {
      widgetContainer.classList.add("mui-fixed");
    });
  }
}

document.addEventListener("chatlio.ready", updateChatlioWidget);
document.addEventListener("chatlio.collapsed", updateChatlioWidget);
document.addEventListener("chatlio.expanded", updateChatlioWidget);
