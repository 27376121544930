import React from "react";
import { context } from "../../Context";
import { Helmet } from "react-helmet";

const Title = props => {
  const value = (props.value || props.children) ? " - " + (props.value || props.children) : "";
  return (
    <context.Consumer>
      {context => {
        return (
          context.paused && (
            <Helmet>
              <title>Jazz Groove{value}</title>
            </Helmet>
          )
        );
      }}
    </context.Consumer>
  );
};

export default Title;
