import { Avatar, Badge, badgeClasses, Button, css, MenuItem, styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import headerClasses from "./classes";
import JGIcon from "Pages/Components/JGIcon";
import JGSignInButton from "Pages/Components/SignInButton";

const PREFIX = headerClasses.authActions
export const classes = {
  root: PREFIX,
  accountButton: `${PREFIX}__account-button`,
  startIcon: `${PREFIX}__start-icon`,
};

export const SignInButton = styled((props: any) => (
  <JGSignInButton ButtonComponent={LoadingButton} {...props} />
))(({ theme }) => css`
  background-color: ${theme.palette.background.default};
  border: ${theme.shape.borderWidthAccent}px solid ${theme.palette.primary.main};

  &:hover {
    background-color: ${theme.palette.background.paper};
    border-width: ${theme.shape.borderWidthAccent}px;
  }
`) as typeof LoadingButton;

export const AccountButton = styled(Button)(({ theme }) => css`
  display: flex;
  align-items: center;
  min-width: 0;
  padding: 0;

  ${theme.breakpoints.up("md")} {
    padding: ${theme.spacing(0.5)};
  }
`) as typeof Button;

export const AvatarBadge = styled(Badge)(({ theme }) => css`
  & .${badgeClasses.badge} {
    right: 4px;
    top: 4px;
  }
`) as typeof Badge;

export const PlanExpirationMenuItem = styled(MenuItem)(({ theme }) => {
  const palette = theme.palette.primary;

  return css`
    margin: ${theme.spacing(1, 0, 0.5)};
    color: ${palette.contrastText};
    background-color: ${palette.main};

    &:hover {
      background-color: ${palette.dark};
    }
  `;
}) as typeof MenuItem;

export const UserAvatar = styled(Avatar)(({ theme }) => {
  const bgColor = theme.palette.background.paper;

  return css`
    width: 36px;
    height: 36px;
    color: ${theme.palette.getContrastText(bgColor)};
    border: ${theme.shape.borderWidthAccent}px solid ${theme.palette.action.disabled};
    background-color: ${bgColor};
    opacity: 1;
    transition: ${theme.transitions.create("opacity")};

    .${classes.root}:hover & {
      opacity: 0.8;
    }

    ${theme.breakpoints.up("md")} {
      width: 36px;
      height: 36px;
    }
  `;
}) as typeof Avatar;

export const StartIcon = styled(JGIcon)(({ theme }) => css`
  margin: ${theme.spacing(0, 1, 0, 0.5)};
  font-size: 0.75rem;
`);
