import React from "react";
import { Drawer } from "@mui/material";
import { HeaderState } from "Providers/Header";
import HEADER_NAV_ITEMS from "./HeaderNavItems";
import HeaderNavItem from "./HeaderNavItem";
import * as S from "./Header.styles";

type HeaderDrawerProps = HeaderState & {
  open: boolean;
  setOpen: (isDrawerOpen: boolean) => void;
};

const drawerWidth = 300;
const HeaderDrawer = (props: HeaderDrawerProps) => {
  const {
    open,
    setOpen,
  } = props;

  // Flatten the nav items because we show them all on the same level.
  const navItems = HEADER_NAV_ITEMS.map(item => item.items ? item.items : item).flat();

  return (
    <Drawer
      className={S.classes.drawer}
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
      onClick={() => setOpen(false)}
      sx={theme => ({
        zIndex: theme.zIndex.appBar - 1,
      })}
      PaperProps={{
        sx: {
          width: drawerWidth,
          maxWidth: "100%",
        }
      }}
    >
      <S.NavMenu
        className={S.classes.nav}
        sx={{
          flexDirection: "column",
          py: 2,
          mt: `var(--jg-header-height, 0)`,
          mb: `var(--jg-player-height, 0)`,
        }}
      >
        {navItems.map(item => <HeaderNavItem {...item} sx={{ py: 0.5 }} />)}
      </S.NavMenu>
    </Drawer>
  );
};

export default HeaderDrawer;
