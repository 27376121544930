export const FOOTER_NAV_ITEMS = [{
    title: "Company",
    items: [{
        url: "",
        to: "/about",
        text: "About",
    }, {
        url: "",
        to: "/contact",
        text: "Contact",
    }, {
        url: "",
        to: "/blog",
        text: "Blog",
    }, {
        url: "",
        to: "/help",
        text: "Help Center",
    }]
}, {
    title: "Support Us",
    items: [{
        url: "",
        to: "/donate",
        target: "_blank",
        text: "Donate",
    }, {
        url: "https://careasy.org/nonprofit/foundationtoadvancejazzjazzgroove",
        to: "",
        text: "Donate a Car",
    }]
}, {
    title: "Other Links",
    items: [{
        url: "",
        to: "/premium",
        text: "Premium Plus",
    }, {
        url: "",
        to: "/jazz-tonight",
        text: "Jazz Tonight",
    }]
}]
