import {
  Alert,
  alertClasses,
  alpha,
  Box,
  Button,
  css,
  LinearProgress,
  linearProgressClasses,
  styled
} from "@mui/material";

const PREFIX = "jg-announcement-bar";
export const classes = {
  root: PREFIX,
  variantStandard: `${PREFIX}--variant-standard`,
  variantFundDrive: `${PREFIX}--variant-fund-drive`,

  content: `${PREFIX}__content`,
};

export const Container = styled(Alert)(({ theme }) => {
  const bgColor = theme.palette.background.default;

  return css`
    position: relative;
    height: var(--jg-announcement-bar-height);
    width: 100%;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    color: ${theme.palette.text.primary};
    background: ${bgColor};
    border-radius: 0;

    ${theme.breakpoints.down("lg")} {
      padding-left: 0;
      padding-right: 0;
    }

    & .${alertClasses.message} {
      position: relative;
      width: 100%;
      height: inherit;
      padding: 0;
      overflow: hidden;

      ${theme.breakpoints.down("lg")} {
        display: flex;
      }
    }

    & .${alertClasses.action} {
      ${theme.breakpoints.down("lg")} {
        position: absolute;
        right: 0;
        z-index: 1;
        padding-left: ${theme.spacing(5)};
        padding-right: ${theme.spacing(2)};

        background: linear-gradient(to left,
          ${alpha(bgColor, 1)} ${theme.spacing(5)},
          ${alpha(bgColor, 0)} 100%
        );
      }
    }
  `;
}) as typeof Alert;

export const ContentContainer = styled(Box)(({ theme }) => css`
  display: flex;
  height: inherit;

  ${theme.breakpoints.down("lg")} {
    margin: 0;
    padding-left: 100%;
    animation: ticker 20s linear infinite;
  }
`);

export const Content = styled(Box)(({ theme }) => {
  return [
    theme.typography.body1,
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      white-space: nowrap;

      ${theme.breakpoints.down("lg")} {
        justify-content: flex-start;
      }
    `
  ];
}) as any;

export const SButton = styled(Button)`
  padding-top: 0;
  padding-bottom: 0;
` as typeof Button;

export const FundDriveProgress = styled(LinearProgress)(({ theme }) => css`
  margin: 4px auto;
  padding: 0;
  width: 80%;
  min-width: 300px;
  max-width: 500px;
  height: 8px;
  border-radius: 6px;
  background: ${theme.palette.action.selected};

  & .${linearProgressClasses.bar} {
    border-radius: 6px;
  }
`) as typeof LinearProgress;
