
import { Grid, TextField, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import CountryInput from "../CountryInput"

export interface ShippingInfo {
    firstName: string,
    lastName: string,
    address1: string,
    address2: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    valid: boolean
}

export default function ShippingStep(props : {
    shippingInfo: ShippingInfo,
    setShippingInfo: (shippingInfo: ShippingInfo) => void
}) {
    const [firstName, setFirstName] = useState((props.shippingInfo && props.shippingInfo.firstName) || "")
    const [firstNameError, setFirstNameError] = useState("")

    const [lastName, setLastName] = useState((props.shippingInfo && props.shippingInfo.lastName) || "")
    const [lastNameError, setLastNameError] = useState("")

    const [address1, setAddress1] = useState((props.shippingInfo && props.shippingInfo.address1) || "")
    const [address1Error, setAddress1Error] = useState("")

    const [address2, setAddress2] = useState((props.shippingInfo && props.shippingInfo.address2) || "")
    const [address2Error, setAddress2Error] = useState("")

    const [city, setCity] = useState((props.shippingInfo && props.shippingInfo.city) || "")
    const [cityError, setCityError] = useState("")

    const [state, setState] = useState((props.shippingInfo && props.shippingInfo.state) || "")
    const [stateError, setStateError] = useState("")

    const [zip, setZip] = useState((props.shippingInfo && props.shippingInfo.zip) || "")
    const [zipError, setZipError] = useState("")

    const [country, setCountry] = useState((props.shippingInfo && props.shippingInfo.country) || "US")

    const form = useRef<HTMLFormElement>(null)

    const {setShippingInfo: propsSetShippingInfo} = props
    useEffect(() => {
        propsSetShippingInfo({
            firstName,
            lastName,
            address1,
            address2,
            city,
            state,
            zip,
            country,
            valid: form?.current?.checkValidity() ?? false
        })
    }, [propsSetShippingInfo, firstName, lastName, address1, address2, city, state, zip, country])

    const setForm = (el: EventTarget & (HTMLInputElement | HTMLTextAreaElement), setValue: (val: string) => void, setError: (err: string) => void) => {
        setValue(el.value)
        if (setError) {
            if (el.checkValidity() === false) {
                setError(el.validationMessage)
            } else {
                setError("")
            }
        }
    }

    return (
        <>
        <form ref={form}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">Shipping Address</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="First name"
                        value={firstName}
                        error={!!firstNameError}
                        helperText={firstNameError}
                        onBlur={e => setForm(e.target, setFirstName, setFirstNameError)}
                        onChange={e => setForm(e.target, setFirstName, setFirstNameError)}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Last name"
                        value={lastName}
                        error={!!lastNameError}
                        helperText={lastNameError}
                        onBlur={e => setForm(e.target, setLastName, setLastNameError)}
                        onChange={e => setForm(e.target, setLastName, setLastNameError)}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Street address"
                        value={address1}
                        error={!!address1Error}
                        helperText={address1Error}
                        onBlur={e => setForm(e.target, setAddress1, setAddress1Error)}
                        onChange={e => setForm(e.target, setAddress1, setAddress1Error)}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Address line 2"
                        value={address2}
                        error={!!address2Error}
                        helperText={address2Error}
                        onBlur={e => setForm(e.target, setAddress2, setAddress2Error)}
                        onChange={e => setForm(e.target, setAddress2, setAddress2Error)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="City"
                        value={city}
                        error={!!cityError}
                        helperText={cityError}
                        onBlur={e => setForm(e.target, setCity, setCityError)}
                        onChange={e => setForm(e.target, setCity, setCityError)}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="State / Province"
                        value={state}
                        error={!!stateError}
                        helperText={stateError}
                        onBlur={e => setForm(e.target, setState, setStateError)}
                        onChange={e => setForm(e.target, setState, setStateError)}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Zip / Postal code"
                        value={zip}
                        error={!!zipError}
                        helperText={zipError}
                        onBlur={e => setForm(e.target, setZip, setZipError)}
                        onChange={e => setForm(e.target, setZip, setZipError)}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <CountryInput
                        address={{country: country}}
                        setAddress={(a) => setCountry(a.country)}
                    />
                </Grid>
            </Grid>
        </form>
        </>
    )
}
