import React, { useContext, useEffect, useState } from "react";
import { isPremium } from "Utils/Account/Plan";
import { HIGH_QUALITY, MID_QUALITY, BITRATE_OPTIONS } from "Utils/Constants";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Link,
  Switch,
  Typography,
  Button,
  DialogContent,
  TypographyProps,
  TypographyTypeMap,
  DialogContentProps,
  BoxProps,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import PremiumAlert from "Utils/PlayerAlert";
import { normalizeSx } from "Utils/Components/normalizeSx";
import { useAuth0 } from "react-auth0-spa";
import { useAppContext } from "Context";
import { StationContext } from "StationsContext/Context";

const SectionLabel = <T extends {}>(
  props: TypographyProps<TypographyTypeMap["defaultComponent"], T>
) => <Typography variant="body1" fontWeight="bold" {...props} />;

const GearSection = (
  props: DialogContentProps & {
    label?: React.ReactNode;
    ContentProps?: BoxProps;
  }
) => {
  const { label, ContentProps = {}, children, sx, ...rest } = props;
  const { sx: contentPropsSx, ...restContentProps } = ContentProps;

  return (
    <DialogContent
      sx={[
        {
          display: "flex",
          justifyContent: "space-between",
          pt: 2,
          px: 2,

          [`&:not(:last-child)`]: {
            pb: 2,
          },
        },
        ...normalizeSx(sx),
      ]}
      {...rest}
    >
      {label && (
        <Box sx={{ flexGrow: 1, alignSelf: "center", textAlign: "left" }}>
          {label}
        </Box>
      )}

      <Box
        sx={[{ alignSelf: "center" }, ...normalizeSx(contentPropsSx)]}
        {...restContentProps}
      >
        {children}
      </Box>
    </DialogContent>
  );
};

const GearMenu = (props) => {
  const [currentQuality, setCurrentQuality] = useState(props.qualityPreference);
  const [external, setExternal] = useState(props.external);
  const [premiumAlertOpen, setPremiumAlertOpen] = useState(false);
  const { isAuthenticated, user } = useAuth0();
  const { activeChannel } = useAppContext();
  const { stations } = useContext(StationContext);
  const STATIONS = stations;

  function getStation() {
    return STATIONS[activeChannel];
  }

  useEffect(() => {
    setCurrentQuality(props.qualityPreference);
  }, [props.qualityPreference]);

  useEffect(() => {
    setExternal(props.external);
  }, [props.external]);

  function updateQuality(value) {
    if (
      (value === MID_QUALITY || value === HIGH_QUALITY) &&
      (!isAuthenticated || !isPremium(user.plan))
    ) {
      setPremiumAlertOpen(true);
    } else {
      setCurrentQuality(value);
    }
  }

  function onClose() {
    props.onClose({
      quality: currentQuality,
      external: external,
    });
  }

  function closeWithoutSaving() {
    props.onClose({
      quality: props.quality,
      external: props.external,
    });
  }

  return (
    <>
      <PremiumAlert
        popup={props.popup}
        open={premiumAlertOpen}
        onClose={(getPremium) => {
          setPremiumAlertOpen(false);
          if (getPremium) {
            closeWithoutSaving();
          }
        }}
      />

      {!isPremium(user.plan) && (
        <Box
          sx={(theme) => ({
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            p: 1,
            textAlign: "center",
          })}
        >
          <Typography
            variant="body2"
            fontWeight="medium"
            sx={(theme) => ({ textShadow: theme.textShadows[1] })}
          >
            Upgrade to Premium Plus for higher quality audio.
          </Typography>
        </Box>
      )}

      <GearSection
        dividers
        label={
          <SectionLabel component="label" htmlFor="quality-select">
            Audio Quality
          </SectionLabel>
        }
      >
        <FormControl fullWidth size="small">
          <Select
            id="quality-select"
            value={currentQuality}
            onChange={(e) => updateQuality(e.target.value)}
          >
            {BITRATE_OPTIONS.map((x) => (
              <MenuItem key={x.value} value={x.value}>
                {x.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </GearSection>

      {(getStation().id === "mix1" || getStation().id === "mix2") && (
        <GearSection
          label={
            <>
              <Box sx={{ maxWidth: 300 }}>
                <SectionLabel component="label" htmlFor="external-switch">
                  Use External Player
                  <Typography
                    variant="caption"
                    component="span"
                    sx={{ pl: 1, color: "text.disabled" }}
                  >
                    Mix 1 and 2 only
                  </Typography>
                </SectionLabel>
                <Typography variant="body2">
                  Press the play button to download a m3u file to import into
                  your player of choice (iTunes, VLC, Winamp, etc.).
                </Typography>
              </Box>
            </>
          }
        >
          <Switch
            id="external-switch"
            checked={external}
            onChange={(e) => setExternal(e.target.checked)}
          />
        </GearSection>
      )}

      <GearSection dividers ContentProps={{ sx: { flex: "auto" } }}>
        <SectionLabel>More Listening Options</SectionLabel>
        <Typography variant="body2">
          See our
          <Link component={RouterLink} to="/help" onClick={closeWithoutSaving}>
            FAQ
          </Link>
          for more ways to listen.
        </Typography>
      </GearSection>

      <GearSection sx={{ textAlign: "center" }}>
        <Button sx={{ px: 4 }} onClick={onClose}>
          Apply
        </Button>
        <Typography
          variant="caption"
          component="p"
          sx={{ mt: 1, color: "text.disabled" }}
        >
          Quality preferences are applied on the next track and may be
          downgraded during periods of slow connectivity.
        </Typography>
      </GearSection>
    </>
  );
};

export default GearMenu;
