import { currentPlaylist } from "../AudioPlayer";

const EVENTS = ["start", "stop", "play", "pause", "skip"];

export default function sendLog(event, metadata) {
  if (EVENTS.indexOf(event) !== -1) {
    navigator.sendBeacon(
      process.env.REACT_APP_ANALYTICS,
      JSON.stringify({
        playlistId: currentPlaylist,
        sessionId: localStorage.getItem("session"),
        event: event,
        metadata: metadata,
        debug: process.env.REACT_APP_NODE_ENV === "dev"
      })
    );
    return true;
  } else {
    return false;
  }
}
