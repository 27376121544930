const RecentlyPlayed: RecentlyPlayedSong[] = [
  {
    title: "Chitlins Con Carne",
    img:
      "https://assets.jazzgroove.org/album-art/Kenny-Burrell-Chitlins-Con-Carne.jpg",
    artist: "Kenny Burrell",
    when: new Date(),
    station: "mix1",
    album: "Midnight Blue"
  },
  {
    title: "Stairway To The Stars",
    img:
      "https://assets.jazzgroove.org/album-art/Dexter-Gordon-Stairway-To-The-Stars.jpg",
    artist: "Dexter Gordon",
    when: new Date(),
    station: "mix1",
    album: "Our Man In Paris"
  },
  {
    title: "Cool Struttin",
    img:
      "https://assets.jazzgroove.org/album-art/Sonny-Clark-Cool-Struttin.jpg",
    artist: "Sonny Clark",
    when: new Date(),
    station: "mix1",
    album: "Cool Struttin'"
  },
  {
    title: "Blues To Elvin",
    img:
      "https://assets.jazzgroove.org/album-art/John-Coltrane-Blues-To-Elvin.jpg",
    artist: "John Coltrane",
    when: new Date(),
    station: "mix1",
    album: "Coltrane Plays the Blues"
  },
  {
    title: "Mood Indigo",
    img:
      "https://assets.jazzgroove.org/album-art/Duke-Ellington-Mood-Indigo.jpg",
    artist: "Duke Ellington",
    when: new Date(),
    station: "mix1",
    album: ""
  },
  {
    title: "Timelessness",
    img:
      "https://assets.jazzgroove.org/album-art/Wynton-Marsalis-Timeless.jpg",
    artist: "Wynton Marsalis",
    when: new Date(),
    station: "mix1",
    album: "Bolden"
  }
]

export default RecentlyPlayed;