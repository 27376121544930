/**
 * Gets the `toStringTag` of `value`.
 */
function getTag(value: any): string {
  if (value == null) {
    return value === undefined ? "[object Undefined]" : "[object Null]";
  }

  return Object.prototype.toString.call(value);
}

/**
 * Check if `value` is object-like and matches the given tag.
 *
 * @example
 *
 * isTag("Foo", "String")
 * // => true
 *
 * isTag(new Date(), "Date")
 * // => true
 *
 * isTag(true, "Number")
 * // => false
 */
function isTag(value: any, tagName: string): boolean {
  return isObjectLike(value) && getTag(value) === `[object ${tagName}]`;
}

/**
 * Checks if `value` is `null` or `undefined`.
 *
 * @example
 *
 * isNil(null)
 * // => true
 *
 * isNil(void 0)
 * // => true
 *
 * isNil(NaN)
 * // => false
 */
export function isNil(value: any): value is null | undefined {
  return value == null;
}

/**
 * Checks if `value` is `null`, `undefined`, an empty string, or an empty array.
 */
export function isNilOrEmpty(value: any): value is null | undefined | "" | [] {
  return isNil(value) || value === "" || (isArray(value) && value.length === 0);
}

export function isString(value: any): value is string {
  return typeof value === "string" || isTag(value, "String");
}

export function isNumber(value: any): value is number {
  return typeof value === "number" || isTag(value, "Number");
}

export function isBoolean(value: any): value is boolean {
  return typeof value === "boolean" || isTag(value, "Boolean");
}

/**
 * Checks if `value` is the
 * [language type](http://www.ecma-international.org/ecma-262/7.0/#sec-ecmascript-language-types)
 * of `Object`. (e.g. arrays, functions, objects, regexes, `new Number(0)`, and `new String("")`)
 *
 * @example
 *
 * isObject({})
 * // => true
 *
 * isObject([1, 2, 3])
 * // => true
 *
 * isObject(Function)
 * // => true
 *
 * isObject(null)
 * // => false
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function isObject(value: any): value is Record<string, unknown> | Array<unknown> | Function {
  const type = typeof value;
  return value != null && (type === "object" || type === "function");
}

/**
 * Checks if `value` is object-like. A value is object-like if it"s not `null` and has a `typeof` result of "object".
 *
 * @example
 *
 * isObjectLike({})
 * // => true
 *
 * isObjectLike([1, 2, 3])
 * // => true
 *
 * isObjectLike(Function)
 * // => false
 *
 * isObjectLike(null)
 * // => false
 */
export function isObjectLike(value: any): boolean {
  return value !== null && typeof value === "object";
}

/**
 * Checks if `value` is a plain object, that is, an object created by the
 * `Object` constructor or one with a `[[Prototype]]` of `null`.
 *
 * @example
 *
 * function Foo() {
 *   this.a = 1
 * }
 *
 * isPlainObject(new Foo)
 * // => false
 *
 * isPlainObject([1, 2, 3])
 * // => false
 *
 * isPlainObject({ "x": 0, "y": 0 })
 * // => true
 *
 * isPlainObject(Object.create(null))
 * // => true
 */
export function isPlainObject(value: any): value is Record<string, unknown> {
  if (!isTag(value, "Object")) return false;
if (Object.getPrototypeOf(value) === null) return true;

let prototype = value;
while (Object.getPrototypeOf(prototype) !== null) {
  prototype = Object.getPrototypeOf(prototype);
}

return Object.getPrototypeOf(value) === prototype;
}

export function isArray(value: any): value is Array<unknown> {
  return Array.isArray(value);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(value: any): value is Function {
  return typeof value === "function";
}
