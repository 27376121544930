import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";

import { useCallback, useEffect, useRef, useState } from "react";
import { useAuth0 } from "react-auth0-spa";
import { useHistory } from "react-router-dom";
import { GiftInfo } from "./GiftRecipientStep";
import UserApi from "Api/UserApi";
import SignInButton from "Pages/Components/SignInButton";
import Spinner from "Pages/Components/Spinner";

export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  signUp: boolean;
  password: string;
  confirmPassword: string;
  valid: boolean;
  emailExists: boolean;
  authenticated: boolean;
  auth0Id?: string;
}

export default function UserInfoStep(props: {
  userInfo: UserInfo;
  giftInfo: GiftInfo;
  setUserInfo: (userInfo: UserInfo) => void;
  requireSignUp?: boolean;
}) {
  const { isAuthenticated, loading: auth0Loading, user } = useAuth0();
  const isGift = props.giftInfo && props.giftInfo.isGift;
  const [firstName, setFirstName] = useState(
    (props.userInfo && props.userInfo.firstName) || ""
  );
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState(
    (props.userInfo && props.userInfo.lastName) || ""
  );
  const [lastNameError, setLastNameError] = useState("");
  const [email, setEmail] = useState(
    (props.userInfo && props.userInfo.email) || ""
  );
  const [emailError, setEmailError] = useState("");
  const [newUserExists, setNewUserExists] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [verifyingEmail, setVerifyingEmail] = useState(false);
  const [signUp, setSignUp] = useState(
    props.userInfo?.signUp ?? (!isAuthenticated && !isGift)
  );
  const [password, setPassword] = useState(
    (props.userInfo && props.userInfo.password) || ""
  );
  const [passwordError, setPasswordError] = useState(
    "Please fill out this field" +
      (!props.requireSignUp ? " OR uncheck the checkbox above" : "")
  );
  const [confirmPassword, setConfirmPassword] = useState(
    (props.userInfo && props.userInfo.confirmPassword) || ""
  );
  const [confirmPasswordError, setConfirmPasswordError] = useState(
    "Please fill out this field" +
      (!props.requireSignUp ? " OR uncheck the checkbox above" : "")
  );
  const [auth0Id, setAuth0Id] = useState("");
  const history = useHistory();
  const form = useRef<HTMLFormElement>(null);
  const passwordInput = useRef<HTMLInputElement>(null);

  const passwordsMatchError = "Passwords must match";

  const { setUserInfo: propsSetUserInfo } = props;

  useEffect(() => {
    if (signUp) {
      if (passwordError === "Please fill out this field.") {
        setPasswordError(
          "Please fill out this field" +
            (!props.requireSignUp ? " OR uncheck the checkbox above" : "")
        );
      } else if (confirmPasswordError === "Please fill out this field.") {
        setConfirmPasswordError(
          "Please fill out this field" +
            (!props.requireSignUp ? " OR uncheck the checkbox above" : "")
        );
      }
      if (
        (!passwordError || passwordError === passwordsMatchError) &&
        !confirmPasswordError
      ) {
        if (password !== confirmPassword) {
          passwordInput?.current?.setCustomValidity(passwordsMatchError);
          setPasswordError(passwordsMatchError);
        } else {
          passwordInput?.current?.setCustomValidity("");
          setPasswordError("");
        }
      }
    } else {
      passwordInput?.current?.setCustomValidity("");
      setPasswordError("");
    }

    propsSetUserInfo({
      firstName,
      lastName,
      email,
      signUp,
      password,
      confirmPassword,
      valid: (form?.current?.checkValidity() && !emailError) ?? false,
      emailExists: !!(emailError && emailError.includes("sign-in")),
      authenticated: isAuthenticated,
      auth0Id: auth0Id,
    });
  }, [
    firstName,
    lastName,
    email,
    signUp,
    password,
    confirmPassword,
    passwordError,
    emailError,
    confirmPasswordError,
    auth0Id,
    isAuthenticated,
    propsSetUserInfo,
    props.requireSignUp,
  ]);

  useEffect(() => {
    if (!auth0Loading) {
      setFirstName(user.given_name ?? "");
      setLastName(user.family_name ?? "");
      setEmail(user.email ?? "");
      setSignUp(!isAuthenticated && !isGift);
      setAuth0Id(user.sub ?? "");

      if (props.requireSignUp && isAuthenticated) {
        history.push("/checkout");
      }
    }
  }, [
    auth0Loading,
    user.given_name,
    user.family_name,
    user.email,
    user.sub,
    isAuthenticated,
    history,
    props.requireSignUp,
    isGift,
  ]);

  useEffect(() => {
    setSignUp(props.userInfo?.signUp);
  }, [props.userInfo.signUp]);

  const checkIfUserExists = useCallback(
    async (forcedEmail?) => {
      if (email || forcedEmail) {
        setNewUserExists(false);
        setEmailVerified(false);
        setVerifyingEmail(true);
      }

      if (
        !isGift &&
        !isAuthenticated &&
        (email || forcedEmail) &&
        (!emailError || emailError.includes("already used"))
      ) {
        try {
          let apiEmail = email ? email : forcedEmail;
          const dbUser = await UserApi.getDBUser(apiEmail, {
            sync: false,
          });

          if (dbUser) {
            console.log("User exists");
            setNewUserExists(true);
            setEmailVerified(true);
            setVerifyingEmail(false);
            setEmailError(
              "It seems that this email is already used. Please sign-in to continue."
            );
          }
        } catch (e) {
          // proceeding with regular donation flow
          setEmailVerified(true);
          setVerifyingEmail(false);
          console.log("User does not exist");
        }
      }

      if (isGift || isAuthenticated) {
        setEmailVerified(true);
        setVerifyingEmail(false);
      }
    },
    [email, emailError, isGift, isAuthenticated]
  );

  const setForm = async (
    el: EventTarget & (HTMLInputElement | HTMLTextAreaElement),
    setValue: (value: string) => void,
    setError: (value: string) => void
  ) => {
    setValue(el.value);
    if (setError) {
      if (el.checkValidity() === false) {
        setError(el.validationMessage);
      } else {
        if (el.type === "email") {
          checkIfUserExists(el.value);
        }
        setError("");
      }
    }
  };

  const checkedToggle = () => {
    setPasswordError(
      "Please fill out this field" +
        (!props.requireSignUp ? " OR uncheck the checkbox above" : "")
    );
    setSignUp(!signUp);
  };
  return (
    <>
      <form ref={form}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label={"First name"}
              value={firstName}
              error={!!firstNameError}
              helperText={firstNameError}
              onBlur={(e) => setForm(e.target, setFirstName, setFirstNameError)}
              onChange={(e) =>
                setForm(e.target, setFirstName, setFirstNameError)
              }
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={"Last name"}
              value={lastName}
              error={!!lastNameError}
              helperText={lastNameError}
              onBlur={(e) => setForm(e.target, setLastName, setLastNameError)}
              onChange={(e) => setForm(e.target, setLastName, setLastNameError)}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={"Email"}
              value={email}
              error={!!emailError}
              helperText={emailError}
              onBlur={async (e) => {
                setForm(e.target, setEmail, setEmailError);
                checkIfUserExists();
              }}
              onFocus={async (e) => {
                setForm(e.target, setEmail, setEmailError);
              }}
              onChange={async (e) => {
                setForm(e.target, setEmail, setEmailError);
                checkIfUserExists();
              }}
              InputProps={{
                sx: { pr: 1 },
                endAdornment: !emailVerified && (
                  <InputAdornment position="end">
                    {verifyingEmail ? (
                      <Spinner style={{ marginTop: "10px" }} />
                    ) : (
                      !emailVerified && (
                        <></>
                        // removing Verify button for now - can re-insert if onChange and onBlur dont cover all scenarios
                        // <Button
                        //   variant="text"
                        //   onClick={() => checkIfUserExists()}
                        //   sx={{ fontWeight: "bold" }}
                        // >
                        //   VERIFY
                        // </Button>
                      )
                    )}
                  </InputAdornment>
                ),
              }}
              type="email"
              fullWidth
              required
            />
          </Grid>
          {emailError && emailError.includes("sign-in") && (
            <Grid item xs={12}>
              <SignInButton redirectTo={"/checkout"} fullWidth sx={{ flex: 1 }}>
                Sign In
              </SignInButton>
            </Grid>
          )}

          {!props.userInfo.authenticated &&
          ((emailVerified && !newUserExists) || !emailVerified) ? (
            <>
              <Grid item xs={12} hidden={props.requireSignUp}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={signUp}
                      onChange={(e) => checkedToggle()}
                    />
                  }
                  componentsProps={{
                    // @ts-ignore
                    label: {
                      style: { marginBottom: 0 },
                    },
                  }}
                  label={
                    <>
                      <span style={{ textTransform: "initial" }}>
                        <span>
                          I&apos;d like to create an account to save my donation
                          and unlock all perks.
                        </span>
                        <br />
                        <span style={{ fontSize: "0.9em" }}>
                          Account will be created upon successful donation.
                        </span>
                      </span>
                    </>
                  }
                />
              </Grid>
              <Grid item xs={12} style={{ display: signUp ? "flex" : "none" }}>
                <Card style={{ boxShadow: "none" }}>
                  <Grid container spacing={2} style={{ padding: "15px" }}>
                    <Grid item xs={12}>
                      <div>
                        Your username will be the email address entered above.
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={"Choose a Password"}
                        inputRef={passwordInput}
                        value={password}
                        error={!!passwordError}
                        helperText={passwordError}
                        onBlur={(e) =>
                          setForm(e.target, setPassword, setPasswordError)
                        }
                        onChange={(e) =>
                          setForm(e.target, setPassword, setPasswordError)
                        }
                        fullWidth
                        required={signUp}
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={"Confirm Password"}
                        value={confirmPassword}
                        error={!!confirmPasswordError}
                        helperText={confirmPasswordError}
                        onBlur={(e) =>
                          setForm(
                            e.target,
                            setConfirmPassword,
                            setConfirmPasswordError
                          )
                        }
                        onChange={(e) =>
                          setForm(
                            e.target,
                            setConfirmPassword,
                            setConfirmPasswordError
                          )
                        }
                        fullWidth
                        required={signUp}
                        type="password"
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </form>
    </>
  );
}
