import React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { Link, LinkProps, LinkTypeMap } from "@mui/material";
import { Simplify } from "type-fest";

type JGLinkProps = Simplify<Omit<
  LinkProps<LinkTypeMap["defaultComponent"], Partial<RouterLinkProps>>,
  "component"
>>;

const JGLink = (props: JGLinkProps) => {
  const {
    to,
    children,
    ...rest
  } = props;

  return (
    <Link
      {...rest}
      component={to ? RouterLink : Link}
      to={to}
    >
      {children}
    </Link>
  );
};

export default JGLink;
