import React, { useState } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import StripeInput from "Pages/Components/Checkout/Stripe/StripeInput";
import type {
  AuBankAccountElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  FpxBankElement,
  IbanElement,
  IdealBankElement
} from "@stripe/react-stripe-js";
import { StripeCardNumberElementChangeEvent } from "@stripe/stripe-js";

export type StripeElement =
  | typeof AuBankAccountElement
  | typeof CardCvcElement
  | typeof CardExpiryElement
  | typeof CardNumberElement
  | typeof FpxBankElement
  | typeof IbanElement
  | typeof IdealBankElement;

type StripeTextFieldProps<T extends StripeElement> = Omit<
  TextFieldProps,
  "onChange" | "inputComponent" | "inputProps"
> & {
  inputProps?: React.ComponentProps<T>;
  onChange?: React.ComponentProps<T>["onChange"];
  stripeElement?: T;
};

const StripeTextField = <T extends StripeElement>(props: StripeTextFieldProps<T>) => {
  const [error, setError] = useState("")
  const {
    stripeElement,
    onChange,
    InputProps,
    inputProps,
    ...rest
  } = props;

  const handleChange: StripeTextFieldProps<T>["onChange"] = (e: StripeCardNumberElementChangeEvent) => {
    setError(e.error?.message ?? "")
    if (typeof onChange === "function") onChange(e as any)
  }

  return (
    <TextField
      {...rest}
      onChange={handleChange as any}
      InputProps={{
        ...InputProps,
        inputComponent: StripeInput as any,
        inputProps: {
          ...InputProps?.inputProps,
          component: stripeElement,
        },
      }}
      helperText={error || " "}
      error={!!error}
    />
  );
};

export default StripeTextField;
