import { useEffect, useRef } from "react";

/**
 * Track the previous value of a given prop or state.
 *
 * @example
 * ```ts
 * function Counter() {
 *   const [count, setCount] = useState(0);
 *   // 👇 look here
 *   const prevCount = usePrevious(count);
 *
 *   return <h1>Now: {count}, before: {prevCount}</h1>;
 * }
 * ```
 */
const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export default usePrevious;
