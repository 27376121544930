export const CONTENTFUL_ABOUT_PAGE_ENTRY_ID = "hl5UAkqBnUKWONVricvh9";
export const CONTENTFUL_LISTENING_CAP_ENTRY_ID = "49oXDWgQdSXcJzfpmmpXE4"; // Dev ID: 4bZ6EN9hAqMXu2rC1xW7HO
export const CONTENTFUL_ANNOUNCEMENT_ENTRY_ID = "2VcHCgVAr5sk50WFwrE2pH";
export const CONTENTFUL_HOW_TO_LISTEN_DISPLAY_NAME_ENTRY_ID = "5qJ7TyTaT6jt5Ecdi6YmuH";
export const CONTENTFUL_FREE_LISTENING_LIMIT_ENTRY_ID = "780ge8tqGOkn1s6PawcWLT";
export const CONTENTFUL_PREMIUM_FOOTNOTES_ENTRY_ID = "PpbGXoHtp4NQXyENYHbbV";
export const CONTENTFUL_DEFAULT_PREMIUM_TOGGLE_FREQUENCY_ENTRY_ID = "18KUsgNSKeYIKq1nMYTcKq";
export const CONTENTFUL_DONATE_DOC_ENTRY_ID = "RrO9gJJmG55P6vX5Dw65y";
export const CONTENTFUL_INITIAL_DONATION_TYPE_ID = "23rpkCIo5LUChqTZkXkcE0";
export const CONTENTFUL_FREE_TRIAL_ENTRY_ID = "1XeMTSgmKvjNn9XtrFLLfv";
