const PREFIX = "jg-player";
const classes = {
  root: PREFIX,
  content: `${PREFIX}__content`,
  song: `${PREFIX}__song`,
  controls: `${PREFIX}__controls`,
  actions: `${PREFIX}__actions`,

  drawerOpen: `${PREFIX}--drawer-open`,
  drawerClosed: `${PREFIX}--drawer-closed`,
};

export default classes;
