import { css, GlobalStyles } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { PaletteColor } from "@mui/material/styles/createPalette";
import { toastifyClasses }  from "Pages/Components/JGToastContainer";

/**
 * Styling is taken from the MUI snackbar and alert components.
 */
const ToastifyStyles = <GlobalStyles styles={theme => {
  const {
    primary,
    info, success, warning, error,
    background, text,
  } = theme.palette;

  const colorVariant: keyof PaletteColor = theme.palette.mode === "dark" ? "dark" : "main";

  return css`
    :root {
      --toastify-color-light: ${background.paper};
      --toastify-color-dark: ${text.primary};
      --toastify-color-info: ${info[colorVariant]};
      --toastify-color-success: ${success[colorVariant]};
      --toastify-color-warning: ${warning[colorVariant]};
      --toastify-color-error: ${error[colorVariant]};
      --toastify-color-transparent: ${text.secondary};
      --toastify-icon-color-info: var(--toastify-color-info);
      --toastify-icon-color-success: var(--toastify-color-success);
      --toastify-icon-color-warning: var(--toastify-color-warning);
      --toastify-icon-color-error: var(--toastify-color-error);
      --toastify-toast-width: 320px;
      --toastify-toast-background: ${background.paper};
      --toastify-toast-min-height: auto;
      --toastify-toast-max-height: 800px;
      --toastify-font-family: ${theme.typography.fontFamily};
      --toastify-z-index: ${theme.zIndex.snackbar};
      --toastify-text-color-light: ${text.primary};
      --toastify-text-color-dark: ${text.primary};
      --toastify-text-color-info: ${info.contrastText};
      --toastify-text-color-success: ${success.contrastText};
      --toastify-text-color-warning: ${warning.contrastText};
      --toastify-text-color-error: ${error.contrastText};
      --toastify-spinner-color: ${text.secondary};
      --toastify-spinner-color-empty-area: ${text.disabled};
      --toastify-color-progress-light: ${primary[colorVariant]};
      --toastify-color-progress-dark: ${primary[colorVariant]};
      --toastify-color-progress-info: var(--toastify-color-info);
      --toastify-color-progress-success: var(--toastify-color-success);
      --toastify-color-progress-warning: var(--toastify-color-warning);
      --toastify-color-progress-error: var(--toastify-color-error);

      --jg-toast-container-spacing: ${theme.spacing(1)};
      --jg-toast-gap: ${theme.spacing(1)};

      ${theme.breakpoints.up("sm")} {
        --jg-toast-container-spacing: ${theme.spacing(3)};
      }
    }

    .${toastifyClasses.container} {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }

    // Media query breakpoint is Toastify's
    @media only screen and (max-width: 480px) {
      .${toastifyClasses.container} {
        width: auto;
      }

      .${toastifyClasses.containerTopLeft},
      .${toastifyClasses.containerTopRight},
      .${toastifyClasses.containerBottomLeft},
      .${toastifyClasses.containerBottomRight} {
        left: var(--jg-toast-container-spacing);
        right: var(--jg-toast-container-spacing);
      }
    }

    .${toastifyClasses.containerTopLeft},
    .${toastifyClasses.containerTopCenter},
    .${toastifyClasses.containerTopRight} {
      top: calc(var(--jg-header-height) + var(--jg-toast-container-spacing) - var(--jg-toast-gap));
    }

    .${toastifyClasses.containerBottomLeft},
    .${toastifyClasses.containerBottomCenter},
    .${toastifyClasses.containerBottomRight} {
      bottom: calc(var(--jg-player-height) + var(--jg-toast-container-spacing) - var(--jg-toast-gap));
    }

    .${toastifyClasses.containerTopLeft},
    .${toastifyClasses.containerBottomLeft} {
      left: var(--jg-toast-container-spacing);
    }

    .${toastifyClasses.containerTopRight},
    .${toastifyClasses.containerBottomRight} {
      right: var(--jg-toast-container-spacing);
    }

    .${toastifyClasses.toast} {
      padding: 6px 16px;
      margin-bottom: var(--jg-toast-gap);
      border-radius: ${theme.shape.borderRadius}px;
    }

    .${toastifyClasses.toastBody} {
      padding: 0;
      font-size: ${theme.typography.body2.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
      line-height: ${theme.typography.body2.lineHeight};

      > *:not(.${toastifyClasses.toastIcon}) {
        padding: 8px 0;
      }
    }

    .${toastifyClasses.toastIcon} {
      display: flex;
      align-self: flex-start;
      padding: 7px 0;
      margin-right: 12px;
      font-size: 22px;
      opacity: 0.9;
    }

    .${toastifyClasses.toastCloseButton} {
      display: flex;
      align-items: flex-start;
      padding: 4px 0 0 16px;
      margin-left: auto;
      margin-right: -8px;
    }
  `;
}} />;

export default ToastifyStyles;
