/**
 * When adding custom theme options, don't forget to update the typings in `types/MUI/Theme.d.ts`
 */
import { ThemeOptions } from "@mui/material/styles";
import { alpha, PaletteMode } from "@mui/material";
import { BorderRadii, BorderWidths } from "../../../types/MUI/System";
import { TextShadows } from "../../../types/MUI/Theme";
import { stationPaletteMap } from "./Colors";
import { typography } from "./Typography";
import MuiComponents from "./MuiComponents";
import MuiDefaultTheme from "./MuiDefaultTheme";
import muiDefaultTheme from "./MuiDefaultTheme";

const breakpoints = MuiDefaultTheme.breakpoints;
const borderWidths: BorderWidths = [1, 2, 4, 8];
const borderRadii: BorderRadii = [4, 8, 12, 20, 32, 60];
const borderWidthElevation = borderWidths[3];

const textShadows: TextShadows = [
  "none",
  "0 0 0.5rem rgba(0, 0, 0, 0.3)",
  "0 0 0.5rem rgba(0, 0, 0, 0.5)",
  "0 0 1rem rgba(0, 0, 0, 0.5)",
];

const toolbar = {
  height: 100,
  mobileHeight: 80,
};

const mixins: ThemeOptions["mixins"] = {
  toolbar: {
    height: toolbar.height,
    minHeight: toolbar.height,
    [breakpoints.down("md")]: {
      height: toolbar.mobileHeight,
      minHeight: toolbar.mobileHeight,
    }
  },

  pageContent: {
    "--jg-page-content-padding-y": "64px",
    paddingTop: "var(--jg-page-content-padding-y)",
    paddingBottom: "var(--jg-page-content-padding-y)",

    [breakpoints.down("md")]: {
      "--jg-page-content-padding-y": "32px",
    },
  },
};

const BaseTheme: ThemeOptions = Object.freeze({
  breakpoints,
  palette: {
    warning: {
      main: "#EB9B00",
    },
    success: {
      main: "#00A743",
    },
    info: {
      main: "#23AFEE",
    },
    ...stationPaletteMap,
  },
  shape: {
    borderRadius: borderRadii[3],
    borderRadii,
    borderWidths,
    borderWidth: borderWidths[0],
    borderWidthAccent: borderWidths[1],
    borderWidthElevation,
  },
  typography,
  components: MuiComponents,
  mixins,
  zIndex: {
    ...muiDefaultTheme.zIndex,
    player: muiDefaultTheme.zIndex.appBar + 50,
  },
  jgComponents: {
    Announcement: {
      height: 36,
      heightFundDrive: 84,
    },
    Player: {
      height: toolbar.height,
      heightMobile: toolbar.mobileHeight,
      paddingVertical: 20 / 2,
      controls: {
        sm: {
          playButtonSize: 70,
          /**
           * The SVG background used behind the background is NOT dynamic for side buttons, so do not
           * change this value unless you also change the SVG in {@link PlayerControlsBackground}.
           */
          sideButtonSize: 38,
          spacing: 2,
          borderWidth: 5,
        },
        lg: {
          playButtonSize: 124,
          /**
           * The SVG background used behind the background is NOT dynamic for side buttons, so do not
           * change this value unless you also change the SVG in {@link PlayerControlsBackground}.
           */
          sideButtonSize: 52,
          spacing: 6,
          borderWidth: borderWidthElevation,
        },
      },
      albumArt: {
        size: 92,
        sizeExpanded: 160,
        borderRadius: 10,
      },
    },
    RecentlyPlayed: {
      chip: {
        height: 56,
      },
    },
    HighlightedFooter: {
      height: 54
    },
    Chatlio: {
      margin: 16,
      chip: {
        height: 48,
        borderWidth: borderWidths[2],
      },
    },
  },
  textShadows,
  legacy: {
    header: {
      height: {
        xs: 50,
        md: 66,
        lg: 83,
      },
    },
  },
});

const hoverOpacity = 0.05;
const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    action: {
      hover: alpha(muiDefaultTheme.palette.common.black, hoverOpacity),
      hoverOpacity,
    },
  }
}
const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: "dark",
    action: {
      hover: alpha(muiDefaultTheme.palette.common.white, hoverOpacity),
      hoverOpacity,
    },
  }
}

export const BaseModeTheme: Record<PaletteMode, ThemeOptions> = {
  light: Object.freeze(lightThemeOptions),
  dark: Object.freeze(darkThemeOptions),
}

export default BaseTheme;
