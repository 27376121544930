import moment, { MomentInput, unitOfTime } from "moment";
import { DEFAULT_DATE_FORMAT } from "Utils/Constants";

/**
 * Threshold in which relative dates become absolute dates in {@link formatRelativeDate}.
 * Date diffs below this number are displayed in a relative format, while date diffs
 * above this are displayed in an absolute format.
 */
const FORMAT_ABSOLUTE_DATE_THRESHOLD = 14;

export function daysUntil(value: moment.MomentInput): number {
  return moment(value).diff(moment(), "day");
}

/**
 * Get the formatted date relative to now.
 */
export function formatRelativeDate(
  value: moment.MomentInput,
  unit: Intl.RelativeTimeFormatUnit,
  options: Intl.RelativeTimeFormatOptions & {
    /**
     * Threshold in which relative dates become absolute dates.
     * Date diffs below this number are displayed in a relative format, while date diffs
     * above this are displayed in an absolute format.
     */
    absoluteDateThreshold?: number;
  } = {}
): string {
  if (!options.absoluteDateThreshold) options.absoluteDateThreshold = FORMAT_ABSOLUTE_DATE_THRESHOLD;
  if (!options.numeric) options.numeric = "auto"

  const date = moment(value).endOf("day");
  const daysDiff = date.diff(moment().startOf("day"), "days");
  if (daysDiff > options.absoluteDateThreshold) {
    return `on ${date.format(DEFAULT_DATE_FORMAT)}`;
  }

  const diff = date.diff(moment().startOf("day"), unit);
  const formatter = new Intl.RelativeTimeFormat("en", options);
  return formatter.format(diff, unit);
}

/**
 * Check whether two dates are within a given amount of time of each other.
 */
export function isWithinTimeOf(
  dateA: MomentInput,
  dateB: MomentInput,
  amount: number,
  unit: unitOfTime.DurationConstructor,
): boolean {
  const diff = moment(dateA).diff(moment(dateB), unit);
  return Math.abs(diff) <= amount;
};
