import React from "react";
import { Box, IconButton } from "@mui/material";
import { IoClose } from "react-icons/io5";
import { CloseButtonProps } from "react-toastify/dist/components/CloseButton";
import JGIcon from "Pages/Components/JGIcon";
import toastifyClasses from "./toastifyClasses";

const ToastCloseButton = ({ closeToast }: CloseButtonProps) => {
  return (
    <Box className={toastifyClasses.toastCloseButton}>
      <IconButton
        size="small"
        onClick={closeToast}
      >
        <JGIcon is={IoClose} fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default ToastCloseButton;
