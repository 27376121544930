import React, { SVGProps } from "react";
import { useTheme } from "@mui/material";

const PremiumQualityIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  const primary = theme.palette.primary;
  const white = theme.palette.common.white;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 165 165"
      fill="none"
      width="100%"
      height="100%"
      {...props}
    >
      <g clipPath="url(#premium-quality-icon__a)">
        <path
          d="M82.5 165c45.563 0 82.5-36.937 82.5-82.5S128.063 0 82.5 0 0 36.937 0 82.5 36.937 165 82.5 165Z"
          fill={primary.light}
        />
        <path
          d="M56.146 40.544a2.705 2.705 0 1 0 0-5.41 2.705 2.705 0 0 0 0 5.41Zm56.173 0a2.705 2.705 0 1 0 0-5.41 2.705 2.705 0 0 0 0 5.41Zm0 92.959a2.705 2.705 0 1 0 0-5.41 2.705 2.705 0 0 0 0 5.41Zm-56.173 0a2.705 2.705 0 1 0 0-5.41 2.705 2.705 0 0 0 0 5.41Zm28.099-21.277a3.686 3.686 0 1 0 0-7.371 3.686 3.686 0 0 0 0 7.371Zm0-50.381a3.686 3.686 0 1 0 0-7.372 3.686 3.686 0 0 0 0 7.372Z"
          fill={white}
        />
        <path
          d="M120.839 28.936H47.243a1.93 1.93 0 0 0-1.939 1.939v108.468a1.93 1.93 0 0 0 1.939 1.939h73.596a1.931 1.931 0 0 0 1.939-1.939V30.875a1.931 1.931 0 0 0-1.939-1.939Zm-1.938 108.444h-69.72V32.79h69.72v104.59Z"
          fill={primary.dark}
        />
        <path
          d="M84.029 83.17c-13.906 0-25.178 11.273-25.178 25.178 0 13.906 11.272 25.179 25.178 25.179s25.178-11.273 25.178-25.179c0-13.905-11.272-25.178-25.178-25.178Zm0 46.48c-11.775 0-21.301-9.55-21.301-21.302 0-11.751 9.55-21.3 21.301-21.3 11.752 0 21.301 9.549 21.301 21.3 0 11.752-9.526 21.302-21.301 21.302Z"
          fill={white}
        />
        <path
          d="M84.03 88.962c-10.7 0-19.363 8.664-19.363 19.363 0 10.698 8.664 19.362 19.362 19.362 10.699 0 19.363-8.664 19.363-19.362 0-10.699-8.664-19.363-19.363-19.363Zm0 34.872c-8.57 0-15.486-6.941-15.486-15.485 0-8.545 6.94-15.486 15.485-15.486 8.545 0 15.485 6.941 15.485 15.486 0 8.544-6.916 15.485-15.485 15.485Zm0-44.541c11.775 0 21.3-9.55 21.3-21.301 0-11.752-9.55-21.301-21.3-21.301-11.752 0-21.302 9.55-21.302 21.3 0 11.752 9.55 21.302 21.301 21.302Zm0-38.75c9.62 0 17.423 7.803 17.423 17.425 0 9.621-7.803 17.424-17.424 17.424-9.621 0-17.424-7.803-17.424-17.424 0-9.622 7.803-17.424 17.424-17.424Z"
          fill={white}
        />
        <path
          d="M84.03 73.477c8.567 0 15.484-6.94 15.484-15.485 0-8.545-6.94-15.485-15.485-15.485-8.544 0-15.485 6.94-15.485 15.485 0 8.544 6.94 15.485 15.485 15.485Zm0-27.117c6.413 0 11.63 5.194 11.63 11.632a11.614 11.614 0 0 1-11.63 11.632 11.614 11.614 0 0 1-11.633-11.632c0-6.438 5.218-11.632 11.632-11.632Z"
          fill={white}
        />
      </g>
      <defs>
        <clipPath id="premium-quality-icon__a">
          <path fill={white} transform="translate(.5)" d="M0 0h165v165H0z"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default PremiumQualityIcon;
