import { PaletteMode, ThemeOptions } from "@mui/material";
import { deepmerge } from "@mui/utils";
import BaseTheme, { BaseModeTheme } from "./BaseTheme";
import createStationThemeOptions from "./createStationThemeOptions";

export default function getThemeOptions(themeId: StationId, mode: PaletteMode): ThemeOptions {
  const baseTheme = deepmerge(BaseTheme, BaseModeTheme[mode], { clone: true });
  const stationTheme = createStationThemeOptions(themeId, mode);

  return deepmerge(baseTheme, stationTheme);
}
