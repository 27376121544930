import React, { PropsWithChildren } from "react";
import { ChipProps, PaperProps, useTheme } from "@mui/material";
import clsx from "clsx";
import * as S from "./JGCard.styles";

export type JGCardProps = PaperProps & {
  /**
   * Whether to remove the thick border around a card used to "elevate" the card.
   */
  borderless?: boolean;

  /**
   * Whether the card should be transparent
   */
  transparent?: boolean;

  /**
   * Whether highlight the card with an additional small accent border.
   */
  highlight?: boolean;

  /**
   * Color of the highlight border and the chip.
   */
  highlightColor?: ChipProps["color"],

  /**
   * If provided, will display the label in a chip at the top of the card.
   * Should almost always be used {@link highlight}.
   */
  label?: string;

  /**
   * Only applicable if {@link label} is provided.
   */
  ChipProps?: ChipProps;

  ContentProps?: PaperProps;

  borderRadius?: number;
  borderWidth?: number;
};

const DEFAULT_HIGHLIGHT_COLOR: ChipProps["color"] = "primary";

const JGCard = (props: PropsWithChildren<JGCardProps>) => {
  const {
    className,
    borderless,
    transparent,
    highlight,
    highlightColor: propsHighlightColor = DEFAULT_HIGHLIGHT_COLOR,
    label,
    borderRadius,
    borderWidth,
    ChipProps,
    ContentProps,
    children,
    style,
    ...rest
  } = props;

  const theme = useTheme();

  const highlightColor: ChipProps["color"] = (!propsHighlightColor || propsHighlightColor === "default")
    ? DEFAULT_HIGHLIGHT_COLOR
    : propsHighlightColor;

  return (
    <S.Container
      className={clsx(
        S.classes.root,
        borderless && S.classes.borderless,
        transparent && S.classes.transparent,
        highlight && S.classes.highlight,
        className,
      )}
      style={{
        "--jg-card-border-width-elevation": borderWidth ? `${borderWidth}px` : undefined,
        "--jg-card-border-radius": borderRadius ? `${borderRadius}px` : undefined,
        "--jg-card-highlight-color": theme.palette[highlightColor as any]?.main || theme.palette[DEFAULT_HIGHLIGHT_COLOR].main,
        ...style,
      } as React.CSSProperties}
      {...rest}
    >
      {label && <S.CardChip label={label} color={highlightColor} {...ChipProps} />}
      <S.Content {...ContentProps} className={clsx(transparent && S.classes.transparent)}>
        {children}
      </S.Content>
    </S.Container>
  );
};

export default JGCard;
