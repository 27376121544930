import { Typography } from "@mui/material";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router";
import { useState, useEffect } from "react";
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from "Pages/Components/ConfirmationDialog";

const MusicStoppedDialog = ({
  open,
  onClose,
  onClick,
  onClickPlay,
  showMusicStoppedDialog,
}: ConfirmationDialogProps | any) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "closedMusicStoppedPopup",
    "refreshedBrowserAftererror",
  ]);
  const location = useLocation();

  const closedMusicStoppedPopup = cookies.closedMusicStoppedPopup;
  const refreshedBrowserAftererror = cookies.refreshedBrowserAftererror;

  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const closeDialog = async (confirm: boolean | null) => {
    if (confirm) {
      setCookie("refreshedBrowserAftererror", "true", { maxAge: 60 * 60 * 24 });
      setIsShown(false);
      onClick();
    } else {
      // Expires after 24 hours
      setCookie("closedMusicStoppedPopup", "true", { maxAge: 60 * 60 * 24 });
    }
  };

  const closeDialogAfterRefresh = (confirm: boolean | null) => {
    removeCookie("refreshedBrowserAftererror", {
      path: "/",
    });

    if (confirm) {
      onClickPlay();
    }
    if (onClose) {
      onClose(false);
    }
  };

  if (refreshedBrowserAftererror && isShown) {
    return (
      <>
        <ConfirmationDialog
          open={open}
          onClose={closeDialogAfterRefresh}
          title="Thanks for refreshing your browser"
          confirmText="Play"
          ConfirmButtonProps={{ color: "primary" }}
        >
          <Typography variant="body2">
            Let’s get the music playing again
          </Typography>
        </ConfirmationDialog>
      </>
    );
  }

  if (
    closedMusicStoppedPopup ||
    !showMusicStoppedDialog ||
    location.pathname === "/checkout" ||
    location.pathname === "/checkout/" ||
    location.pathname === "/freetrial/" ||
    location.pathname === "/freetrial" ||
    !isShown
  ) {
    return <></>;
  }

  return (
    <>
      <ConfirmationDialog
        open={open}
        onClose={closeDialog}
        title="Oops, looks likes the music stopped"
        confirmText="Refresh"
        ConfirmButtonProps={{ color: "primary" }}
      >
        <Typography variant="body2">
          We apologize for the inconvenience. It looks like your browser needs
          to be refreshed and then you can press Play again. Contact us if the
          issue persists.
        </Typography>
      </ConfirmationDialog>
    </>
  );
};

export default MusicStoppedDialog;
