import React, { useContext, useState } from "react";
import {
  Radio,
  SelectChangeEvent,
  SelectProps,
  Stack,
  Typography,
} from "@mui/material";
import { deepmerge } from "@mui/utils";
import { IoChevronDown } from "react-icons/io5";
import clsx from "clsx";
import { useAppContext } from "Context";
import JGIcon from "../../JGIcon";
import CheckCircleIcon from "../../Icons/CheckCircleIcon";
import * as S from "./SelectStation.styles";
import StationIcon from "../../Icons/StationIcon";
import { StationContext } from "StationsContext/Context";

type SelectStationProps = SelectProps & {
  fullWidth?: boolean;
};

const RenderStationValue = (index: number) => {
  const { stations } = useContext(StationContext);
  const STATIONS = stations;

  return (
    <>
      <S.Background />

      <Stack
        direction="column"
        alignItems="flex-start"
        flex="auto"
        sx={{ minWidth: 0 }}
      >
        {/**
         * Using a dynamic line height instead of "16px" in case the caption height changes. Currently,
         * the caption font size is 12px, so (16 / 12) gives us the ratio we need. It's a magic number
         * because these values will never change and this is more descriptive than "1.14285714"
         */}
        <Typography
          variant="caption"
          color={(theme) => theme.palette.text.primary}
          lineHeight={16 / 12}
        >
          Select Station
        </Typography>
        <S.InputStationName
          className={S.classes.inputStationName}
          variant="body2"
        >
          {STATIONS[index].channelTitle}
        </S.InputStationName>
      </Stack>
    </>
  );
};

const SelectStation = (props: SelectStationProps) => {
  const { fullWidth = false, MenuProps, ...rest } = props;
  const { stations } = useContext(StationContext);
  const STATIONS = stations;

  const {
    activeChannel: selectedStationId,
    setActiveChannel: setSelectedStationId,
    setExternal,
  } = useAppContext();
  const [open, setOpen] = useState(false);

  const handleStationChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value;
    if (value == null) return;
    setSelectedStationId(value as number);
    setExternal(false);
  };

  return (
    <S.StyledSelect
      className={clsx(S.classes.root, fullWidth && S.classes.widthFull)}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      value={selectedStationId}
      onChange={handleStationChange}
      input={<S.Input />}
      renderValue={RenderStationValue}
      IconComponent={(props) => <JGIcon is={IoChevronDown} {...props} />}
      MenuProps={deepmerge(
        { MenuListProps: { sx: { padding: 0 } } },
        MenuProps
      )}
      {...rest}
    >
      {STATIONS.map((station, index) => (
        <S.Option key={station.id} value={index} className={S.classes.option}>
          <S.StationContainer direction="row" spacing={1.5}>
            <StationIcon
              station={station.id}
              size={40}
              sx={{ flexShrink: 0 }}
            />

            <Stack
              direction="column"
              alignItems="flex-start"
              sx={{ minWidth: 0 }}
            >
              <Typography variant="body2" fontWeight="bold">
                {station.channelTitle}
              </Typography>
              <S.StationDescription variant="caption">
                {station.channelDescription}
              </S.StationDescription>
            </Stack>
          </S.StationContainer>

          <Radio
            size="small"
            disableRipple
            checked={selectedStationId === index}
            checkedIcon={<CheckCircleIcon />}
            sx={{
              opacity: selectedStationId === index ? 1 : 0,
              pr: 0,
            }}
          />
        </S.Option>
      ))}
    </S.StyledSelect>
  );
};

export default SelectStation;
