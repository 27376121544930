import React from "react";
import clsx from "clsx";
import { isRedirectedFromMobileApp } from "Utils/RedirectedFromMobileApp";
import Player from "Pages/Components/Player/Player";
import Header from "Pages/Components/Header/Header";
import Footer from "Pages/Components/Footer/Footer";
import { HeaderProvider } from "Providers/Header";
import * as S from "./DefaultLayout.styles";

export interface DefaultLayoutProps {
  children: any
  disablePlayer?: boolean
  disableFooter?: boolean
  disableNavItems?: boolean
  disableBanner?: boolean
  disableDonateButton?: boolean
  disableHeader?: boolean
}

const DefaultLayout = ({ disablePlayer, children, disableBanner, disableFooter, disableNavItems, disableDonateButton, disableHeader }: DefaultLayoutProps) => {
  return (
    <HeaderProvider>
      <S.Container className={clsx(S.classes.root)}>
        <S.Body className={S.classes.body}>
          {!disableHeader && <Header disableBanner={disableBanner}
            disableNavItems={disableNavItems}
            disableDonateButton={disableDonateButton} />}

          <S.Content className={S.classes.content}>
            {children}
          </S.Content>

          {!disableFooter && <Footer />}
        </S.Body>

        {!isRedirectedFromMobileApp() && !disablePlayer && <Player />}
      </S.Container>
    </HeaderProvider>
  )
};

export default DefaultLayout;
