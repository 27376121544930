import { alpha, Button, buttonClasses, useMediaQuery, useTheme } from "@mui/material"
import { MouseEventHandler } from "react"
import { IoRadioButtonOff } from "react-icons/io5"
import JGIcon from "Pages/Components/JGIcon";
import CheckCircleIcon from "../Icons/CheckCircleIcon";

export default function SelectBubble(props: {
  icon?: JSX.Element,
  label: string | JSX.Element,
  selected: boolean,
  onClick: MouseEventHandler<HTMLButtonElement>,
  showCheck?: boolean
}) {
  const theme = useTheme()
  const backgroundColor = alpha(theme.palette.primary.main, props.showCheck ? 0.1 : 1)
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Button
      className="jg-select-bubble"
      variant="outlined"
      size="large"
      sx={{
        height: "60px",
        width: "100%",
        px: 3,
        py: 2,
        color: theme.palette.text.primary,
        fontSize: theme.typography.body1.fontSize,
        textAlign: "left",
        borderColor: theme.palette.divider,

        "&:hover": {
          backgroundColor: backgroundColor,
        },

        ...(isMobile && !props.selected && { "&:hover": {
            borderColor: theme.palette.divider,
            boxShadow: "none",
          }
        }),

        ...((!isMobile || props.selected) && { "&:hover": {
          backgroundColor: backgroundColor
          }
        }),

        [`& .${buttonClasses.endIcon}`]: {
          display: "block",
          flex: "auto",
          textAlign: "right",
        },

        ...(props.selected && {
          borderColor: theme.palette.primary.main,
          boxShadow: `0 0 0 ${theme.shape.borderWidth}px ${theme.palette.primary.main}`,
          backgroundColor,
        }),
      }}
      onClick={props.onClick}
      startIcon={
        props.icon
      }
      endIcon={
        props.showCheck && (
          props.selected
            ? <CheckCircleIcon sx={{ color: "primary.main" }} checkmarkColor={theme.palette.text.primary} />
            : <JGIcon is={IoRadioButtonOff} sx={{ color: "divider" }} />
        )
      }
    >
      {props.label}
    </Button>
  )
}
