import { SxProps } from "@mui/material";

/**
 * Normalize any {@link SxProps} to an array to easily use the spread operator.
 * You cannot spread `sx` directly because {@link SxProps} (typeof sx) can be an array.
 * @see https://mui.com/system/the-sx-prop/#passing-sx-prop
 */
export function normalizeSx<Theme extends object = {}>(
  sx: SxProps<Theme> | undefined | null
// ): Array<boolean | SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)> {
): Extract<SxProps<Theme>, ReadonlyArray<unknown>> {
  if (!sx) return [];
  if (Array.isArray(sx)) return sx;
  return [sx as Exclude<SxProps<Theme>, ReadonlyArray<unknown>>];
}

