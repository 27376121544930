import React, { SVGProps } from "react";
import { useTheme } from "@mui/material";

const PremiumSilenceIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  const primary = theme.palette.primary;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 165 165"
      fill="none"
      width="100%"
      height="100%"
      {...props}
    >
      <path
        d="M82.5 165c45.563 0 82.5-36.937 82.5-82.5S128.063 0 82.5 0 0 36.937 0 82.5 36.937 165 82.5 165Z"
        fill={primary.light}
      />
      <path
        d="M136.145 81.687c0 25.035-17.394 46.818-41.778 52.345-3.414 3.414-8.697 7.153-10.241 8.616-2.439 2.438-17.07 4.877-20.32 4.064-3.252-.813-5.69-4.064-7.316-9.754a27.739 27.739 0 0 1-.975-8.941C29.91 113.062 21.295 80.224 36.17 54.621c14.874-25.604 47.793-34.22 73.396-19.345 16.419 9.672 26.579 27.31 26.579 46.411Z"
        fill={theme.palette.common.white}
      />
      <path
        d="M46.736 72.502c-1.056 0-1.869-.894-1.869-1.95 0-.325.081-.57.163-.813 3.901-8.29 13.817-11.786 22.108-7.884 3.414 1.625 6.259 4.389 7.884 7.884.488.894.163 2.113-.813 2.6-.894.489-2.113.163-2.6-.812 0 0 0-.081-.082-.081A12.777 12.777 0 0 0 54.54 65.35c-2.682 1.3-4.876 3.413-6.096 6.096a1.896 1.896 0 0 1-1.706 1.056Zm44.461 0c-1.057 0-1.95-.894-1.87-1.95 0-.244.082-.57.163-.813 3.902-8.29 13.818-11.786 22.109-7.884 3.413 1.625 6.258 4.389 7.884 7.884.487.975.081 2.113-.894 2.52-.976.487-2.114.08-2.52-.894a12.777 12.777 0 0 0-16.988-6.097c-2.682 1.3-4.877 3.414-6.096 6.097-.406.731-1.056 1.137-1.788 1.137Zm-3.251 28.611c-1.057.082-1.95-.813-1.95-1.788-.082-1.056.73-1.87 1.706-1.95a42.95 42.95 0 0 0 19.914-7.804c.894-.569 2.032-.325 2.682.57.569.812.325 1.95-.406 2.52a48.573 48.573 0 0 1-21.702 8.534l-.244-.082Zm-13.33-.325h-.325c-7.072-1.22-13.818-4.064-19.67-8.21-.813-.65-.976-1.869-.325-2.681.65-.813 1.706-.976 2.52-.407a44.206 44.206 0 0 0 18.125 7.56c1.056.162 1.707 1.218 1.544 2.194-.244.894-.975 1.544-1.87 1.544Z"
        fill={primary.dark}
      />
      <path
        d="M82.5 25.603c-30.805 0-55.84 25.035-55.84 55.84 0 19.67 10.404 37.958 27.31 47.956v-4.714c-23.895-15.769-30.48-47.956-14.71-71.852 15.768-23.897 47.955-30.48 71.852-14.712 23.896 15.768 30.48 47.955 14.712 71.852-6.34 9.591-15.688 16.825-26.58 20.483-.08 1.788-.73 3.495-1.95 4.795 29.749-8.128 47.306-38.852 39.177-68.6-6.583-24.304-28.773-41.129-53.97-41.048Z"
        fill={primary.dark}
      />
      <path
        d="M99.244 129.887c-.081-1.301-.488-2.601-1.22-3.658 0 0 0-.081-.08-.081-1.626-2.357-5.284-4.308-10.323-2.52v-33c0-4.227-3.658-6.502-7.234-6.502-3.577 0-7.316 2.275-7.316 6.502v14.224c-4.47.325-8.778 1.788-9.103 5.771-5.283.732-9.672 4.389-9.998 8.535v18.125c0 5.609 6.747 11.867 16.5 11.867 9.104 0 22.028-9.022 26.66-13.574l.244-.243c1.22-1.301 1.87-3.008 1.951-4.796-.081-.244-.081-.406-.081-.65Zm-22.271-39.34c0-2.114 2.195-2.682 3.414-2.682 1.219 0 3.413.569 3.413 2.682v15.931c-2.113-1.057-4.47-1.707-6.908-1.707V90.547h.08Zm-1.22 18.044c1.87 0 3.658.325 5.447.894 2.357.976 2.6 2.357 2.6 3.333v13.817c0 .326-.569.651-1.381.895-1.951.487-3.74-.082-3.983-.407v-7.396a7.617 7.617 0 0 0-2.52-5.365c-2.276-2.032-5.04-3.332-8.046-3.739.812-1.788 5.201-2.032 7.884-2.032Zm19.508 22.759c-.081.162-.081.325-.162.487-.163.407-.488.813-.813 1.138-1.545 1.463-3.089 2.764-4.796 3.983-5.527 4.064-13.574 8.453-19.1 8.453-7.723 0-12.68-4.795-12.68-8.046v-17.638c0-2.276 3.657-5.202 7.884-5.365a11.089 11.089 0 0 1 7.64 2.764c.732.65 1.22 1.625 1.382 2.601v13.248c-.163 1.951-2.439 2.439-3.82 2.439-1.382 0-3.82-.569-3.82-2.682v-8.454c-.082-1.056-.976-1.869-2.033-1.788a1.923 1.923 0 0 0-1.788 1.788v8.454c0 .406 0 .812.081 1.219.326 1.544 1.22 2.926 2.52 3.82 1.463.975 3.251 1.544 5.04 1.544a8.087 8.087 0 0 0 5.852-2.194 6.253 6.253 0 0 0 1.788-3.658v-2.195c.813.163 1.544.326 2.357.326 2.52 0 4.552-.813 5.771-2.195.407-.488.732-1.057.894-1.626.163 0 .407-.081.57-.162 2.519-1.138 4.795-1.138 6.176.081.244.163.407.407.57.65.568.813.812 1.951.487 3.008Z"
        fill={primary.dark}
      />
    </svg>
  );
};

export default PremiumSilenceIcon;
