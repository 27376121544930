import React from "react";
import { Typography } from "@mui/material";
import { useAuth0 } from "react-auth0-spa";
import ConfirmationDialog, { ConfirmationDialogProps } from "Pages/Components/ConfirmationDialog";

const SignOutDialog = ({ open, onClose, ...rest }: ConfirmationDialogProps) => {
  const { logout } = useAuth0();

  const closeDialog = (confirm: boolean | null) => {
    if (confirm) logout();
    if (onClose) onClose(confirm || false);
  };

  return (
    <ConfirmationDialog
      open={open}
      onClose={closeDialog}
      title="Are you sure you want to sign out?"
      confirmText="Sign Out"
      ConfirmButtonProps={{ color: "error" }}
      {...rest}
    >
      <Typography variant="body2">
        By signing out, you will not have access to your plan perks until you sign in again.
        Unless this is a public computer, we recommend you stay signed in.
      </Typography>
    </ConfirmationDialog>
  );
};

export default SignOutDialog;
