import { useEffect, useState } from "react";
import Axios from "axios";
import queryString from "query-string";
import { Link, RouteChildrenProps } from "react-router-dom";
import Spinner from "./Components/Spinner";
import Title from "./Components/Title";
import History from "Utils/Routing/History";
import { CHECKOUT_ENDPOINT } from "Utils/Constants";
import DefaultLayout from "Containers/Layout/DefaultLayout";
import { Alert, Box, Button, Typography } from "@mui/material";
import { DonationSummary } from "./Components/Checkout/DonationSummary";
import { formatCurrency } from "../Utils/Format";
import { PaypalProps } from "../../types/Donate";

const PayPalCheckout = (props: PaypalProps & RouteChildrenProps) => {
  const [donation, setDonation] = useState<any>({
    gift: {},
  });
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [coupon, setCoupon] = useState<any>({});
  useEffect(() => {
    let values = queryString.parse(props.location.search);
    console.log("getting token")
    Axios.get(
      process.env.REACT_APP_API_ENDPOINT +
        "/checkout/paypal-token?token=" +
        values.token
    )
      .then((response) => {
        const tokenDonation = response.data
        setDonation(tokenDonation);
        console.log("donation coupon is", tokenDonation.coupon)
        if (tokenDonation.coupon) {
          Axios.post(process.env.REACT_APP_API_ENDPOINT + "/checkout/coupon", {
            coupon: tokenDonation.coupon.id,
            plan: tokenDonation.plan + (donation.isGift ? "-gift" : ""),
          })
            .then((r) => {
              setCoupon({
                id: tokenDonation.coupon.id,
                formatted:
                  r.data.discountType === "fixed_amount"
                    ? formatCurrency(
                        Math.min(tokenDonation.donationAmount, r.data.discountAmount) /
                          100
                      )
                    : r.data.discountAmount + "%",
                discountAmount: r.data.discountAmount,
                discountType: r.data.discountType,
              });
              setLoading(false);
            })
            .catch(()=>{
              setLoading(false);
            });
        }
        else setLoading(false);
      })
      .catch(() => {
        setError(
          "Uh oh! Your PayPal token has expired. Click here to make a new donation."
        );
        setLoading(false);
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultLayout disablePlayer>
      <Box sx={{display: "flex", justifyContent: "center", p: 2}}>
        <Box sx={{maxwidth: "md"}}>
          <Title value="Confirm Donation" />
          <Typography variant="h1" sx={{my: 2, textAlign: "center"}}>{error ? "An error has occurred" : "Confirm Your Donation"}</Typography>
          {(() => {
            if (loading)
              return (
                <Typography variant="body1" sx={{textAlign: "center"}}>Loading your donation...</Typography>
              );
            if (error)
              return (
                <Typography variant="body1">
                  <Link to="/checkout">{error}</Link>
                </Typography>
              );
            return (
              <Box sx={{width: "400px", maxWidth: "100%", m: "0 auto"}}>
                <DonationSummary
                  donationType={donation.donationType}
                  autoRenew={donation.autoRenew}
                  donationAmount={donation.donationAmount}
                  donationAdditionalAmount={donation.donationAdditionalAmount}
                  gift={donation.gift}
                  shippingFee={donation.shippingAmount}
                  coupon={coupon}
                />
                <Box sx={{mt: 1}}>
                  {donation.isGift && (
                    <Box sx={{mt: 2}}>
                      <Typography variant="h4">Gift Membership:</Typography>
                      <Typography variant="body1">
                        To: {donation.giftRecipient.first_name}
                      </Typography>
                    </Box>
                  )}
                  {donation.gift && (
                    <Box sx={{mt: 2}}>
                      <Typography variant="h4">Your Gift:</Typography>
                      <Typography variant="body1">{donation.gift.name}</Typography>
                      {donation.size && (
                        <Typography variant="body1">
                          <span>Size: {donation.size}</span>
                        </Typography>
                      )}
                      {donation.gift.meta_data &&
                        donation.gift.meta_data.images &&
                        donation.gift.meta_data.images[1] && (
                          <img
                            src={donation.gift.meta_data.images[1].url}
                            alt="gift"
                            className="paypal_gift-image"
                          />
                        )}
                    </Box>
                  )}
                  {(!donation.gift || donation.gift.name === "No gift for me") && (
                    <Box sx={{mt: 2}}>
                      <Typography variant="h4">Your gift:</Typography>
                      <Typography variant="body1">
                        100% of donation supports <Link to="/">JazzGroove.org</Link> (no
                        gift)
                      </Typography>
                    </Box>
                  )}
                  <Button sx={{mt: 1}} fullWidth type="submit" disabled={submitLoading} onClick={() => {
                    setSubmitLoading(true);
                    setSubmitError("");
                    Axios.post(process.env.REACT_APP_API_ENDPOINT + CHECKOUT_ENDPOINT + "/v2", {
                      customer: {
                        email: donation.email,
                        first_name: donation.first_name,
                        last_name: donation.last_name,
                      },
                      password: donation.password,
                      plan: donation.plan,
                      shippingAmount: donation.shippingAmount,
                      donationAmount: donation.donationAmount,
                      donationAdditionalAmount: donation.donationAdditionalAmount,
                      amount: donation.amount,
                      autoRenew: donation.autoRenew,
                      coupon: coupon.id,
                      gift: donation.gift ? donation.gift.id : undefined,
                      intl: donation.intl,
                      shipping: donation.shipping,
                      monthly: donation.monthly,
                      giftRecipient: donation.giftRecipient,
                      isGift: donation.isGift,
                      paymentMethod: {
                        type: "paypal_express_checkout",
                        token: donation.id,
                      },
                      dev: process.env.REACT_APP_NODE_ENV === "dev",
                    })
                    .then((response) => History.push("/thank-you", response.data))
                    .catch((e) => {
                      setSubmitLoading(false);
                      setSubmitError(
                        (e.response && e.response.data) ? e.response.data.message : (e.message ? e.message : e)
                      );
                    });
                  }}>
                    {submitLoading ? <Spinner /> : <>Complete Donation</>}
                  </Button>
                  {submitError && <Alert severity="error" sx={{mt: 1}}>{submitError || error}</Alert>}
                </Box>

              </Box>
            );

          })()}
        </Box>
      </Box>
    </DefaultLayout>
  )
};

export default PayPalCheckout;
