import React, { useMemo } from "react";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx, SxProps } from "@mui/system";
import { normalizeSx } from "Utils/Components/normalizeSx";
import { ReactComponent as Mix1Icon } from "Assets/stations/mix1.svg";
import { ReactComponent as Mix2Icon } from "Assets/stations/mix2.svg";
import { ReactComponent as GemsIcon } from "Assets/stations/gems.svg";
import { ReactComponent as DreamsIcon } from "Assets/stations/dreams.svg";
import { ReactComponent as SmoothIcon } from "Assets/stations/smooth.svg";
import { ReactComponent as ChristmasIcon } from "Assets/stations/christmas-swirls.svg";

type StationIconProps = React.SVGProps<SVGSVGElement> & {
  station: StationId;
  size?: number | string;
  sx?: SxProps;
}

const icons: Record<StationId, React.FunctionComponent> = {
  mix1: Mix1Icon,
  mix2: Mix2Icon,
  gems: GemsIcon,
  dreams: DreamsIcon,
  smooth: SmoothIcon,
  christmas: ChristmasIcon,
};

const StationIcon = (props: StationIconProps) => {
  const {
    station,
    size,
    sx,
    ...rest
  } = props;

  const StyledIconComponent = useMemo(() => {
    const IconComponent = icons[station];
    return styled(IconComponent)<{ sx?: SxProps }>(unstable_styleFunctionSx as any);
  }, [station]);

  return (
    <StyledIconComponent
      sx={[
        {
          borderRadius: 0.5,
          width: size,
          height: size,
        },
        ...normalizeSx(sx),
      ]}
      {...rest}
    />
  );
};

export default StationIcon;
