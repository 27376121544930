import React from "react";
import Title from "./Components/Title";
import { Container, Typography } from "@mui/material";
import { SuccessfulDonation } from "../../types/Donate";

interface ThankYouProps {
  location: {
    state?: SuccessfulDonation;
  };
}

const DefaultContent = ({
  customer,
}: Pick<SuccessfulDonation, "customer" | "signUp"> & {
  freeTrialDoc?: {
    thankYouHeader: string;
    thankYouContent: React.ReactNode;
    welcomeContent: React.ReactNode;
  };
}) => {
  return (
    <>
      <>
        <p>
          Everything you hear on The Jazz Groove is made possible by generous
          financial support from donors like you. Thank you so much for
          supplementing your existing membership with an additional donation. We
          will email you with more details in the next few minutes.
        </p>

        {customer?.email && (
          <p>
            We emailed your receipt to <strong>{customer.email}</strong>.
          </p>
        )}
      </>
    </>
  );
};

const ThankYouOneTime = (props: ThankYouProps) => {
  const state = props.location?.state;
  const { customer, signUp } = state || {};

  return (
    <Container
      maxWidth="sm"
      sx={(theme) => ({ textAlign: "center", ...theme.mixins.pageContent })}
    >
      <Title value="Thank You" />
      <Typography variant="h1" sx={{ mb: 3 }}>
        <>Thank You</>
        {customer?.first_name && `, ${customer.first_name}`}!
      </Typography>

      <DefaultContent customer={customer!} signUp={signUp!} />
    </Container>
  );
};

export default ThankYouOneTime;
