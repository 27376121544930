import useGetStationApi from "Api/ChannelsApi";
import React from "react";

export const StationContext = React.createContext<any | null>(null);

export const StationProvider = ({ children }) => {
  const { stations } = useGetStationApi();
  if (stations) {
  }
  const stationsId = stations?.map((station: any) => station.id);

  const stationsOptions = stations?.map((station) => ({
    label: station.channelTitle,
    value: station.id,
  }));

  const stationQueryCodes = stations
    ?.map((station) => station.queryCode)
    ?.concat(["christmas"]);

  return (
    <StationContext.Provider
      value={{ stations, stationsId, stationsOptions, stationQueryCodes }}
    >
      {stations && children}
    </StationContext.Provider>
  );
};
