import { NavLink, useHistory } from "react-router-dom";
import { Link, MenuItem } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import clsx from "clsx";
import { HeaderNavItemProps } from "./HeaderNavItems";
import * as S from "./HeaderNavItem.styles";

const HeaderNavMenuItem = (props: HeaderNavItemProps & CommonProps & { component?: any }) => {
  const {
    label,
    link,
    className,
    ...rest
  } = props;

  const history = useHistory()

  return (
    <S.ListItem className={clsx(S.classes.menuItem, className)} component={MenuItem} {...rest} onClick={() => history.push(link!.to as any)}>
      <Link
        className={S.classes.link}
        component={NavLink}
        {...link}
      >
        <S.ItemButtonText className={S.classes.text} variant="body1">
          {label}
        </S.ItemButtonText>
      </Link>
    </S.ListItem>
  );
};

export default HeaderNavMenuItem;
