import { Components, Theme } from "@mui/material/styles";

const Snackbar: Components<Theme> = {
  MuiSnackbar: {
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        return {
          ...(ownerState.anchorOrigin?.vertical === "bottom" && {
            bottom: `calc(var(--jg-player-height) + ${theme.spacing(1)})`,

            [theme.breakpoints.up("sm")]: {
              bottom: `calc(var(--jg-player-height) + ${theme.spacing(3)})`,
            },
          }),
        };
      },
    },
  },
};

export default Snackbar;
