import React, { useEffect, useState } from "react";
import { IconButton, Menu, Slider, Stack, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { IoClose, IoVolumeMedium, IoVolumeMute } from "react-icons/io5";
import { audioPlayer } from "AudioPlayer";
import JGIcon from "../../JGIcon";
import { PlayerVolumeProps } from "../types";

const PlayerVolume = (props: PlayerVolumeProps) => {
  const {
    muted,
    setMuted,
    volume,
    setVolume,
  } = props;

  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up("lg"));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const isMenuOpen = Boolean(anchorEl);

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const toggleMute = () => {
    setMuted(isMuted => !isMuted);
  };

  const volumeSliderChanged = (event: Event, newVolume: number | number[]) => {
    setVolume(newVolume as number);

    if (muted && newVolume > 0) {
      setMuted(false);
    }
  };

  useEffect(() => {
    if (audioPlayer) {
      audioPlayer.volume = muted ? 0 : volume;
    }
  }, [volume, muted]);

  return (
    <div className="jg-player-volume">
      <Tooltip title="Volume">
        <IconButton
          id="jg-player-volume__menu-toggle"
          aria-controls={isMenuOpen ? "jg-player-volume__menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuOpen ? "true" : undefined}
          onClick={toggleMenu}
          sx={{
            height: 38,
            width: 38,
          }}
        >
          <JGIcon
            is={isMenuOpen ? IoClose : muted ? IoVolumeMute : IoVolumeMedium}
            fontSize={isDesktop ? "medium" : "small"}
          />
        </IconButton>
      </Tooltip>

      <Menu
        id="jg-player-volume__menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        disableScrollLock
        MenuListProps={{
          sx: {
            height: 220,
            padding: "36px 12px 16px",
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Stack spacing={2} alignItems="center" sx={{ height: "100%" }}>
          <Slider
            aria-label="Volume"
            orientation="vertical"
            value={muted ? 0 : volume}
            min={0}
            max={1}
            step={0.01}
            onChange={volumeSliderChanged}
          />

          <Tooltip title={muted ? "Unmute" : "Mute"}>
            <IconButton aria-label="Toggle Mute" onClick={toggleMute}>
              <JGIcon is={muted ? IoVolumeMute : IoVolumeMedium} fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Menu>
    </div>
  );
};

export default PlayerVolume;
