import { NavLinkProps } from "react-router-dom";
import { CONTENTFUL_HOW_TO_LISTEN_DISPLAY_NAME_ENTRY_ID } from "Utils/Constants";

export type HeaderNavItemProps = {
  label: string;
  key: string;
  link?: Omit<NavLinkProps, "className" | "style"> & { className?: string };
  items?: HeaderNavItemProps[];
};

const HEADER_NAV_ITEMS: ReadonlyArray<HeaderNavItemProps> = [
  {
    key: "premium",
    label: "Premium Plus",
    link: { to: "/premium" },
  },
  {
    key: CONTENTFUL_HOW_TO_LISTEN_DISPLAY_NAME_ENTRY_ID,
    label: "Help Center",
    link: { to: "/devices" },
  },
  {
    key: "more",
    label: "More",
    items: [
      {
        key: "about",
        label: "About",
        link: { to: "/about" },
      },
      {
        key: "blog",
        label: "Blog",
        link: { to: "/blog" },
      },
      {
        key: "jazz-tonight",
        label: "Jazz Tonight",
        link: { to: "/jazz-tonight" },
      },
      {
        key: "help",
        label: "Help",
        link: { to: "/help" },
      },
    ],
  }
];

export default HEADER_NAV_ITEMS;
