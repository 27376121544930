import { useCallback, useLayoutEffect, useState } from "react";
import { useEvent } from "react-use";

export interface ElementOverflow<T extends HTMLElement = HTMLDivElement> {
  ref: (node: T | null) => void;
  x: boolean;
  y: boolean;
}

/**
 * Detect when an element has overflowed its container.
 */
const useElementOverflow = <T extends HTMLElement = HTMLDivElement>(): ElementOverflow<T> => {
  /**
   * Mutable values like `ref.current` aren't valid dependencies
   * because mutating them doesn't re-render the component.
   * Instead, we use a state as a ref to be reactive.
   * @see https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
   */
  const [ref, setRef] = useState<T | null>(null)
  const [overflow, setOverflow] = useState({ x: false, y: false });

  // Prevent too many rendering using useCallback
  const handleSize = useCallback(() => {
    setOverflow({
      x: ref ? ref.offsetWidth < ref.scrollWidth : false,
      y: ref ? ref.offsetHeight < ref.scrollHeight : false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth, ref?.scrollHeight, ref?.scrollWidth]);

  useEvent("resize", handleSize);

  useLayoutEffect(() => {
    handleSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth, ref?.scrollHeight, ref?.scrollWidth]);

  return { ref: setRef, x: overflow.x, y: overflow.y };
};

export default useElementOverflow;
