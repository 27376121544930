import React, { useImperativeHandle, forwardRef } from "react";
import { Box, InputBaseComponentProps, useTheme } from "@mui/material";

type StripInputProps = InputBaseComponentProps & {
  component: React.FunctionComponent<InputBaseComponentProps>;
  options: Record<string, unknown>;
};

/**
 * Receive CardNumber from Stripe as prop and useRef to explain that when you
 * use TextField it has to interact with CardNumber.
 */
const StripeInput = forwardRef<HTMLElement, StripInputProps>((props, ref) => {
  const { component: Component, options, inputRef, ...rest } = props;
  const theme = useTheme();
  const [stripeEl, setStripeEl] = React.useState<HTMLInputElement | null>(null);

  useImperativeHandle(inputRef, () => ({
    focus: () => stripeEl?.focus(),
  }), [stripeEl]);

  return (
    <Box ref={ref} sx={{ display: "flex", width: "100%" }}>
      <Component
        onReady={setStripeEl}
        options={{
          ...options,
          style: {
            base: {
              color: theme.palette.text.primary,
              fontSize: `16px`,
              lineHeight: theme.typography.body1.lineHeight,
              fontFamily: theme.typography.fontFamily,
            },
            invalid: {
              color: theme.palette.text.primary,
            },
          },
        }}
        {...rest}
      />
    </Box>
  )
});

export default StripeInput;
