import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { isHome } from "Utils/isHome";

/**
 * Track whether the current page is the home page.
 */
const useHome = () => {
  const location = useLocation();
  const [home, setHome] = useState(true);

  useEffect(() => {
    setHome(isHome(location.pathname));
  }, [location, setHome]);

  return home;
}

export default useHome;
