/** global AudioPump */
import ReactGA from "react-ga";
import moment from "moment";
import { isPremium, isPremiumPlus } from "./Utils/Account/Plan";
import { maxListenTime } from "./App";
import sendLog from "./Utils/Logger";
import {
  LOW_QUALITY,
  MID_QUALITY,
  HIGH_QUALITY,
  AUTH0_USER_METADATA_KEY,
  LOWEST_QUALITY,
} from "Utils/Constants";
import { getUser, lastUser } from "react-auth0-spa";
import * as Sentry from "@sentry/react";
import { LocalStorageItem } from "Utils/Constants/LocalStorageItem";
import RecentlyPlayed from "Utils/Constants/RecentlyPlayed";
import UserApi from "Api/UserApi";

const isDev = process.env.REACT_APP_NODE_ENV === "dev";

let awaitingRetry = false;
let retryCount = 0;
const retryLimit = isDev ? 2 : 10;

let last = {
  playlistId: null,
  channelId: null,
};

let BLOCK = false;
let subscriber = false;
let userId: any = null;
let listenerCapData: any = {};
let currentTimeListenedData: any = {};

let stations = [];

async function getStations(stationsArray: any) {
  try {
    stations = stationsArray;
    await stationsArray.forEach((station: any) => {
      return (listenerCapData[station.channelId] = {
        listeningCapTimeFrame: station.listeningCapTimeFrame,
        listeningCapTimeLimit: station.listeningCapTimeLimit,
        listeningCapRedirectUrl: station.listeningCapRedirectUrl,
        BLOCK_PLAYLIST_ID: station.listeningCapRedirectUrl.split("/").pop(),
      });
    });

    stationsArray.forEach((station: any) => {
      const currentTimeListened = localStorage.getItem(
        `block-time-${station.channelId}`
      )
        ? JSON.parse(
            localStorage.getItem(`block-time-${station.channelId}`) || ""
          )
        : {
            listenTime: 0,
            resetTime: moment().endOf(
              listenerCapData[
                station.channelId
              ].listeningCapTimeFrame.toLowerCase()
            ),
          };

      if (moment(currentTimeListened.resetTime).isBefore()) {
        currentTimeListened.resetTime = moment().endOf(
          listenerCapData[station.channelId].listeningCapTimeFrame.toLowerCase()
        );
        currentTimeListened.listenTime = 0;
      }
      return (currentTimeListenedData[station.channelId] = currentTimeListened);
    });
  } catch (e) {}
}

/**
 * Keeps track of each minute a user has listened, caches this data
 * Checks to see if user has gone over listening cap limit
 * Returns without doing anything if user is a subscriber
 * @returns
 */
function incrementMinutes() {
  if (subscriber) return;
  getStations(stations);
  if (activeChannelId !== "BLOCK") {
    currentTimeListenedData[activeChannelId].listenTime++;

    if (moment(currentTimeListenedData[activeChannelId].resetTime).isBefore()) {
      currentTimeListenedData[activeChannelId].resetTime = moment().endOf(
        listenerCapData[activeChannelId].listeningCapTimeFrame.toLowerCase()
      );
    }

    localStorage.setItem(
      `block-time-${activeChannelId}`,
      JSON.stringify(currentTimeListenedData[activeChannelId])
    );
  }

  try {
    checkBlock(true);
  } catch (e) {}
}

/**
 * Checks if the user is over the listening cap and/or is authenticated
 * If user is authenticated/subscribed or under the cap make no change
 * If the user is unsubscribed and over the cap change playlist to promo playlist
 * @param {Boolean} stopImmediately Flag to denote if player should switch immediately or only after cached songs are done playing
 * @param {Boolean} setSubscriber Flag to denote if function should get Auth0 subscriber data
 * @returns
 */
function checkBlock(stopImmediately?: boolean, setSubscriber?: boolean) {
  return new Promise(async (resolve, reject) => {
    try {
      let user = lastUser;
      if (!user) {
        try {
          user = await getUser();
        } catch (e) {}
      }
      if ((setSubscriber || !subscriber) && user) {
        userId = user.sub;
        subscriber = Boolean(user.plan);
      }
      if (
        subscriber ||
        currentTimeListenedData[activeChannelId].listenTime <
          listenerCapData[activeChannelId].listeningCapTimeLimit
      ) {
        BLOCK = false;

        if (
          audioPlayer &&
          audioPlayer.playlistId ===
            listenerCapData[activeChannelId].BLOCK_PLAYLIST_ID
        ) {
          reject("Updated block");
          newPlaylist(
            last.playlistId
              ? last.playlistId
              : "bab24f05-73f8-4088-9373-bf118f07bdec",
            last.channelId ? last.channelId : "jazzgroove-mix-1",
            false,
            true
          );
        }
      } else {
        // bugsnagClient.notify(new Error("Block Activated"), {
        //   metaData: { ...user },
        // });

        if (
          !BLOCK ||
          currentTimeListenedData[activeChannelId].listenTime >=
            listenerCapData[activeChannelId].listeningCapTimeLimit
        ) {
          BLOCK = true;

          if (stopImmediately) {
            audioPlayer.pause();
            newPlaylist(
              listenerCapData[activeChannelId].BLOCK_PLAYLIST_ID,
              "BLOCK",
              false,
              false
            );
            reject("Updated block");
          } else if (audioPlayer)
            audioPlayer.playlistId =
              listenerCapData[activeChannelId].BLOCK_PLAYLIST_ID;
        }
      }
    } catch (e) {
      if (
        currentTimeListenedData[activeChannelId] &&
        currentTimeListenedData[activeChannelId].listenTime > maxListenTime &&
        !subscriber
      ) {
        // bugsnagClient.notify(new Error("Catch Block Activated"));

        BLOCK = true;
        if (audioPlayer) {
          audioPlayer.playlistId =
            listenerCapData[activeChannelId].BLOCK_PLAYLIST_ID;
        }
      }
    }
    resolve(BLOCK);
  });
}

let currentEntry: Record<string, any> = {};
let activeChannelId = "";
let audioPlayer: any = null;
let started = false;
let quality = [LOW_QUALITY];
let currentMetadata: Record<string, any> = {};
let currentPlaylist = "";
const lastTimes: any[] = [];
let ignoreTimeUpdates = false;
let ignoreTimeUpdateTimeout: NodeJS.Timeout;
let eventCallbacks: Record<string, any> = {
  recentlyPlayed: null,
  newSong: null,
  statusUpdate: null,
  seeked: null,
  musicStopped: null,
};
let songData: Record<string, any> = {};
let trackProgress = 0;

function setIngoreTimeUpdates(bool) {
  ignoreTimeUpdates = bool;
}
function setIgnoreTimeUpdateTimeout(timeout) {
  ignoreTimeUpdateTimeout = timeout;
}

function setAudioCallbacks(callbacks) {
  eventCallbacks = { ...eventCallbacks, ...callbacks };
}

async function setMaxQuality(max: AudioQuality) {
  switch (max) {
    case HIGH_QUALITY:
      quality = [HIGH_QUALITY, MID_QUALITY, LOW_QUALITY, LOWEST_QUALITY];
      break;
    case MID_QUALITY:
      quality = [MID_QUALITY, LOW_QUALITY, LOWEST_QUALITY];
      break;
    case LOW_QUALITY:
      quality = [LOW_QUALITY, LOWEST_QUALITY];
      break;
    case LOWEST_QUALITY:
      quality = [LOWEST_QUALITY];
      break;
    default:
      quality = [LOWEST_QUALITY];
  }

  const prevQual = localStorage.getItem(LocalStorageItem.audioQuality);
  if (
    max &&
    (lastUser?.[AUTH0_USER_METADATA_KEY]?.preferences?.quality !== max ||
      prevQual !== max)
  ) {
    try {
      localStorage.setItem(LocalStorageItem.audioQuality, max);
      await UserApi.updatePreferences({ quality: max });
    } catch (e) {}
  }
}

function updateNowPlaying() {
  const coverVariant = currentEntry.Asset.Variants.find((variant) => {
    return variant.type === "coverThumbnail-500x500";
  });
  songData = {
    title: currentEntry.Asset.tags.TITLE ? currentEntry.Asset.tags.TITLE : "",
    artist: currentEntry.Asset.tags.ARTIST
      ? currentEntry.Asset.tags.ARTIST
      : "",
    src: coverVariant ? coverVariant.urls.get : "",
    coverVariant: currentEntry.Asset.id.charCodeAt(0) % 4,
    progress: trackProgress,
  };
  if (eventCallbacks.nowPlayingUpdate)
    eventCallbacks.nowPlayingUpdate(songData);
}

let newPlaylist: any;

if (typeof AudioPump !== "undefined") {
  class LowQualityVariantResolver extends AudioPump.Channels.VariantResolver {
    static get mediaVariantTypePriority() {
      return quality;
    }
  }

  class JazzGroovePlaylistController extends AudioPump.Channels
    .SequentialPlaylistController {
    static getCurrentDate() {
      const d = new Date();
      return new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
    }

    static getNext(playlist: any, currentEntry: any): any {
      // eslint-disable-next-line prefer-rest-params
      const next = super.getNext.apply(this, arguments);
      const silenceStationVocal = stations.find(
        (station: any) =>
          station.silenceVocals && station.channelId === activeChannelId
      )?.["silenceVocals"];

      const tag =
        next && next.Asset && next.Asset.tags
          ? next.Asset.tags["jg-voiceover"]
          : null;
      if (lastUser) {
        if (isPremiumPlus(lastUser.plan)) {
          if (
            tag === "FD" &&
            lastUser[AUTH0_USER_METADATA_KEY].preferences.silenceFD
          ) {
            return this.getNext(playlist, next);
          } else if (
            tag === "DJ" &&
            lastUser[AUTH0_USER_METADATA_KEY].preferences.silenceDJ
          ) {
            return this.getNext(playlist, next);
          } else if (tag === "PM") {
            return this.getNext(playlist, next);
          } else if (
            tag === "V" &&
            lastUser[AUTH0_USER_METADATA_KEY].preferences.silenceVocals &&
            silenceStationVocal
          ) {
            return this.getNext(playlist, next);
          }
        } else if (isPremium(lastUser.plan)) {
          if (tag === "PM") {
            return this.getNext(playlist, next);
          } else if (tag === "FD" && true) {
            return this.getNext(playlist, next);
          }
        }
      }
      return next;
    }
  }

  function ended() {
    switch (activeChannelId) {
      case "jazzgroove-mix1":
        audioPlayer.playlistId = "03475f49-5b46-46f1-a3d4-2e4d3fc0e8f7";
        break;
      case "jazzgroove-mix2":
        audioPlayer.playlistId = "c1115e6b-38ad-44ef-8dcf-df5c2f65ed60";
        break;
      case "jazzgroove-dreams":
        audioPlayer.playlistId = "08f64a07-748f-4862-9ec6-0c2f5380c05f";
        break;
      case "jazzgroove-gems":
        audioPlayer.playlistId = "4fdf2e49-6a93-4a32-bd17-16aa8ff87713";
        break;
      case "jazzgroove-smooth":
        audioPlayer.playlistId = "ced5de56-84f3-423d-94cf-88bb28f19396";
        break;
      default:
        throw new Error("Invalid channel id");
    }
  }

  function onError(e) {
    // Ignore abort errors... they usually happen due to a legitimate user action.
    if (audioPlayer.error.name === "AbortError") {
      return;
    }

    // Errors sometimes occur in clusters.  For example, if connectivity is lost,
    // there may be several simultaneous or near-simultaneous errors triggered
    // on multiple HTMLMediaElements, and potentially in playlist loading.
    // After an error occurs, ignore subsequent errors until we retry playing.
    if (awaitingRetry) {
      return;
    }

    if (audioPlayer.error) {
      Sentry.captureException(new Error("Audio Player Error"), {
        user: lastUser
          ? {
              id: lastUser.sub,
              email: lastUser.email,
            }
          : undefined,
        extra: {
          errorType: e && e.constructor ? e.constructor.name : undefined,
          currentTrack:
            currentEntry.Asset && currentEntry.Asset.tags.TITLE
              ? currentEntry.Asset.tags.TITLE
              : "",
          quality: lastUser
            ? lastUser[AUTH0_USER_METADATA_KEY]?.preferences?.quality
            : LOW_QUALITY,
          retryCount: retryCount,
        },
        tags: {
          message: audioPlayer?.error?.message,
          code: audioPlayer?.error?.code,
        },
      });
      audioPlayer.error = undefined;
    } else {
      Sentry.captureException(e, {
        user: lastUser
          ? {
              id: lastUser.sub,
              email: lastUser.email,
            }
          : undefined,
      });
    }

    // Limit the number of attempts to retry playback.
    if (retryCount >= retryLimit) {
      console.error("Player Error", { error: audioPlayer.error });
      eventCallbacks.musicStopped(true);

      Sentry.captureException(new Error("Audio Player Music Stopped Error"), {
        user: lastUser
          ? {
              id: lastUser.sub,
              email: lastUser.email,
            }
          : undefined,
        extra: {
          errorType: e && e.constructor ? e.constructor.name : undefined,
          currentTrack:
            currentEntry.Asset && currentEntry.Asset.tags.TITLE
              ? currentEntry.Asset.tags.TITLE
              : "",
          quality: lastUser
            ? lastUser[AUTH0_USER_METADATA_KEY]?.preferences?.quality
            : LOW_QUALITY,
          retryCount: retryCount,
        },
      });
      return;
    }

    retryCount++;

    if (retryCount <= 1) {
      // This is the first error occurence.  Try to recover immediately
      // by skipping everything in the current queue.
      audioPlayer.seekQueueRelative(audioPlayer.queue.length);
    } else {
      // If this is a subsequent error, attempt recovery only after a delay.
      // Note: Due to varying implementations of autoplay policy, not all browsers
      // are able to start playback after such a delay without user interaction.
      awaitingRetry = true;
      setTimeout(() => {
        awaitingRetry = false;
        audioPlayer.seekQueueRelative(audioPlayer.queue.length);
      }, retryCount * 1000);
    }

    ReactGA.event({
      category: "player",
      action: "retry",
      value: retryCount,
    });
  }

  function addListeners() {
    audioPlayer.addEventListener("queueupdate", queueUpdate);
    audioPlayer.addEventListener("timeupdate", timeUpdate);
    audioPlayer.addEventListener("play", eventCallbacks.statusUpdate);
    audioPlayer.addEventListener("pause", eventCallbacks.statusUpdate);
    audioPlayer.addEventListener("seeked", eventCallbacks.seeked);
    audioPlayer.addEventListener("ended", ended);
    audioPlayer.addEventListener("error", onError);
  }

  function removeListeners() {
    audioPlayer.removeEventListener("queueupdate", queueUpdate);
    audioPlayer.removeEventListener("timeupdate", timeUpdate);
    audioPlayer.removeEventListener("play", eventCallbacks.statusUpdate);
    audioPlayer.removeEventListener("pause", eventCallbacks.statusUpdate);
    audioPlayer.removeEventListener("seeked", eventCallbacks.seeked);
    audioPlayer.removeEventListener("ended", ended);
    audioPlayer.removeEventListener("error", onError);
  }

  function timeUpdate(e) {
    if (audioPlayer.seeking || ignoreTimeUpdates) return;
    lastTimes.push(e.target.currentTime);
    while (lastTimes.length > 2) {
      lastTimes.shift();
    }
    currentMetadata.playTime = e.target.currentTime;
    const currentProgress =
      (e.target.currentTime / currentEntry.Asset.effectiveDuration) * 100;

    if (trackProgress + 1 < currentProgress) {
      trackProgress = currentProgress;
      updateNowPlaying();
    } else if (trackProgress > currentProgress) {
      trackProgress = 0;
      updateNowPlaying();
    }
  }

  function queueUpdate(e) {
    const entries = e.detail;
    // Show "now playing" information
    if (entries[0]) {
      if (
        subscriber &&
        entries[0].id === "cb80a647-0d8b-4a8a-a534-4241f9a90da5" &&
        audioPlayer.playlistId !==
          listenerCapData[activeChannelId].BLOCK_PLAYLIST_ID
      ) {
        audioPlayer.seekQueueRelative(1);
        return;
      }
      if (!started) {
        currentEntry = entries[0];
        currentMetadata = {
          tags: currentEntry.Asset.tags,
          startTime: new Date().toISOString(),
          endTime: null,
          playTime: 0,
          playlistId: audioPlayer.playlistId,
          channelId: activeChannelId,
          assetId: currentEntry ? currentEntry.Asset.id : null,
          effectiveDuration: currentEntry.Asset.effectiveDuration,
          title: currentEntry.Asset.title,
          userId: userId,
          isSubscriber: subscriber,
        };
        sendLog("start", currentMetadata);
        started = true;
        if ("mediaSession" in navigator) {
          const coverVariant = currentEntry.Asset.Variants.find((variant) => {
            return variant.type === "coverThumbnail-500x500";
          });
          navigator.mediaSession.metadata = new MediaMetadata({
            title: currentEntry.Asset.tags.TITLE
              ? currentEntry.Asset.tags.TITLE
              : "",
            artist: currentEntry.Asset.tags.ARTIST
              ? currentEntry.Asset.tags.ARTIST
              : "",
            artwork: coverVariant
              ? [
                  {
                    src: coverVariant.urls.get,
                    sizes: "500x500",
                    type: "image/jpg",
                  },
                ]
              : undefined,
          });
        }
      }
      if (currentMetadata.assetId !== entries[0].Asset.id) {
        sendLog("stop", {
          ...currentMetadata,
          endTime: new Date().toISOString(),
        });
        addToRecentlyPlayed(currentEntry.Asset);
        currentEntry = entries[0];
        currentMetadata = {
          tags: currentEntry.Asset.tags,
          startTime: new Date().toISOString(),
          endTime: null,
          playTime: 0,
          playlistId: audioPlayer.playlistId,
          channelId: activeChannelId,
          assetId: currentEntry ? currentEntry.Asset.id : null,
          effectiveDuration: currentEntry.Asset.effectiveDuration,
          title: currentEntry.Asset.title,
          userId: userId,
          isSubscriber: subscriber,
        };
        sendLog("start", currentMetadata);
      }
      updateNowPlaying();
    }
  }

  if (!localStorage.getItem("session"))
    localStorage.setItem(
      "session",
      Math.random().toString(36).substr(2) +
        Math.random().toString(36).substr(2)
    );

  document.addEventListener("DOMContentLoaded", (e) => {
    window.addEventListener("beforeunload", () => {
      sendLog("stop", {
        ...currentMetadata,
        endTime: new Date().toISOString(),
      });
    });
  });

  newPlaylist = async function (playlistId, channelId, external, paused) {
    try {
      await getStations(stations);

      let play = false;
      if (audioPlayer && !paused) {
        audioPlayer.pause();
        removeListeners();
        play = true;
      }
      if (
        !BLOCK &&
        audioPlayer &&
        audioPlayer.playlistId === listenerCapData[channelId].BLOCK_PLAYLIST_ID
      ) {
        await checkBlock(true, true);
      }

      if (playlistId !== "d87f1b64-a00f-4915-a34e-5fa21d58813c") {
        last = {
          playlistId: playlistId,
          channelId: channelId,
        };
      }
      activeChannelId = channelId;

      audioPlayer = new AudioPump.Channels.Player({
        playlistId: playlistId,
        baseUrl:
          "https://tjg-apchannels-media-prod.sfo2.cdn.digitaloceanspaces.com/",
        playlistController: JazzGroovePlaylistController,
        variantResolver: LowQualityVariantResolver,
      });
      currentPlaylist = playlistId;
      started = false;
      addListeners();
      audioPlayer.load();

      if (play && !external) {
        audioPlayer.play();

        ReactGA.event({
          category: "player",
          action: "streamquality",
          label: activeChannelId,
          value: quality as any,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  function addToRecentlyPlayed(song) {
    const variant = song.Variants.find((variant) => {
      return variant.type === "coverThumbnail-500x500";
    });
    if (!variant) return;
    let recentlyPlayed: RecentlyPlayedSong[];
    if (localStorage.getItem(LocalStorageItem.recentlyPlayed)) {
      recentlyPlayed = JSON.parse(
        localStorage.getItem(LocalStorageItem.recentlyPlayed) || ""
      );
    } else {
      recentlyPlayed = [...RecentlyPlayed];
      localStorage.setItem(
        LocalStorageItem.recentlyPlayed,
        JSON.stringify(recentlyPlayed)
      );
    }
    if (recentlyPlayed.length >= 30) {
      recentlyPlayed.shift();
    }
    const currentSong: RecentlyPlayedSong = {
      title: song.tags.TITLE,
      img: variant.urls.get,
      artist: song.tags.ARTIST,
      album: song.tags.ALBUM,
      when: new Date(),
      station: activeChannelId.replace("jazzgroove-", "") as StationId,
    };
    if (recentlyPlayed.slice(-1)[0].title !== currentSong.title)
      recentlyPlayed.push(currentSong);
    localStorage.setItem(
      LocalStorageItem.recentlyPlayed,
      JSON.stringify(recentlyPlayed)
    );
    eventCallbacks.recentlyPlayed(recentlyPlayed);
  }
}

export {
  getStations,
  audioPlayer,
  setMaxQuality,
  started,
  newPlaylist,
  updateNowPlaying,
  setAudioCallbacks,
  activeChannelId,
  currentEntry,
  lastTimes,
  setIngoreTimeUpdates,
  ignoreTimeUpdateTimeout,
  setIgnoreTimeUpdateTimeout,
  incrementMinutes,
  checkBlock,
  BLOCK,
  currentMetadata,
  currentPlaylist,
  songData,
};
