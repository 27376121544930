import { isNilOrEmpty } from "./Inspect";

/**
 * Format a number to be human-readable.
 *
 * @example
 * formatNumber(2500);
 * // => '2,500.00'
 *
 * formatNumber(2500, 0, 0);
 * // => '2,500'
 */
export function formatNumber(value: number, minimumFractionDigits = 2, maximumFractionDigits = 2): string {
  if (isNilOrEmpty(value) || isNaN(value)) return "";

  const formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits, maximumFractionDigits });
  return formatter.format(value);
}

/**
 * Format a number as currency.
 *
 * @example
 * formatCurrency(2500);
 * // => '$2,500.00'
 *
 * formatCurrency(2500, 0, 0);
 * // => '$2,500'
 */
export function formatCurrency(value: number, minimumFractionDigits = 2, maximumFractionDigits = 2): string {
  if (isNilOrEmpty(value) || isNaN(value)) return "";

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits, maximumFractionDigits
  });

  return formatter.format(value);
}
