import type { CamelCase } from "type-fest";
import { isBoolean } from "Utils/Inspect";
import { toPascalCase } from "Utils/ChangeCase";
import { DonateQueryParam, DONATION_QUERY_PARAM_FREQUENCY_MAP } from "Utils/Constants";
import { DonateQueryParamName, DonateQueryParams } from "../../types/Donate";
import { DonationHistory, ExpiringSubscription } from "Api/SubscriptionApi";
import { getPlanTierByCode } from "./Components/Plan";

/**
 * Convert the keys in `TOut` to their equivalent, camel-cased key name in `TEnum`.
 */
type CamelCaseEnumKeys<
  TEnum extends Record<keyof typeof DonateQueryParam, DonateQueryParamName>,
  TOut extends Partial<Record<DonateQueryParamName, unknown>>
> = {
  [prop in keyof TEnum as `${CamelCase<string & prop>}`]?: TOut[keyof TOut & TEnum[prop]];
};

/** See {@link DonateQueryParams} for details on each property. */
export type BuildDonatePathProps = Omit<
  CamelCaseEnumKeys<typeof DonateQueryParam, DonateQueryParams>,
  // Deprecated property.
  "monthly"
>;

function toQueryParams(props: BuildDonatePathProps): DonateQueryParams {
  return Object.entries(props).reduce((accum, [key, value]) => {
    const paramKey: string = toPascalCase(key);
    accum[DonateQueryParam[paramKey]] = value;
    return accum;
  }, {} as DonateQueryParams);
}

/**
 * Helper for building the path to the donate/checkout page.
 *
 * @example
 * buildDonatePath({ amount: 100_00, frequency: "m" });
 * // => /checkout?amount=10000&f=m
 */
export function buildDonatePath(props: BuildDonatePathProps = {}): string {
  let path = "/checkout";

  const params = Object.entries(toQueryParams(props)).map(([key, value]) => {
    const parsedValue = isBoolean(value)
      ? (value ? 1 : 0)
      : isNaN(value as any)
        ? value
        // We re-cast as a number to remove any underscores we may have used for legibility.
        : Number(value);

    return `${key}=${parsedValue}`;
  });
  if (params.length) path += `?${params.join("&")}`;

  return path;
}

export function buildLegacyUpgradeDonatePath(subscription: ExpiringSubscription | DonationHistory) {
  const planId = (subscription as DonationHistory).planId || (subscription as ExpiringSubscription).planCode
  const minimumAmount = getPlanTierByCode(planId)[subscription.legacyUpgradeOffer!.donationType === "one-time" ? "oneTime" : subscription.legacyUpgradeOffer!.donationType].minimumAmount * 100
  return buildDonatePath({
      coupon: subscription.legacyUpgradeOffer!.coupon,
      amount: subscription.donationType === "one-time" ? minimumAmount :
          Math.max(subscription.amount, minimumAmount),
      frequency: DONATION_QUERY_PARAM_FREQUENCY_MAP[subscription.legacyUpgradeOffer!.donationType]
  })
}
