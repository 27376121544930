import {
  PREMIUM_PLAN,
  PREMIUM_PLUS_PLAN,
  UNLIMITED_LISTENING_PLAN,
} from "Utils/Constants";

export function isPremiumPlus(plan) {
  return typeof plan === "string" && plan.includes(PREMIUM_PLUS_PLAN);
}

export function isPremium(plan) {
  return typeof plan === "string" && plan.includes(PREMIUM_PLAN);
}

export function isPremiumOrUnlimited(plan) {
  return typeof plan === "string" && (plan.includes(UNLIMITED_LISTENING_PLAN) || plan.includes(PREMIUM_PLAN));
}

export function isUnlimited(plan) {
  return typeof plan === "string" && plan.includes(UNLIMITED_LISTENING_PLAN);
}
