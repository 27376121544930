import React from "react";
import { Options as RichTextRendererOptions } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { Divider, Link, Typography } from "@mui/material";
import { deepmerge } from "@mui/utils";
import JGLink from "Pages/Components/JGLink";

/**
 * Create renderer options based on {@link RENDERER_OPTIONS}.
 * Any options provided will override the defaults.
 */
export function createRendererOptions(options?: RichTextRendererOptions) {
  return deepmerge(RENDERER_OPTIONS, options, { clone: true });
}

export const RENDERER_OPTIONS: RichTextRendererOptions = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <Typography variant="h1">{children}</Typography>,
    [BLOCKS.HEADING_2]: (node, children) => <Typography variant="h2">{children}</Typography>,
    [BLOCKS.HEADING_3]: (node, children) => <Typography variant="h3">{children}</Typography>,
    [BLOCKS.HEADING_4]: (node, children) => <Typography variant="h4">{children}</Typography>,
    [BLOCKS.HEADING_5]: (node, children) => <Typography variant="h5">{children}</Typography>,
    [BLOCKS.HEADING_5]: (node, children) => <Typography variant="h5">{children}</Typography>,

    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <Typography sx={{
          "&:not(:first-of-type)": { mt: 2 },
        }}>
          {children}
        </Typography>
      )
    },

    [BLOCKS.HR]: (node, children) => <Divider sx={{ my: 2 }}>{children}</Divider>,

    [INLINES.HYPERLINK]: (node, children) => {
      let url: URL | null = null;
      try {
        url = new URL(node.data.uri);
      } catch (_) { /* Ignore error. It's likely not a fully formatted URL. */ }

      const isLocalLink = url?.hostname === window.location.hostname;
      const isOutgoingLink = !node.data.uri.startsWith("#") && url?.hostname !== "jazzgroove.org";

      if (url && isLocalLink) {
        return <JGLink to={url.pathname}>{children}</JGLink>;
      }

      return (
        <Link href={node.data.uri} target={isOutgoingLink ? "_blank" : undefined}>
          {children}
        </Link>
      );
    },
  }
} as const;
