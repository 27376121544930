import { PlanFrequency } from "../../../types/Plan";

export const NON_US_SHIPPING_FEE = 15_00;

/**
 * Minimum amount a user must donate per month to quality for a thank-you gift.
 */
export const MINIMUM_MONTHLY_DONATION_FOR_GIFT = 20_00;

/**
 * TODO: Ideally this and {@link PlanFrequency} would be one-in-the-same.
 */
export enum DonationType {
  annual = "a",
  monthly = "m",
  oneTime = "f"
}

export enum DonateQueryParam {
  Amount = "a",
  Monthly = "m",
  Frequency = "f",
  GiftSelected = "gm",
  Gift = "g",
  StartAtPaymentStep = "s",
  Coupon = "c",
}

export const DONATION_QUERY_PARAM_FREQUENCY_MAP: Record<PlanFrequency, DonationType> = {
  annually: DonationType.annual,
  monthly: DonationType.monthly,
  "one-time": DonationType.oneTime
}

export const DONATION_TYPE_FREQUENCY_MAP: Record<DonationType, PlanFrequency> = {
  [DonationType.monthly]: "monthly",
  [DonationType.annual]: "annually",
  [DonationType.oneTime]: "one-time",
} as const;

export const DONATION_FREQUENCY_ABBREV_FORMATTED_MAP: Record<DonationType, string> = {
  [DonationType.monthly]: "/mo",
  [DonationType.annual]: "/yr",
  [DonationType.oneTime]: "One-time",
} as const;
