import React from "react";
import { Box, Typography } from "@mui/material";
import ConfirmationDialog, { ConfirmationDialogProps } from "Pages/Components/ConfirmationDialog";
import { DonationGift } from "../../../../../types/Donate";

type ViewGiftDialogProps = ConfirmationDialogProps & {
  gift: DonationGift;
};

const GiftViewDialog = (props: ViewGiftDialogProps) => {
  const { gift, open, onClose } = props;
  const imgSrc = gift.meta_data?.images?.find(img => img.type === "thumbnail")?.url;

  return (
    <ConfirmationDialog
      maxWidth="sm"
      open={open}
      onClose={onClose}
      title={<Typography variant="h4" component="span">{gift.name}</Typography>}
      cancelText="Cancel"
      confirmText="Select"
      ConfirmButtonProps={{ color: "primary" }}
    >
      {imgSrc && (
        <Box
          component="img"
          src={imgSrc}
          sx={{
            maxWidth: 248,
            maxHeight: 248,
            my: 2,
            borderRadius: 1,
          }}
        />
      )}

      <Typography>
        {gift.description}
      </Typography>
    </ConfirmationDialog>
  );
};

export default GiftViewDialog;
