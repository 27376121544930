import { createContext } from "react";
import { DEFAULT_THEME_ID } from "../../Utils/Constants";
import { Theme } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme();

export const StationThemeContext = createContext<{
  themeId: StationId;
  switchTheme: (themeId: StationId) => void;
  lightTheme: Theme;
  darkTheme: Theme,
}>({
  themeId: DEFAULT_THEME_ID,
  switchTheme: () => {},
  lightTheme: defaultTheme,
  darkTheme: defaultTheme,
});
