import React from "react";
import Title from "../Components/Title";

const SonosComplete = () => {
  return (
    <div className="page sonos">
      <Title value="Sonos" />

      <main className="sonos-lander">
        <section className="hero">
          <img
            src="https://assets.jazzgroove.org/Logo-white.svg"
            alt="The Jazz Groove"
            className="logo"
          />
          <img
            src="https://assets.jazzgroove.org/sonos/Sonos_Logo.png"
            alt="Sonos"
            style={{ background: "white" }}
            className="logo"
          />
          <h1>Return to the Sonos app to login and start listening</h1>
        </section>

        <section className="sub-hero">
          <h3>Laid-Back Jazz</h3>
          <p>Plus 4 other channels of hand-selected, artfully curated jazz.</p>
        </section>

        <footer className="sonos-footer">
          <p className="small">Foundation To Advance Jazz</p>
          <p className="small">501(c)(3) nonprofit organization</p>
        </footer>
      </main>
    </div>
  );
};

export default SonosComplete;
