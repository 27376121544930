import { createContext } from "react";
import { PaletteMode } from "@mui/material";
import { DEFAULT_HEADER_MODE } from "Utils/Constants";

export interface HeaderState {
  mode: PaletteMode;
  setMode: (mode: PaletteMode) => void;
  bgOpacity: number;
}

export const HeaderContext = createContext<HeaderState>({
  mode: DEFAULT_HEADER_MODE,
  setMode: () => {},
  bgOpacity: 0,
});
