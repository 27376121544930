import { CamelCase, KebabCase, PascalCase } from "type-fest";

// Support camel case ("camelCase" -> "camel Case" and "CAMELCase" -> "CAMEL Case").
const DEFAULT_SPLIT_REGEXP = [/([a-z0-9])([A-Z])/g, /([A-Z])([A-Z][a-z])/g];

// Remove all non-word characters.
const DEFAULT_STRIP_REGEXP = /[^A-Z0-9]+/gi;

/** Replace `re` in the input string with the replacement value. */
function replace(input: string, re: RegExp | RegExp[], value: string) {
  if (re instanceof RegExp) return input.replace(re, value);
  return re.reduce((input, re) => input.replace(re, value), input);
}

/**
 * Split a string into an array of words in lower case.
 * https://github.com/blakeembrey/change-case/blob/040a079f007879cb0472ba4f7cc2e1d3185e90ba/packages/no-case/src/index.ts
 */
function words(value: string): string[] {
  let result = replace(
    replace(value, DEFAULT_SPLIT_REGEXP, "$1\0$2"),
    DEFAULT_STRIP_REGEXP,
    "\0"
  );

  let start = 0;
  let end = result.length;

  // Trim the delimiter from around the output string.
  while (result.charAt(start) === "\0") start++;
  while (result.charAt(end - 1) === "\0") end--;

  // Transform each token independently.
  return result.slice(start, end).split("\0").map(str => str.toLowerCase());
}

export function toKebabCase<T extends string>(value: T): KebabCase<T> {
  return words(value).join("-") as KebabCase<T>;
}

export function toCamelCase<T extends string>(value: T): CamelCase<T> {
  return words(value)
    .map((word, index) => {
      return index === 0
        ? word
        : word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("") as CamelCase<T>;
}

export function toPascalCase<T extends string>(value: T): PascalCase<T> {
  return words(value)
    .map(word => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
    .join("") as PascalCase<T>;
}
