import { Box, BoxProps, Stack, StackProps, styled } from "@mui/material";

const PREFIX = "jg-song";
export const classes = {
  root: PREFIX,
};

export const Container = styled(Box)<BoxProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;

  &.${classes.root}--direction-horizontal {
    flex-direction: row;
  }
`;

export const Content = styled(Stack)<StackProps>`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
`
