import { Button, Grid } from "@mui/material"
import { useState } from "react"
import { IoChevronDown, IoChevronUp } from "react-icons/io5"
import GiftSelect from "../GiftSelect"
import { DonationType } from "Utils/Constants";
import { DonationGift } from "../../../../../types/Donate";
import GiftViewDialog from "Pages/Components/Checkout/Dialogs/GiftViewDialog";
import GiftVariantsDialog from "Pages/Components/Checkout/Dialogs/GiftVariantsDialog";
import JGIcon from "Pages/Components/JGIcon";

function compareGifts(type, a, b) {
  if (a.first) return -1
  else if (b.first) return 1
  if (
    !(
      a.meta_data &&
      a.meta_data.pricing &&
      a.meta_data.pricing[type] &&
      a.meta_data.pricing[type].minimum
    )
  )
    return -1
  if (
    !(
      b.meta_data &&
      b.meta_data.pricing &&
      b.meta_data.pricing[type] &&
      b.meta_data.pricing[type].minimum
    )
  )
    return 1
  if (a.meta_data.pricing[type].minimum > b.meta_data.pricing[type].minimum)
    return 1
  else if (
    a.meta_data.pricing[type].minimum < b.meta_data.pricing[type].minimum
  )
    return -1

  if (a.name > b.name) return 1
  else if (a.name < b.name) return -1
  return 0
}

export default function GiftStep(props: {
  donationAmount: number,
  donationType: DonationType,
  gift: DonationGift | null,
  gifts: DonationGift[],
  setGift: (gift: DonationGift | null) => void
}) {
  const [showMore, setShowMore] = useState(false)
  const [focusedGift, setFocusedGift] = useState<DonationGift | null>(null);
  const [giftViewDialogOpen, setGiftViewDialogOpen] = useState(false);
  const [giftVariantsDialogOpen, setGiftVariantsDialogOpen] = useState(false);

  const viewGift = (gift: DonationGift) => {
    setFocusedGift(gift);
    setGiftViewDialogOpen(true);
  };

  const selectGift = (gift: DonationGift | null, variantIndex?: number) => {
    const variants = gift?.meta_data?.variants;
    if (variants?.length && !variantIndex) {
      setFocusedGift(gift);
      setGiftVariantsDialogOpen(true);
      return;
    }

    props.setGift(gift && variants?.length && variantIndex
      ? { ...gift, size: variantIndex < variants.length ? variants[variantIndex] : undefined }
      : gift);
    setFocusedGift(null);
  };

  const handleGiftViewDialogClose = (confirm: boolean) => {
    if (focusedGift && confirm) {
      selectGift(focusedGift);
    }
    setGiftViewDialogOpen(false);
  };

  const handleGiftVariantDialogClose = (variantIndex: number | null) => {
    if (focusedGift && variantIndex != null) {
      props.setGift({
        ...focusedGift,
        size: focusedGift.meta_data?.variants?.[variantIndex],
      });
      setFocusedGift(null);
    }
    setGiftVariantsDialogOpen(false);
  };

  const getDonationMinimum = (gift: DonationGift) => {
    return gift.meta_data.pricing?.[props.donationType === DonationType.monthly ? "monthly" : "oneTime"]?.minimum || 0;
  }

  const getGiftDescription = (gift: DonationGift) => {
    const minimum = getDonationMinimum(gift) / 100
    let frequency = ""
    switch (props.donationType) {
      case DonationType.monthly: frequency = "/mo"
        break
      case DonationType.annual: frequency = "/yr"
        break
      default: frequency = ""
        break
    }
    return `$${minimum}${frequency} minimum`
  }

  return (
    <>
      {focusedGift && <>
        <GiftViewDialog
          open={giftViewDialogOpen}
          onClose={handleGiftViewDialogClose}
          gift={focusedGift}
        />

        <GiftVariantsDialog
          open={giftVariantsDialogOpen}
          onClose={handleGiftVariantDialogClose}
          gift={focusedGift}
        />
      </>}

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <GiftSelect
            description={<>I'd like 100% of my donation to support JazzGroove.org and be tax deductible.</>}
            selected={props.gift == null}
            onSelect={() => selectGift(null)}
          />
        </Grid>
        {showMore && props.gifts
          .sort((a, b) =>
            compareGifts(props.donationType === DonationType.monthly ? "monthly" : "oneTime", a, b)
          )
          .filter((gift) =>
            props.donationType !== DonationType.monthly ||
            (gift.meta_data &&
              gift.meta_data.pricing &&
              gift.meta_data.pricing.monthly) ||
            gift.first
          )
          .map((gift, index) => (
            <Grid key={index} item xs={12}>
              <GiftSelect
                imgSrc={gift.meta_data?.images?.find(img => img.type === "thumbnail")?.url}
                title={gift.name}
                description={getGiftDescription(gift)}
                selected={!!(props.gift && props.gift.id === gift.id)}
                onSelect={() => selectGift(gift)}
                onView={() => viewGift(gift)}
                available={getDonationMinimum(gift) <= props.donationAmount}
              />
            </Grid>
          ))}
        <Grid item xs={12} textAlign="center">
          <Button
            variant="outlined"
            size="small"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "Hide" : "Show all"} gifts
            <JGIcon is={showMore ? IoChevronUp : IoChevronDown} fontSize="inherit" sx={{ ml: 0.5 }} />
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
