import { useEffect, useRef, useState } from "react";
import CheckoutStepper from "./Components/Checkout/CheckoutStepper";
import Carousel from "react-multi-carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-multi-carousel/lib/styles.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  Avatar,
  Box,
  Grid,
  Stack,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { HashLink } from "react-router-hash-link";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useAsync } from "react-use";
import ImagesCover from "Assets/free-trial/ALBUMCOVERS.png";
import Ellipse from "Assets/free-trial/Ellipse.png";
import EllipseBottom from "Assets/free-trial/EllipseBottom.png";
import GrainBg from "Assets/free-trial/Grain-bg.png";
import CardsBg from "Assets/free-trial/cards_bg.png";
import TestimonialsBgMobile from "Assets/free-trial/testimonials_bg_mobile.png";
import TestimonialsBg from "Assets/free-trial/testimonials_bg.png";
import Logo from "Pages/Components/Logo";
import ContentfulClient from "Utils/ContentfulClient";
import {
  PremiumQualityIcon,
  PremiumSilenceIcon,
  PremiumSkipIcon,
} from "./Components/Icons";
import Title from "Pages/Components/Title";
import { FreeTrialDocDto } from "../../types/Donate";
import UnlimitedAccessIcon from "./Components/Icons/UnlimitedAccessIcon";
import styles from "./Components/FreeTrial/FreeTrial.module.css";
import ChrisBotti from "Assets/free-trial/photos/chris-botti.png";
import BradMehldau from "Assets/free-trial/photos/Brad-Mehldau.png";
import ChetBaker from "Assets/free-trial/photos/Chet-Baker-Mobile.png";
import DianaKrall from "Assets/free-trial/photos/Diana-Krall.png";
import LouisArmstrong from "Assets/free-trial/photos/Louis-Armstrong-Pyramids-Mobile.png";
import MilesDavis from "Assets/free-trial/photos/Miles.png";
import SonnyRollins from "Assets/free-trial/photos/Sonny-Rollins.png";
import WyntonMarsalis from "Assets/free-trial/photos/Wynton-Marsalis-Mobile.png";
import { Link } from "react-router-dom";
import Footer from "./Components/Footer/Footer";

const legends = [
  { name: "Chris Botti", image: ChrisBotti },
  { name: "Brad Mehldau", image: BradMehldau },
  { name: "Chet Baker", image: ChetBaker },
  { name: "Diana Krall", image: DianaKrall },
  { name: "Louis Armstrong", image: LouisArmstrong },
  { name: "Miles Davis", image: MilesDavis },
  { name: "Sonny Rollins", image: SonnyRollins },
  { name: "Wynton Marsalis", image: WyntonMarsalis },
];

const testimonials = [
  {
    name: "Doug",
    comment:
      "My wife and I love The Jazz Groove! We listen to it everytime we are at home; the playlists are absolutely first-rate, don't change a thing!",
  },
  {
    name: "Jane",
    comment:
      "The Jazz Groove is the best! I love laid-back Jazz but had never found a station that consistently played it... For something that provides me with so much enjoyment, I happily contribute.",
  },
  {
    name: "Bill & Joan",
    comment:
      "We just made our annual contribution to The Jazz Groove. The calming effect The Jazz Groove has on our pets is just amazing",
  },
  {
    name: "Bill",
    comment:
      "It is so relaxing and puts me in such a great headspace—I just enjoy listening on a daily basis",
  },
  {
    name: "Artie",
    comment:
      "I keep contributing to The Jazz Groove because the station just resonates with me. Your music helps me balance, mentally—you're just doing everything right. Keep up the good work",
  },
  {
    name: "Christopher",
    comment:
      "There’s no other station with music so carefully curated. When I come home in the evening, all I need to do is put it on, sit back and relax",
  },
  {
    name: "Daryl",
    comment:
      "With all the jazz stations out there, The Jazz Groove stands out with the selection of music and tone that we were looking for",
  },
  {
    name: "Bill",
    comment:
      "As a devoted and grateful listener, it’s easy to speak in superlatives when discussing The Jazz Groove. It's my daily companion, the music station I go to first, last and always",
  },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1500 },
    items: 6,
  },
  smalldesktop: {
    breakpoint: { max: 1500, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Perk(props: {
  icon: React.ElementType;
  title: string;
  body: string | JSX.Element;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const iconSize = isMobile ? 80 : 164;
  return (
    <Stack
      direction={isMobile ? "row" : "column"}
      spacing={2}
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: iconSize }}>
        <props.icon
          style={{
            width: iconSize,
            height: iconSize,
            flexShrink: 0,
          }}
        />
      </Box>
      <Box
        sx={{
          textAlign: isMobile ? "left" : "center",
          flex: "auto",
        }}
      >
        <Typography variant="h5" component="p" sx={{ pb: isMobile ? 0 : 1 }}>
          {props.title}
        </Typography>
        <Typography variant="body2">{props.body}</Typography>
      </Box>
    </Stack>
  );
}

const stripe = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string);

const FreeTrial = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTinyMobile = useMediaQuery("(max-width: 400px)");
  // console.log("🚀 ~ FreeTrial ~ isMobile:", isMobile);
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down("lg"));
  const isMediumDesktop = useMediaQuery("(max-width: 1370px)");
  // console.log("🚀 ~ FreeTrial ~ isMediumDesktop:", isMediumDesktop);
  // console.log("🚀 ~ FreeTrial ~ isSmallDesktop:", isSmallDesktop);
  const [showLanding, setShowLanding] = useState(true);
  console.log("🚀 ~ FreeTrial ~ showLanding:", showLanding);
  const [contentfulEntry, setContentfulEntry] = useState<null | string>(null);
  let sliderRef = useRef<Slider | null>(null);

  const legendsItems: [JSX.Element?] = [];
  legends.forEach((legend) => {
    legendsItems.push(
      <div>
        <Box
          sx={{
            width: isMobile ? "180px" : "280px",
            height: isMobile ? "180px" : "280px",
            borderRadius: "18px",
            background: `url(${legend.image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            textAlign: "center",
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              bottom: "20px",
              width: "100%",
              textAlign: "center",
              zIndex: 5,
            }}
          >
            <Typography variant={"body1"}>{legend.name}</Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "0px",
              width: isMobile ? "180px" : "280px",
              height: isMobile ? "70px" : "140px",
              borderRadius: "18px",
              backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)`,
              zIndex: 3,
            }}
          ></Box>
        </Box>
      </div>
    );
  });

  const testimonialsItems: [JSX.Element?] = [];
  testimonials.forEach((legend) => {
    testimonialsItems.push(
      <Grid
        container
        xs={12}
        sx={{
          minHeight: isTinyMobile
            ? "250px"
            : isMediumDesktop
            ? "327px"
            : "160px",
          borderRadius: "18px",
          background: `url(${
            isMediumDesktop ? TestimonialsBgMobile : TestimonialsBg
          })`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: 5,
        }}
      >
        <Grid
          item
          sx={{
            zIndex: 5,
          }}
          xs={12}
        >
          <Box
            sx={{
              display: "block",
              textAlign: "center",
              padding: isTinyMobile
                ? "80px 15px 10px "
                : isMediumDesktop
                ? "120px 20px 10px"
                : "50px 50px 10px",
            }}
          >
            <Typography variant={"body1"}>{legend.comment}</Typography>
            <Typography variant={"body1"}>
              <b>{legend.name}</b>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  });

  const { value: freeTrialDoc } = useAsync(async () => {
    if (contentfulEntry) {
      const entry = await ContentfulClient.getEntry<FreeTrialDocDto>(
        contentfulEntry
      );

      let options = {
        renderNode: {
          "embedded-asset-block": (node) => (
            <img
              className="img-fluid"
              src={node.data.target.fields.file.url}
              alt="img"
            />
          ),
        },
      };
      const ret = {
        welcomeContent: documentToReactComponents(
          entry.fields.freeTrialWelcomeText,
          options
        ),
        thankYouHeader: entry.fields.thankYouPageContentHeader,
        thankYouContent: documentToReactComponents(
          entry.fields.thankYouPageContent
        ),
        inactiveFreeTrialText: documentToReactComponents(
          entry.fields.inactiveFreeTrialText
        ),
        active: entry.fields.freeTrialActive,
      };
      return ret;
    }
  }, [contentfulEntry]);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const queryContentfulEntry = params.get("entity");
    if (queryContentfulEntry) setContentfulEntry(queryContentfulEntry);
    else setContentfulEntry("1XeMTSgmKvjNn9XtrFLLfv"); // default entry ID for both dev and prod
  }, []);

  return (
    <>
      <Title value="Free Trial" />
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          height: "104px",
          left: 0,
          top: 0,
          background:
            "linear-gradient(180deg, #121212 0%, rgba(0, 0, 0, 0) 100%)",
          pt: 2,
          pl: isMobile ? 1 : 10,
          pr: isMobile ? 1 : 10,
          zIndex: 15,
        }}
      >
        <Grid container xs={12}>
          <Grid xs={6} justifyContent={"flex-start"} sx={{ textAlign: "left" }}>
            <Box
              sx={{
                maxWidth: "169px",
                maxHeight: "75px",
                mx: 0,
              }}
            >
              <Logo height={"53px"} />
            </Box>
          </Grid>
          <Grid
            xs={6}
            alignItems={"right"}
            sx={{
              textAlign: "right",
              pr: isMobile ? 2 : 0,
              ml: isMobile ? -2 : 0,
              isolation: "isolate",
            }}
          >
            <Button
              color="darkPurple"
              smooth
              component={HashLink}
              to={"#form"}
              sx={{
                padding: isMobile ? "15px 5px" : "15px 30px",
                minWidth: isMobile ? "200px" : "64px",
              }}
            >
              Sign up for free trial
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Elements stripe={stripe}>
        <Box
          sx={{
            backgroundColor: "#121212",
            minHeight: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              height: "1119px",
              width: "100%",
              background: `url('${GrainBg}')`,
              backgroundPosition: "top right",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              mixBlendMode: "overlay",
              zIndex: 2,
            }}
          ></Box>
          <Box
            sx={{
              height: "1800px",
              marginTop: "-1119px",
              background: `url('${Ellipse}')`,
              backgroundPosition: "top right",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#121212",
              zIndex: 1,
            }}
          ></Box>

          <Box
            sx={{
              height: isMobile ? "500px" : "800px",
              marginTop: "-1800px",
              background: `url('${ImagesCover}')`,
              backgroundPosition: isMobile
                ? "top -50px right -100px"
                : "top -150px right -200px",
              backgroundSize: isMobile ? "cover" : "contain",
              backgroundRepeat: "no-repeat",
              zIndex: 3,
              isolation: "isolate",
            }}
          ></Box>
          <Container
            sx={{
              height: "100%",
              marginTop: isMobile ? "-50px" : "-600px",
              zIndex: 5,
              isolation: "isolate",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingTop: "50px",
              }}
            >
              <Typography
                variant="subtitle2"
                component="div"
                fontWeight="regular"
                sx={(theme) => ({
                  pt: 2,
                  fontFamily: theme.typography.fontFamily,
                  color: theme.palette.text.disabled,
                })}
              >
                Internet Radio. Non-profit.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <Typography variant={"h1"} component="h1" fontWeight="bold">
                Laid-Back Jazz
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                maxWidth: "500px",
                mt: 2,
              }}
            >
              <Typography variant={isMobile ? "body2" : "body1"}>
                Feel your shoulders relax with The Jazz Groove's gentle swing of
                music, featuring artists like Miles Davis, Duke Ellington,
                Wynton Marsalis, and thousands more. Sign up for a one month
                free trial to enjoy Hi-Res Audio, Song Skips & many other perks!
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <Typography
                variant={isMobile ? "h2" : "h1"}
                component="h1"
                fontWeight="bold"
              >
                <Button
                  color="darkPurple"
                  smooth
                  component={HashLink}
                  to={"#form"}
                  sx={{ padding: "15px 30px" }}
                >
                  Sign up for free trial
                </Button>
              </Typography>
            </Box>
          </Container>
        </Box>
        <Grid
          container
          sx={{ pt: isMobile ? 6 : 20, zIndex: 5, isolation: "isolate" }}
        >
          <Grid
            item
            sm={12}
            md={6}
            sx={{
              py: isMobile ? 1 : 8,
              px: isMobile ? 3 : 15,
              textAlign: isMobile ? "left" : "right",
            }}
          >
            <Typography
              sx={{
                fontFamily: "futura-pt",
                fontWeight: 450,
                fontSize: isSmallMobile
                  ? "2em"
                  : isSmallDesktop
                  ? "2em"
                  : "3.5em",
                margin: "0 auto",
              }}
            >
              Member Benefits
            </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            container
            alignItems="center"
            sx={{
              pt: 2,
              px: isMobile ? 3 : 15,
              pb: isMobile ? 2 : 0,
              textAlign: isMobile ? "left" : "left",
            }}
          >
            <Typography
              variant={"subtitle2"}
              component="text"
              fontWeight="regular"
            >
              When you sign up for a <b>free trial</b>, you get everything:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={1}
              sx={{ maxWidth: "lg", margin: isMobile ? "0 10px" : "0 auto" }}
            >
              <Grid item xs={12} md sx={{ px: 2, pb: isMobile ? 2 : 0 }}>
                <Perk
                  icon={PremiumSilenceIcon}
                  title="Just the music"
                  body={
                    <>
                      No interruptions from promotional messages or fund drives.
                    </>
                  }
                />
              </Grid>
              <Grid item xs={12} md sx={{ px: 2, py: isMobile ? 2 : 0 }}>
                <Perk
                  icon={PremiumQualityIcon}
                  title="Hi-Res Audio"
                  body="Audiophile fidelity (Lossless FLAC)."
                />
              </Grid>
              <Grid item xs={12} md sx={{ px: 2, pt: isMobile ? 2 : 0 }}>
                <Perk
                  icon={PremiumSkipIcon}
                  title="Song Skips"
                  body={<>Want to listen to a different song? Click skip!</>}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md
                sx={{ px: 2, pt: isMobile ? 2 : 0, mt: isMobile ? 2 : 0 }}
              >
                <Perk
                  icon={UnlimitedAccessIcon}
                  title="Unlimited Access"
                  body={
                    <>
                      Your listening won’t be be capped as it is for
                      non-members.
                    </>
                  }
                />
              </Grid>
              {/* <Grid item xs={12} md sx={{ px: 2, pt: isMobile ? 2 : 0 }}>
                <Perk
                  icon={StationsIcon}
                  title="5 stations"
                  body={
                    <>
                      Five unique jazz radio stations; a jazz aficionado's
                      delight!
                    </>
                  }
                />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ maxWidth: "lg", margin: "0 auto", pt: 15, overflow: "visible" }}
        >
          <Grid
            item
            sm={12}
            height={"615px"}
            sx={{
              background: `url('${CardsBg}')`,
              borderRadius: "18px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              textAlign: "center",
              overflow: "visible",
            }}
          >
            <Container
              sx={{
                spacing: 0,
                paddingTop: "50px",
                zIndex: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box>
                <Typography
                  variant="subtitle2"
                  component="div"
                  fontWeight="regular"
                  sx={(theme) => ({
                    fontFamily: theme.typography.fontFamily,
                    color: theme.palette.text.disabled,
                    fontWeight: 500,
                  })}
                >
                  15,000+ songs across 5 unique jazz radio stations
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "futura-pt",
                    fontWeight: 450,
                    fontSize: "3.5em",
                  }}
                >
                  Jazz Legends & Rising Stars
                </Typography>
              </Box>
              <Box
                sx={{
                  pt: 2,
                  maxWidth: "700px",
                  margin: "auto",
                  mb: 10,
                }}
              >
                <Typography variant={isMobile ? "body1" : "body1"}>
                  Each hour of music is meticulously-curated and
                  artfully-blended from a rare collection of 15,000+ jazz
                  recordings spanning 8-decades
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Grid>
        <Box sx={{ mt: isMobile ? "-220px" : "-330px" }}>
          <Carousel
            responsive={responsive}
            centerMode
            autoPlay={true}
            autoPlaySpeed={2000}
            infinite
            partialVisible={false}
            pauseOnHover
            itemClass={styles.carouselPadding}
            draggable
            arrows={false}
          >
            {legendsItems}
          </Carousel>
        </Box>
        <Box
          sx={{
            height: "3500px",
            marginTop: "-300px",
            background: `url('${EllipseBottom}')`,
            backgroundPosition: "bottom left",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#121212",
            zIndex: 1,
          }}
        ></Box>
        <Box
          sx={{
            height: "819px",
            marginTop: "-819px",
            width: "100%",
            background: `url('${GrainBg}')`,
            backgroundPosition: "bottom left",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            mixBlendMode: "overlay",
            zIndex: 2,
          }}
        ></Box>
        <Box sx={{ margin: "-3300px auto 0", maxWidth: "lg" }}></Box>
        <Grid
          container
          sx={{ maxWidth: "lg", margin: "0 auto", pt: 20, overflow: "visible" }}
        >
          <Grid
            item
            sm={12}
            sx={{
              textAlign: "center",
              overflow: "visible",
            }}
          >
            <Container
              sx={{
                spacing: 0,
                paddingTop: "50px",
                zIndex: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "futura-pt",
                    fontWeight: 450,
                    fontSize: "3.5em",
                  }}
                >
                  Listen In Your Favorite Music Player.
                </Typography>
              </Box>
              <Box
                sx={{
                  pt: 2,
                  maxWidth: "700px",
                  margin: "auto",
                  mb: 4,
                }}
              >
                <Typography
                  variant={"subtitle2"}
                  component="text"
                  fontWeight="regular"
                >
                  PC, MAC, iPhone, Android, Tablets, Sonos, Amazon Alexa & many
                  other devices.
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            maxWidth: "lg",
            margin: "0 auto",
            pt: 15,
            mb: 7,
            overflow: "visible",
          }}
        >
          <Grid
            item
            sm={12}
            sx={{
              textAlign: "center",
              overflow: "visible",
            }}
          >
            <Container
              sx={{
                spacing: 0,
                zIndex: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "futura-pt",
                    fontWeight: 450,
                    fontSize: "3.5em",
                    lineHeight: "1.3em",
                  }}
                >
                  Jazz Groove listeners share why they donated
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Grid>
        <Box
          sx={{
            position: "relative",
            width: "140%",
            marginLeft: "-20%",
            zIndex: 7,
          }}
        >
          <div className="slider-arrow">
            <Avatar
              className="arrow-btn-prev"
              onClick={() => sliderRef.current?.slickPrev()}
            >
              <NavigateBeforeIcon sx={{ color: "white" }} />
            </Avatar>
            <Avatar
              className="arrow-btn-next"
              onClick={() => sliderRef.current?.slickNext()}
            >
              <NavigateNextIcon sx={{ color: "white" }} />
            </Avatar>
          </div>

          <Slider
            ref={sliderRef}
            infinite
            adaptiveHeight={false}
            variableWidth={false}
            arrows={false}
            centerMode
            centerPadding={"0px"}
            slidesToShow={isMobile ? 1 : 3}
            slidesToScroll={1}
          >
            {testimonialsItems}
          </Slider>
        </Box>
        <Grid
          container
          sx={{
            maxWidth: "lg",
            margin: "0 auto",
            pt: 20,
            mb: 0,
            overflow: "visible",
          }}
        >
          <Grid
            item
            sm={12}
            sx={{
              textAlign: "center",
              overflow: "visible",
            }}
          >
            <Container
              sx={{
                spacing: 0,
                zIndex: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "futura-pt",
                    fontWeight: 450,
                    fontSize: "3em",
                    lineHeight: "1.3em",
                  }}
                >
                  Sign-up for a one month free trial to enjoy Hi-Res Audio, Song
                  Skips & No Talk!
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Grid>
        <div id="form">
          <Box
            sx={{
              pt: 5,
              pb: 8,
              margin: "0 auto 0",
              maxWidth: isMobile ? "lg" : "66%",
              zIndex: 5,
            }}
          >
            {freeTrialDoc?.active && (
              <CheckoutStepper
                isMobile={isMobile}
                setShowLanding={(val) => {
                  if (isMobile) setShowLanding(val);
                }}
                freeTrial={true}
              ></CheckoutStepper>
            )}
          </Box>
        </div>

        <Grid
          container
          sx={{
            maxWidth: "lg",
            margin: "0 auto",
            pt: 5,
            mb: 20,
            overflow: "visible",
          }}
        >
          <Grid
            item
            sm={12}
            sx={{
              textAlign: "center",
              overflow: "visible",
              zIndex: 7,
            }}
          >
            <Container
              sx={{
                spacing: 0,
                zIndex: 5,
                alignItems: "center",
                justifyContent: "center",
                display: "flex-end",
              }}
            >
              <Box sx={{ mt: 2, mb: 4 }}>
                <Typography
                  sx={{
                    fontFamily: "futura-pt",
                    fontWeight: 450,
                    fontSize: "3.5em",
                    lineHeight: "1.3em",
                  }}
                >
                  Frequently Asked Questions
                </Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Button
                  color="darkPurple"
                  component={Link}
                  to={"/devices"}
                  sx={{
                    padding: "20px 50px",
                    margin: isSmallMobile ? "10px auto" : "0 0 0 30px",
                    fontSize: "1.5em",
                  }}
                >
                  Devices FAQ
                </Button>

                <Button
                  color="darkPurple"
                  component={Link}
                  to={"/help"}
                  sx={{
                    padding: "20px 50px",
                    margin: isSmallMobile ? "10px auto" : "0 0 0 30px",
                    fontSize: "1.5em",
                  }}
                >
                  General FAQ
                </Button>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Elements>
      <Box sx={{ zIndex: 10, position: "relative" }}>
        <Footer />
      </Box>
    </>
  );
};

export default FreeTrial;
