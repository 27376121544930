import { Box, BoxProps, css, Paper, styled } from "@mui/material";
import { isString } from "Utils/Inspect";

const PREFIX = "jg-player__album-art";
export const classes = {
  root: PREFIX,
  image: `${PREFIX}__image`,
};

interface OwnerStateProps {
  ownerState: {
    size?: string | number;
  };
}

export const Container = styled(Box)<BoxProps & OwnerStateProps>(({ theme, ownerState }) => {
  const albumArt = theme.jgComponents.Player.albumArt;
  let albumArtSize = `${albumArt.size}px`;
  if (ownerState.size) {
    const size = ownerState.size;
    albumArtSize = isString(size) ? size : `${size}px`;
  }

  return css`
    --jg-album-art-size: ${albumArtSize};
    --jg-album-art-border-width: 0px;
    --jg-album-art-with-border-size: calc(var(--jg-album-art-size) + (var(--jg-album-art-border-width) * 2));

    /**
      Setting a height on the player variant will cause the album art to grow downward instead of upward.
      We apply the height to the image separately to circumvent this.
     */
    width: var(--jg-album-art-with-border-size);
    flex-shrink: 0;

    &.${classes.root}--variant-default {
      position: relative;
      height: var(--jg-album-art-with-border-size);
    }

    &.${classes.root}--expanded {
      --jg-album-art-size: ${albumArt.sizeExpanded}px;
    }

    &.${classes.root}--bordered {
      --jg-album-art-border-width: ${theme.shape.borderWidthElevation}px;
    }

    &,
    & .${classes.image} {
      max-width: 100%;
      transition: ${theme.transitions.create(["height", "width"])};
    }
  `;
});

export const ImageContainer = styled("div")(({ theme }) => {
  return css`
    position: absolute;
    // Since we're using box-shadow as our makeshift border, we offset enough space to account for the border.
    bottom: var(--jg-album-art-border-width);
    left: var(--jg-album-art-border-width);
    overflow: hidden;

    border-radius: ${theme.jgComponents.Player.albumArt.borderRadius}px;
    background-color: ${theme.palette.border.elevation};
    // We use box-shadow as our border so the border-radius properly clips the background image.
    box-shadow: 0 0 0 var(--jg-album-art-border-width) ${theme.palette.border.elevation};

    ${theme.breakpoints.up("lg")} {
      cursor: pointer;
    }

    .${classes.root}--variant-player & {
      border-bottom-left-radius: 0;
    }
  `;
});

export const Image = styled(Box)(({ theme }) => css`
  position: relative;
  width: var(--jg-album-art-size);
  height: var(--jg-album-art-size);
  border-radius: ${theme.jgComponents.Player.albumArt.borderRadius}px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`) as typeof Box;

export const LoadingContainer = styled(Paper)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
