import React from "react";
import { List, ListProps } from "@mui/material";
import { PlanTierKey, PLAN_FEATURES } from "Utils/Constants";
import PlanFeatureItem, { PlanFeatureItemSize } from "./PlanFeatureItem";

export type PlanFeaturesListProps = ListProps & {
  tier: PlanTierKey;
  size?: PlanFeatureItemSize;
  giftEnabled: boolean
}

const PlanFeaturesList = React.memo((props: PlanFeaturesListProps) => {
  const {
    tier,
    size = "medium",
    giftEnabled,
    ...rest
  } = props;

  return (
    <List dense {...rest}>
      {PLAN_FEATURES.map((feature, index) => (
        <PlanFeatureItem key={index} feature={feature} tier={tier} size={size} giftEnabled={giftEnabled} />
      ))}
    </List>
  );
});

export default PlanFeaturesList;
