import { decomposeColor, recomposeColor } from "@mui/material";

interface RgbaColor {
  red: number;
  green: number;
  blue: number;
  alpha: number;
}

/**
 * Returns a number whose value is limited to the given range.
 * @param {number} value The value to be clamped
 * @param {number} min The lower boundary of the output range
 * @param {number} max The upper boundary of the output range
 * @returns {number} A number in the range [min, max]
 */
function clamp(value, min = 0, max = 1) {
  if (process.env.NODE_ENV !== "production") {
    if (value < min || value > max) {
      console.error(`clamp: The value provided (${value}) is out of range [${min}, ${max}].`);
    }
  }

  return Math.min(Math.max(min, value), max);
}

/**
 * Decompose a color into an RGBA object.
 * @param color - Hex or RGB(A) color string
 */
export function createRgbaColor(color: string): RgbaColor {
  const aColor = decomposeColor(color);
  return {
    red: aColor.values[0],
    green: aColor.values[1],
    blue: aColor.values[2],
    alpha: aColor.type === "rgba" ? aColor[3] : 1,
  };
}

/**
 * Returns a color (in an RGBA color string) that’s a mixture of `color1` and `color2`.
 * Both the `weight` and the relative opacity of each color determines how much of each color is in the result.
 *
 * Ported from sass implementation in C
 * https://github.com/sass/libsass/blob/0e6b4a2850092356aa3ece07c6b249f0221caced/functions.cpp#L209
 *
 * @param mixinColor1 - Hex or RGB(A) color string
 * @param mixinColor2 - Hex or RGB(A) color string
 * @param weight - Number between 0 and 1 (inclusive). A larger weight indicates that more of `color1`
 *                 should be used, and a smaller weight indicates that more of `color2` should be used.
 * @returns The mixed color as an RGBA color string
 */
export function mix(mixinColor1: string, mixinColor2: string, weight: number = 0.5): string {
  const color1 = createRgbaColor(mixinColor1);
  const color2 = createRgbaColor(mixinColor2);

  const p = weight === undefined ? 0.5 : clamp(weight, 0, 1);

  const w = 2 * p - 1;
  const a = color1.alpha - color2.alpha;

  const w1 = (((w * a === -1) ? w : (w + a) / (1 + w * a)) + 1) / 2;
  const w2 = 1 - w1;

  return recomposeColor({
    type: "rgba",
    values: [
      w1 * color1.red + w2 * color2.red,
      w1 * color1.green + w2 * color2.green,
      w1 * color1.blue + w2 * color2.blue,
      color1.alpha * p + color2.alpha * (1 - p)
    ],
  });
}
